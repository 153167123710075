import { combineReducers } from 'redux';

import user from './user';
import products from './products';
import visibility from './visibility';
import menu from './menu';
import adyenPayment from './adyen';
const allReducers = combineReducers({
    user,
    products,
    visibility,
    menu,
    adyenPayment
});
export default allReducers;
// export default allReducers;