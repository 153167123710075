import React, { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { useSelector } from 'react-redux';
import { selectLanguage, selectSelectedCurrency } from '../../store/user';
import CallCenterImg from '../../images/call-center/call-center.png';
import CallCenterImg1 from '../../images/call-center/callCenter1.png';
import USA from '../../images/call-center/usa-coming-soon.png';
import Feedback from '../../images/call-center/feedback 1.png';
import Ringing from '../../images/call-center/ringing.png';
import SubwayCall from '../../images/call-center/subway_call-4.png';
import Info from '../../images/call-center/info-filled.png';
import Email from '../../images/call-center/email.png';
import { useForm } from 'react-hook-form';
import useMutation from '../../hooks/useMutaion';
import services from '../../services';
import { toast } from 'react-toastify';
const CallCenter = () => {
    const language = useSelector(selectLanguage);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState('AUD');
    const { error, isLoading, mutate, isSuccess } = useMutation(services.postFormContact);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'onSubmit',
    });
    const onSubmit = data => {
        mutate(data);
    };
    /* ---------------------------------- Hooks --------------------------------- */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        if (isSuccess) {
            toast.success('Thank you for contacting us. We will get back to you soon.');
            reset();
            console.log("sele",selectedCurrency)
        }
        if (error) {
            toast.error('Something went wrong. Please try again later.');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, error]);
    return (
        <Layout className='bg-white' uk>
            <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
            <section className="bg-[#093686]" dir={language === 'ar' ? 'rtl' : 'ltr'}>
                <section className="max-w-[1440px] mx-auto">
                    <div className='hidden sm:flex'>
                        <div className='flex-1  pl-3 sm:pl-4 md:pl-8 py-1 sm:py-3 md:py-6 lg:py-10 xl:pl-20 xl:pt-20'>
                            <div className='text-white text-[12px] sm:text-[20px] md:text-[25px] lg:text-[36px] xl:text-[40px] '>MATW's dedicated adviser team ready to help you</div>
                            <div className='text-white text-[9px] sm:text-sm md:text-base lg:text-[26px] xl:text-[28px] lg:leading-7 xl:leading-10 pr-2 sm:pr-0 sm:w-10/12/4 lg:w-11/12 mb-2 sm:mb-4'>
                                MATW's lines are now open for UK and Australia. Feel free to use these numbers to connect with a team member for additional information or to make a donation.
                            </div>
                            <div > <img className='w-[100px] sm:w-[250px] md:w-[300px] lg:w-[350px] xl:[380px]' src={USA} alt="usa" /></div>
                        </div>
                        <div className='basis-5/12 sm:basis-5/12 md:basis-6/12 xl:basis-[44%]' ><img alt="callcenter" src={CallCenterImg} /></div>


                    </div>
                    <div className='sm:hidden '>
                        <div><img className='w-[450px] h-[250px] object-top' src={CallCenterImg1} alt="callcenter" /></div>
                        <div className='px-10 py-8 flex flex-col justify-center items-center'>
                            <h1 className='text-white text-[28px] leading-[30px]  text-center'>MATW's dedicated adviser team ready to help you</h1>
                            <p className='text-center px-1 leading-[16px] my-6 text-white text-[16px]'>
                                MATW's lines are now open for UK and Australia.
                                Feel free to use these numbers to connect with a team member for
                                additional information or to make a donation.
                            </p>
                            <div > <img className='w-[300px] sm:w-[250px] md:w-[300px] lg:w-[350px] xl:[380px]' src={USA} alt="usa" /></div>

                        </div>
                    </div>
                </section>
            </section>
            <section className='bg-[#E2EFF3]' dir={language === 'ar' ? 'rtl' : 'ltr'}>
                <section className='max-w-[1440px] mx-auto'>
                    <div className='md:container mx-auto'>
                        <div className='grid grid-cols-3 gap-10 px-3 md:px-1 lg:px-10 py-8 sm:py-16'>
                            <div className='flex flex-col min-[400px]:px-20 sm:px-0 items-center col-span-3 sm:col-span-1'>
                                <img src={Feedback} alt="icon" className='w-[40px] sm:w-[80px]' />
                                <div className='mt-3 text-[18px] leading-[20px] md:text-[22px] lg:text-[25px] xl:text-[32px] sm:leading-9 text-center text-[#333333]'>Have any feedback? We listen and we act</div>
                            </div>
                            <div className='flex flex-col min-[400px]:px-20 sm:px-0 items-center col-span-3 sm:col-span-1'>
                                <img src={SubwayCall} alt="icon" className='w-[40px] sm:w-[80px]' />
                                <div className='mt-3 text-[18px] leading-[20px] md:text-[22px] lg:text-[25px] xl:text-[32px] sm:leading-9 text-center text-[#333333]'>Would like to contact us regarding a problem?</div>
                            </div>
                            <div className='flex flex-col min-[400px]:px-20 sm:px-0 items-center col-span-3 sm:col-span-1'>
                                <img src={Info} alt="icon" className='w-[40px] sm:w-[80px]' />
                                <div className='mt-3 text-[18px] leading-[20px] md:text-[22px] lg:text-[25px] xl:text-[32px] sm:leading-9 text-center text-[#333333]'>Would like to get more info?</div>
                            </div>

                        </div>
                    </div>
                </section>
            </section>
            <section className='bg-white' dir={language === 'ar' ? 'rtl' : 'ltr'}>
                <section className='max-w-[1440px] mx-auto'>
                    <div className='md:container mx-auto'>
                        <div className='grid md:gap-y-8 grid-cols-2 gap-6 sm:gap-10 px-4 sm:px-10 py-10'>
                            <div className=' col-span-2 sm:col-span-2 lg:col-span-1'>
                                <div className='flex items-center gap-x-3   justify-start'>
                                    <div className='basis-1/12 mt-2'> <img src={Ringing} alt="icon" className='w-full' /></div>
                                    <div className='p-4 bg-[#E2EFF3] basis-11/12 text-[#333333]'>
                                        <div className='text-[20px] sm:text-[28px]  text-[#00A3DA] font-bold'>Opening hours of call centre</div>
                                        <div className='text-[16px] sm:text-[20px] text-[#333333]'>
                                            <div className='text-[#093686]'> UK ( 10am-8pm) Local UK time:&nbsp;
                                                <a href="tel:+44 2080895843" target="_self" className="text-black" rel="noopener noreferrer">
                                                    &nbsp; 02080895843
                                                </a>
                                            </div>
                                            <div className='text-[#093686]'> AUS ( 10am-8pm) Local AUS time: &nbsp;
                                                <a href="tel:+61 282239876" target="_self" className="text-black" rel="noopener noreferrer">

                                                    +61282239876
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='flex items-center gap-x-3 mt-6  justify-start'>
                                    <div className='basis-1/12 mt-2'> <img src={Ringing} alt="icon" className='w-full' /></div>
                                    <div className='p-4 bg-[#E2EFF3] basis-11/12 text-[#333333]'>
                                        <div className='text-[20px] sm:text-[28px]  text-[#00A3DA] font-bold'>Inbound Contact Details</div>
                                        <div className='text-[16px] sm:text-[20px] text-[#333333]'>
                                            <div className='text-[#093686]'> UK Inbound: &nbsp;
                                                {/* <a href="tel:+44 2080895843" target="_self" className="text-black" rel="noopener noreferrer">
                                                    02080895843
                                                </a> */}
                                                <a href="tel:+44 2081380722" target="_self" className="text-black" rel="noopener noreferrer">
                                                02081380722
                                                </a>
                                            </div>
                                            <div className='text-[#093686]'> AUS Inbound: &nbsp;
                                                <a href="tel:+61 282239876" target="_self" className="text-black" rel="noopener noreferrer">

                                                    +61 282239876
                                                </a>
                                            </div>
                                            <div className='text-[16px] sm:text-[20px] leading-6 text-[#333333] mt-4'>
                                                Contact this number to speak to a member of
                                                the team to gain more information.
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>


                            <div className=' col-span-2 sm:col-span-2 lg:col-span-1'>
                                <div className='items-center flex  gap-x-3 justify-start'>
                                    <div className='basis-1/12 mt-2'> <img src={Ringing} alt="icon" className='w-full' /></div>

                                    <div className='bg-[#E2EFF3] p-4 lg:min-h-[445px] xl:min-h-0 basis-11/12 text-[#333333]'>
                                        <div className='text-[20px] sm:text-[28px]  text-[#00A3DA] font-bold'>Outbound Contact Details</div>

                                        <div className='text-[14px] sm:text-[20px]  text-[#333333]'>
                                            <div className='text-[#F60362] leading-[25px]'> UK Outbound: &nbsp;
                                                {/* <a href="tel:+44 2037694075" target="_self" className="text-black" rel="noopener noreferrer">
                                                    02037694075
                                                </a> */}
                                                <a href="tel:+44 2034769139" target="_self" className="text-black" rel="noopener noreferrer">
                                                02034769139
                                                </a>
                                            </div>
                                            <div className='text-[#F60362] leading-[25px]'> AUS Outbound &nbsp;
                                                <a href="tel:+61 282239876" target="_self" className="text-black" rel="noopener noreferrer">

                                                    +61282239876
                                                </a>
                                            </div>
                                            <div className='mt-5 leading-6 text-[16px] sm:text-[20px] w-12/12'>

                                                You may receive calls from our donor care team from time to time asking you for your feedback or updating you
                                                on major updates.
                                            </div>
                                        </div>
                                        <div className='text-[14px] mt-7  sm:text-[20px]  text-[#333333]'>
                                            <div className='text-[#F60362] leading-[25px]'> UK Outbound SMS: &nbsp;
                                                <a href="tel:+44 7418310725" target="_self" className="text-black" rel="noopener noreferrer">

                                                    +447418310725
                                                </a>
                                            </div>
                                            <div className='text-[#F60362] leading-[25px]'> AUS Outbound SMS: &nbsp;
                                                <a href="tel:+61 485908669" target="_self" className="text-black" rel="noopener noreferrer">

                                                +61485908669
                                                </a>
                                            </div>
                                            <div className='mt-3 text-[16px] leading-6 sm:text-[20px] w-12/12'>

                                                If you receive a message from this number, don't worry
                                                it's from MATW.
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>


                    </div>
                </section>
            </section>
            <section className='bg-[#E2EFF3]' dir={language === 'ar' ? 'rtl' : 'ltr'}>
                <section className='max-w-[1440px] mx-auto'>
                    <div className='md:container mx-auto'>
                        <div className='grid grid-cols-2 gap-5 sm:gap-10 px-3 md:px-1 lg:px-10 py-5 sm:py-16'>
                            <div className='col-span-2 sm:col-span-1'>
                                <div className='flex h-full justify-center items-center'>
                                    <div className='flex gap-x-3'>
                                        <div className='mt-2 w-[25px] sm:w-[30px] md:w-[35px] lg:w-[60px] sm:mt-4'><img src={Email} className='text-[#093686]' alt='email' /></div>
                                        <div >
                                            <div className='font-bold text-[#093686] text-[20px]  sm:text-[48px]'>Want to send a message instead?</div>
                                            <div className='sm:px-2 mt-2 text-[#333333] text-[16px] sm:text-[23px]'>Fill out our contact form and  we will respond to
                                                you shortly.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-span-2 sm:col-span-1'>

                                <form onSubmit={handleSubmit(onSubmit)} className="w-full  text-sm text-textPrimary gap-4 grid grid-cols-1 md:grid-cols-2">
                                    <div>
                                        <input
                                            {...register('name')}
                                            placeholder="Name"
                                            className="w-full p-2  rounded-[10px] h-[50px] text-base    focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                                        />
                                        {errors.name && <span className="text-sm text-red-600">This field is required</span>}
                                    </div>
                                    <div>
                                        <input
                                            {...register('email', {
                                                required: {
                                                    value: true,
                                                    message: 'This field is required',
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: 'invalid email address',
                                                },
                                            })}
                                            placeholder="E-mail*"
                                            type="email"
                                            className="w-full p-2  rounded-[10px] h-[50px] text-base    focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                                        />
                                        {errors.email?.message && <span className="text-sm text-red-600">{errors.email.message}</span>}
                                    </div>
                                    <div>
                                        <input
                                            className="w-full p-2  rounded-[10px] h-[50px] text-base    focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                                            {...register('phone')}
                                            type="number"
                                            placeholder="Phone"
                                        />
                                        {errors.phone && <span className="text-sm text-red-600">This field is required</span>}
                                    </div>
                                    <div>
                                        <select
                                            defaultValue={''}
                                            className="w-full p-2  rounded-[10px] h-[50px] text-base     focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                                            {...register('purpose')}
                                        >
                                            <option value="">--Please choose an option--</option>
                                            <option value="Complaint">Complaint</option>
                                            <option value="donationQuestion">Donation Question</option>
                                            <option value="progressQuery">Progress Query</option>
                                            <option value="Other">General enquiry</option>
                                        </select>
                                        {errors.purpose && <span className="text-sm text-red-600">This field is required</span>}
                                    </div>
                                    <div className="md:col-span-2 col-span-1">
                                        <textarea
                                            rows={4}
                                            {...register('message', { required: true })}
                                            placeholder="Message*"
                                            className="w-full p-2 resize-none  rounded-[10px] text-base    focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                                        />
                                        {errors.message && <span className="text-sm text-red-600">This field is required</span>}
                                    </div>


                                    <div className=" md:col-span-2 col-span-1">
                                        <button type="submit" className="h-[53px] w-full rounded-[10px] text-[20px] font-bold bg-[#F60362] text-white hover:opacity-7 transition-all duration-300 px-16 py-3">
                                            {isLoading ? 'Loading...' : 'Send Message'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </section>
            </section>
            <section className="bg-[#]" dir={language === 'ar' ? 'rtl' : 'ltr'}>
                <section className="max-w-[1440px] mx-auto">
                    <div className='md:container mx-auto px-2 sm:px-10 '>
                        <h1 className='text-center my-8 text-[#093686] text-[48px] font-bold'>Visit Us</h1>

                        <div className='drop-shadow-lg  rounded-lg p-4 bg-white'>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d105953.35344765664!2d151.037564!3d-33.914311!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bc0e4d1410ed%3A0xbce1604f19c23f2!2sLevel%202%2F47%20Rickard%20Rd%2C%20Bankstown%20NSW%202200%2C%20Australia!5e0!3m2!1sen!2sus!4v1680419870535!5m2!1sen!2sus"
                                width="100%"
                                height="400"
                                title=' '
                                allowFullScreen=""
                                style={{
                                    border: 'none',
                                }}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>

                        </div>
                    </div>
                    <div>
                      <div className="font-bold text-[#093686] text-[20px]  sm:text-[48px]">Want to send a message instead?</div>
                      <div className="sm:px-2 mt-2 text-[#333333] text-[16px] sm:text-[23px]">
                        Fill out our contact form and we will respond to you shortly.
                      </div>
                    </div>
                </section>
            </section>
              <div className="col-span-2 sm:col-span-1">
                <form onSubmit={handleSubmit(onSubmit)} className="w-full  text-sm text-textPrimary gap-4 grid grid-cols-1 md:grid-cols-2">
                  <div>
                    <input
                      {...register('name')}
                      placeholder="Name"
                      className="w-full p-2  rounded-[10px] h-[50px] text-base    focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                    />
                    {errors.name && <span className="text-sm text-red-600">This field is required</span>}
                  </div>
                  <div>
                    <input
                      {...register('email', {
                        required: {
                          value: true,
                          message: 'This field is required',
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'invalid email address',
                        },
                      })}
                      placeholder="E-mail*"
                      type="email"
                      className="w-full p-2  rounded-[10px] h-[50px] text-base    focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                    />
                    {errors.email?.message && <span className="text-sm text-red-600">{errors.email.message}</span>}
                  </div>
                  <div>
                    <input
                      className="w-full p-2  rounded-[10px] h-[50px] text-base    focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                      {...register('phone')}
                      type="number"
                      placeholder="Phone"
                    />
                    {errors.phone && <span className="text-sm text-red-600">This field is required</span>}
                  </div>
                  <div>
                    <select
                      defaultValue={''}
                      className="w-full p-2  rounded-[10px] h-[50px] text-base     focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                      {...register('purpose')}
                    >
                      <option value="">--Please choose an option--</option>
                      <option value="Complaint">Complaint</option>
                      <option value="donationQuestion">Donation Question</option>
                      <option value="progressQuery">Progress Query</option>
                      <option value="Other">General enquiry</option>
                    </select>
                    {errors.purpose && <span className="text-sm text-red-600">This field is required</span>}
                  </div>
                  <div className="md:col-span-2 col-span-1">
                    <textarea
                      rows={4}
                      {...register('message', { required: true })}
                      placeholder="Message*"
                      className="w-full p-2 resize-none  rounded-[10px] text-base    focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-transparent"
                    />
                    {errors.message && <span className="text-sm text-red-600">This field is required</span>}
                  </div>

                  <div className=" md:col-span-2 col-span-1">
                    <button
                      type="submit"
                      className="h-[53px] w-full rounded-[10px] text-[20px] font-bold bg-[#F60362] text-white hover:opacity-7 transition-all duration-300 px-16 py-3"
                    >
                      {isLoading ? 'Loading...' : 'Send Message'}
                    </button>
                  </div>
                </form>
              </div>
      <section className="bg-[#]">
        <section className="max-w-[1440px] mx-auto">
          <div className="md:container mx-auto px-2 sm:px-10 ">
            <h1 className="text-center my-8 text-[#093686] text-[48px] font-bold">Visit Us</h1>

            <div className="drop-shadow-lg  rounded-lg p-4 bg-white">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d105953.35344765664!2d151.037564!3d-33.914311!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12bc0e4d1410ed%3A0xbce1604f19c23f2!2sLevel%202%2F47%20Rickard%20Rd%2C%20Bankstown%20NSW%202200%2C%20Australia!5e0!3m2!1sen!2sus!4v1680419870535!5m2!1sen!2sus"
                width="100%"
                height="400"
                title=" "
                allowFullScreen=""
                style={{
                  border: 'none',
                }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default CallCenter;
