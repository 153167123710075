import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import ShowCurrencies from "../../../utils/showCurrencies";
import useHandleAddProduct from "../../../utils/handleAddProduct";
import { selectProducts } from "../../../store/products";
import CustomButton from "../../../components/general/button";
// import SelectCurrency from "../../../components/general/selectCurrency";
import { selectSelectedCurrency } from "../../../store/user";
const styles = {
    control: (base) => ({
        ...base,
        height: '48px',
        border: 'none',
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const DonateWidget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { defaultProduct, hasSuggestPrice, className, arabic, donateProducts, isSingleProduct = false, customSuggestedPrices, useDonateProducts } = props;
    const products = useSelector((state) => selectProducts(state));
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [productAmount, setProductAmount] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    // const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedPrice, setSelectedPrice] = useState(150);
    const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
    const [addProduct] = useHandleAddProduct();
    const [suggestedPrices, setSuggestedPrices] = useState([
        50, 100, 150
    ])
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempMakeDonationProducts = [];
        let tempProductsList = [];
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === defaultProduct.creator) {
                        setSelectedProduct(inner_item_product)
                    }
                    if (isSingleProduct || useDonateProducts) {
                        if (donateProducts.includes(inner_item_product.creator)) { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                    } else {
                        if(inner_item_product.creator === 'EMR-AIA-24-01') tempProductsList[0] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-02') tempProductsList[1] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-03') tempProductsList[2] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-04') tempProductsList[3] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-05') tempProductsList[4] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-06') tempProductsList[5] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-07') tempProductsList[6] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-08') tempProductsList[7] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-09') tempProductsList[8] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-10') tempProductsList[9] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-11') tempProductsList[10] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-12') tempProductsList[11] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-13') tempProductsList[12] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-14') tempProductsList[13] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-15') tempProductsList[14] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-16') tempProductsList[15] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-17') tempProductsList[16] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-18') tempProductsList[17] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-19') tempProductsList[18] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-20') tempProductsList[19] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-21') tempProductsList[20] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-22') tempProductsList[21] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-23') tempProductsList[22] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                        if(inner_item_product.creator === 'EMR-AIA-24-24') tempProductsList[23] = { value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator }
                    }
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
        tempProductsList = tempProductsList.filter(n => n)
        setProductsList(tempProductsList);
        setAllProducts(tempMakeDonationProducts)
    };
    // const handleCurrency = (currency) => {
    //     setCurrentCurrency(currency.value)
    //     // selectedCurrency(currency);
    // }
    const handleProduct = (value) => {
        // console.log("product", value, selectedProduct,allProducts);
        allProducts.map(item => item.id === value.value && setSelectedProduct(item))
    }
    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: 'one-off',
        })
        addProduct({product: tempProduct, currency: "AUD"})
    }

    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // if (selectedCurrencyStore) {
        //   setCurrentCurrency(selectedCurrencyStore);
        // } else {
        //   setCurrentCurrency('AUD');
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore]);
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    useEffect(() => {
        console.log("customSuggestedPrices", customSuggestedPrices)
        customSuggestedPrices && setSuggestedPrices(customSuggestedPrices);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customSuggestedPrices])
    /* -------------------------------------------------------------------------- */
    return (
        <div className={`lg:max-w-[390px] bg-[#E2EFF3] rounded-lg p-2 md:p-4 flex items-center ${className}`}>
            <div className="w-full grid grid-cols-12 gap-4">
                {!isSingleProduct && <div className="col-span-12">
                    <ReactSelect
                        options={productsList}
                        className='max-h-[48px]'
                        defaultValue={selectedProduct}
                        isSearchable={false}
                        styles={styles}
                        onChange={(e) => handleProduct(e)}
                    />
                </div>}
                {hasSuggestPrice && <div className="col-span-12 grid grid-cols-6 gap-2 h-12">
                    {suggestedPrices.map((price, index) => {
                        return <div key={`prices${index}`} className="col-span-2" style={styles.shadow}>
                            <div
                                className={`h-12 rounded-md text-base font-bold transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] ${selectedPrice === price ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                onClick={() => {
                                    setSelectedPrice(price);
                                    setProductAmount('');
                                }}
                            >
                                {/* {ShowCurrencies(currentCurrency, price, false)}<span className={`uppercase ml-1 text-sm`}>AUD</span> */}
                                {/* {ShowCurrencies(currentCurrency, price, true)} */}
                                {ShowCurrencies("AUD", price, true)}
                            </div>
                        </div>
                    })}
                </div>}
                <div className="col-span-12 flex flex-wrap">
                    <div className="flex justify-center items-center pr-2 bg-white text-[#F60362] rounded-tl-md rounded-bl-md w-1/2 md:w-1/3 h-12" style={styles.shadow}>
                        AUD
                        {/* <SelectCurrency onChangeCurrency={e => setCurrentCurrency(e.value)} classNamePrefix="orphan_select" hideFlag /> */}
                    </div>
                    <div className="flex justify-center items-center bg-white text-[#F60362] rounded-tr-md rounded-br-md w-[calc(100%_-_56px)]- w-1/2 md:w-2/3 h-12 px-2-" style={styles.shadow}>
                        <input
                            value={productAmount}
                            className="w-full p-2 rounded-lg text-base focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                            type="number"
                            onChange={(e) => {
                                setProductAmount(e.target.value);
                                setSelectedPrice(0);
                            }}
                            placeholder="Enter Amount" />
                    </div>

                </div>
                <div className="col-span-12">
                    {/* <button
                        className={`text-white w-full text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-[38px] px-3 rounded-md ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                        onClick={(e) => { handleAddNewProduct() }}
                    >{arabic ? 'تبرع الان' : 'Donate Now'}</button> */}
                    <CustomButton
                        disabled={!selectedProduct.id}
                        className={'w-full'}
                        title={arabic ? 'تبرع الان' : 'Donate Now'}
                        onClick={(e) => { handleAddNewProduct() }}
                    />
                </div>
            </div>
        </div>
    );
}

export default DonateWidget;
