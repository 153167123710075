import { useEffect, useState } from 'react';
import services from '../../services';
import Footer from './footer';
import MainFooter from './mainFooter';
import CustomNavbar from './navbar';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedCurrency,
  setCountryCode,
  setLanguage,
  // selectSelectedCurrency,
  // setCountry,
  // setSelectedCurrencyInStore
} from '../../store/user';
import { selectLastOrders, selectProducts, setLastOrders, setProducts } from '../../store/products';
import Highlights from './highlights';
import { setFooterMenu, setHighlightMenu, setNavbarMenu } from '../../store/menu';
import { appealProductsCodes, endDateOfAppealCampaign, startDateOfAppealCampaign } from '../../pages/MATWAppeal/details';
import AddUniversalUSAScript from '../../utils/addUniversalUSAScript';
import AddAdrollGlobalPixelUKScript from '../../utils/addAdrollGlobalPixelUKScript';
import { checkDomain } from '../../utils/checkDomain';
// import PopUp from "../pop-up";
// import { FaXmark } from "react-icons/fa6";
// import LaylatulQadrWidget from "../../pages/ramadan/laylatulQadr/laylatulQadrWidget";
let curent_currency = '';
const Layout = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const dispatch = useDispatch();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const {
    children,
    propertyName,
    className,
    hideNavbar,
    hideHighlights,
    hideFooter,
    hideMainFooter,
    childClasses,
    highlights,
    navigation,
    isRamadanPage,
    showRegularImg = true,
    isHomePage = false,
  } = props;
  const [isUk, setIsUk] = useState(false);
  const [isAus, setIsAus] = useState(false);
  const [allProducts, setAllProducts] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentLang, setCurrentLang] = useState('en');
  const products = useSelector(state => selectProducts(state));
  const orders = useSelector(state => selectLastOrders(state));
  const [isLoadedLatestOrders, setIsLoadedLatestOrders] = useState(false);
  // const [showLastTenNightsModal, setShowLastTenNightsModal] = useState(false);
  // const products = useSelector((state) => getProducts(state, 'crisis and emergencies','Caravan'));
  // console.log(products);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = async () => {
    try {
      const response = await services.allProducts();
      let tempDeactiveProducts = [];
      const tempProducts = [];
      response.data.forEach(item => {
        item.sub_categories.forEach(inner_item => {
          inner_item.products = inner_item.products.filter(inner_item_product => {
            Number(inner_item_product.status) !== 1 && tempDeactiveProducts.push(inner_item_product);
            if (checkDomain('matwprojectfr.org') && inner_item_product.name?.toLowerCase().includes('masjid')) {
              return false;
            }
            return Number(inner_item_product.status) === 1 && inner_item_product;
          });
        });
        tempProducts.push(item);
      });
      setAllProducts(tempProducts);
      dispatch(setProducts(tempProducts));
      setTimeout(() => {
        zohoScript();
      }, 3000);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const getAllMenus = async () => {
    try {
      const response = await services.getAllMenus();
      let tempNavbar = response.data.data.filter(n => (n.status === true || Number(n.status) === 1) && n.section === 'main');
      let tempCircle = response.data.data.filter(n => (n.status === true || Number(n.status) === 1) && n.section === 'circle');
      let tempFooter = response.data.data.filter(n => (n.status === true || Number(n.status) === 1) && n.section === 'footer');
      if (checkDomain('matwprojectfr.org')) {
        if (tempNavbar[0].children) tempNavbar[0].children = tempNavbar[0]?.children?.filter(item => ![99, 17].includes(item.id));
        if (tempFooter[0].children) tempFooter[0].children = tempFooter[0].children.filter(item => ![57].includes(item.id));
        if (tempFooter[2].children) tempFooter[2].children = tempFooter[2].children.filter(item => ![77].includes(item.id));
      }
      dispatch(setNavbarMenu(tempNavbar));
      dispatch(setHighlightMenu(tempCircle));
      dispatch(setFooterMenu(tempFooter));
    } catch (error) {
      console.error('error========', error);
    }
  };
  const getConvertedCurrency = async amount => {
    let tempRatioes = {
      EUR: 1,
      USD: 1,
      CAD: 1,
      GBP: 1,
      SGD: 1,
      MYR: 1,
      IDR: 1,
      AUD: 1,
      AED: 1,
    };
    try {
      const response = await services.convertCurrency(amount);
      // console.log("response convert aud",response)
      response.data.map(item => {
        tempRatioes[item.currency_name] = item.ratio;
        return item;
      });
      localStorage.setItem('Ratio', JSON.stringify(tempRatioes));
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const customUseScript1 = (url, content) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.innerHTML = content;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  const customUseScript2 = content => {
    const script = document.createElement('script');
    script.innerHTML = content;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  const googleTagManager = () => {
    const emptyScript = document.createElement('script');
    emptyScript.src = 'https://www.googletagmanager.com/gtag/js?id=DC-13776505';
    const script = document.createElement('script');
    script.innerHTML =
      "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'DC-13776505');";
    document.getElementsByTagName('head')[0].appendChild(emptyScript);
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  const zohoScript = () => {
    // Zoho scriptini oluşturup body'ye ekliyoruz
    const script = document.createElement('script');
    script.id = 'zsiqchat';
    script.type = 'text/javascript';
    script.innerHTML =
      'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq60bd1fd1aa1065847adac0e6df33ad8fe13971fe786f7d88146522701fde175f", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);';
    document.getElementsByTagName('body')[0].appendChild(script);

    const applyChatIconStyles = () => {
      const chatIcon = document.querySelector('.zsiq_floatmain');

      if (chatIcon) {
        const screenWidth = window.innerWidth;

        if (screenWidth < 768) {
          chatIcon.style.marginBottom = '10px';
        } else {
          chatIcon.style.marginBottom = '15px';
        }
      }
    };

    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const chatIcon = document.querySelector('.zsiq_floatmain');
          if (chatIcon) {
            applyChatIconStyles();
            observer.disconnect();
            break;
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
    window.addEventListener('scroll', applyChatIconStyles);
    window.addEventListener('resize', applyChatIconStyles);
  };

  zohoScript();

  const customUseScript4 = url => {
    const script = document.createElement('script');
    script.src = url;
    document.getElementsByTagName('body')[0].appendChild(script);
  };

  const checkUKWebsite = referrer => {
    const href = referrer;
    const findTerm = term => {
      if (href.includes(term)) {
        return href;
      }
    };
    switch (referrer) {
      case findTerm('matwproject.org.uk'): // UK Website
        setIsUk(true);
        break;
      case findTerm('matwproject.org'): // AUS Website
        setIsAus(true);
        break;
      default:
        setIsUk(false);
    }
  };

  // const handleClick = () => {
  //   console.log('curent-lang===', gt_get_cookie('googtrans').split('/').pop());
  //   let current_language = gt_get_cookie('googtrans').split('/').pop();
  //   console.log('curent-lang===', current_language,gt_get_cookie('googtrans').split('/').pop());
  //   dispatch(setLanguage(current_language));
  //   setCurrentLang(current_language);
  // };
  // const customUseScriptOnHead = content => {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.innerHTML = content;
  //   document.getElementsByTagName('head')[0].appendChild(script);
  // };
  const getOrders = async _currency => {
    let req = {
      products: appealProductsCodes,
      currency: _currency || 'AUD',
      start_date: startDateOfAppealCampaign,
      end_date: endDateOfAppealCampaign,
    };
    try {
      const response = await services.getLatestOrders(req);
      setIsLoadedLatestOrders(true);
      let _first = response.data;
      _first[0] = Object.assign({}, _first[0], {
        currency: _currency,
      });
      dispatch(setLastOrders(_first));
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    (!products || !Object.keys(products).length) && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    // console.log("layout currency=",selectedCurrencyStore)
    if (selectedCurrencyStore) {
      if ((!orders || !Object.keys(orders).length) && !isLoadedLatestOrders) {
        if (curent_currency !== selectedCurrencyStore) {
          !isHomePage && getOrders(selectedCurrencyStore);
          curent_currency = selectedCurrencyStore;
        }
      } else {
        if (orders[0].currency.toLowerCase() !== selectedCurrencyStore.toLowerCase()) {
          if (curent_currency !== selectedCurrencyStore) {
            !isHomePage && getOrders(selectedCurrencyStore);
            curent_currency = selectedCurrencyStore;
          }
        }
      }
    } else {
      // console.log("currency not exict=",curent_currency,selectedCurrencyStore)
      if (!curent_currency) {
        // console.log("currency not exict on if=",curent_currency,selectedCurrencyStore)
        // !isHomePage && getOrders(selectedCurrencyStore);
        curent_currency = 'AUD';
      }
    }
    // selectedCurrencyStore && (!orders || !Object.keys(orders).length) && !isLoadedLatestOrders && getOrders(selectedCurrencyStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore, orders]);
  useEffect(() => {
    // customUseScript2(`window.gtranslateSettings = {"default_language":"en","detect_browser_language":true,"languages":["en","fr"],"wrapper_selector":".gtranslate_wrapper"}`);
    // customUseScript1('https://cdn.gtranslate.net/widgets/latest/float.js', '');
    customUseScript4('https://maps.googleapis.com/maps/api/js?key=AIzaSyCXVJ062M2GPfxr4DvmF55qSbSp4qh0S6U&libraries=places');
    // let current_language = gt_get_cookie('googtrans').split('/').pop();
    // setCurrentLang(current_language);
    // dispatch(setLanguage(current_language));
    getConvertedCurrency(1);
    // getAllProducts();
    getAllMenus();
    checkUKWebsite(window.location.href);
    // console.log("window=",window.navigation)
    if (!window.navigation || !window.navigation.pagetype) {
      // Set a default value
      window.navigation = window.navigation || {};
      // window.navigation.pagetype = 'webpage';
      window.navigation.pagetype = window.navigation.pagetype ? window.navigation.pagetype + 1 : 0;
    }
    // customUseScript3('var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq60bd1fd1aa1065847adac0e6df33ad8fe13971fe786f7d88146522701fde175f", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);')
    // customUseScriptOnHead(`var head = document.head;var script = document.createElement('script');script.type = 'text/javascript';script.src = "https://206788.t.hyros.com/v1/lst/universal-script?ph=01ca8fee456895e2b732862de7b2f523f7024ba386b77655db6e444828c725b8&tag=!clicked&ref_url=" + encodeURI(document.URL) ;head.appendChild(script);`)
    // setTimeout(() => {
    //   customUseScript3('var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq60bd1fd1aa1065847adac0e6df33ad8fe13971fe786f7d88146522701fde175f", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);')
    // }, 3000)
    // customUseScriptOnHead(`var head = document.head;var script = document.createElement('script');script.type = 'text/javascript';script.src = "https://t.matwprojectusa.org/v1/lst/universal-script?ph=01ca8fee456895e2b732862de7b2f523f7024ba386b77655db6e444828c725b8&tag=!clicked&ref_url=" + encodeURI(document.URL);head.appendChild(script);`)
    AddUniversalUSAScript();
    AddAdrollGlobalPixelUKScript();
    // fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
    //   .then(res => res.json())
    //   .then(response => {
    //     if (!selectedCurrencyStore) {
    //       switch (response.countryCode) {
    //         case 'AU':
    //           dispatch(setSelectedCurrencyInStore('AUD'));
    //           break;
    //         case 'US':
    //           dispatch(setSelectedCurrencyInStore('USD'));
    //           break;
    //         case 'MY':
    //           dispatch(setSelectedCurrencyInStore('MYR'));
    //           break;
    //         case 'SG':
    //           dispatch(setSelectedCurrencyInStore('SGD'));
    //           break;
    //         case 'CA':
    //           dispatch(setSelectedCurrencyInStore('CAD'));
    //           break;
    //         // case 'AE':
    //         //   dispatch(setSelectedCurrencyInStore('AED'));
    //         //   break;
    //         default:
    //           dispatch(setSelectedCurrencyInStore('USD'));
    //       }
    //       if (response.timezone.includes('Europe')) {
    //         if (response.countryCode === 'GB') {
    //           dispatch(setSelectedCurrencyInStore('GBP'));
    //         } else {
    //           dispatch(setSelectedCurrencyInStore('EUR'));
    //         }
    //       }
    //     }
    //     dispatch(setCountry(response.country === 'United Kingdom' ? 'uk' : ''));
    //   })
    //   .catch((data, status) => {
    //     console.log('Request failed:', data);
    //   });
    if (window.location.hostname) {
      switch (window.location.hostname) {
        case 'matwproject.org':
          dispatch(setCountryCode('AU'));
          break;
        case 'matwproject.org.uk':
          dispatch(setCountryCode('UK'));
          break;
        case 'matwprojectusa.org':
          dispatch(setCountryCode('US'));
          break;
        case 'matwprojectca.org':
          dispatch(setCountryCode('CA'));
          break;
        case 'matwprojectfr.org':
          dispatch(setCountryCode('FR'));
          break;
        case 'matwprojectme.org':
          dispatch(setCountryCode('ME'));
          break;
        case 'matwprojectmys.org':
          dispatch(setCountryCode('MY'));
          break;
        case 'matwprojectsgp.org':
          dispatch(setCountryCode('SG'));
          break;
        case 'matwprojectid.org':
          dispatch(setCountryCode('ID'));
          break;
        case 'matwprojectie.org':
          dispatch(setCountryCode('IE'));
          break;
        default:
          dispatch(setCountryCode('AU'));
          break;
      }
    }
    // const newScript = document.createElement('script');
    // newScript.id = 'zsiqchat';
    // newScript.type = 'text/javascript';
    // newScript.innerHTML = 'var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq60bd1fd1aa1065847adac0e6df33ad8fe13971fe786f7d88146522701fde175f", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);';

    // // Append the script to the end of the body
    // document.body.appendChild(newScript);

    // const handleBodyChange = (mutations) => {
    //   // document.body.removeChild(newScript);
    //   // document.body.appendChild(newScript);
    //   console.log('Body mutations=',mutations);
    //   mutations.forEach((mutation) => {
    //     console.log('Body tag has changed!',mutation.parentNode);
    //   });
    // };

    // Set up the MutationObserver
    // const observer = new MutationObserver(handleBodyChange);
    // observer.observe(document.body, { childList: true, subtree: false });
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      // observer.disconnect();
      // document.body.removeChild(newScript);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <div
      id="main_layout"
      className={`w-full bg-[#e2eff3] min-h-screen ${className ? className : ''} ${currentLang === 'ar' ? 'font-[AlmariMedium]' : ''}`}
      lang={currentLang || 'en'}
    >
      <Helmet>
        {/* <link rel="canonical" href={`${window.location.protocol + "//" + window.location.host === 'matwproject.org.uk' ? 'matwproject.org' : window.location.host}${window.location.pathname}`} /> */}
        {/* {window.location.host === 'matwproject.org.uk'
          ? <link rel="canonical" href={`${window.location.protocol + "//" + 'matwproject.org'}${window.location.pathname}`} />
          : <link rel="canonical" href={`${window.location.protocol + "//" + window.location.host}${window.location.pathname}`} />
        } */}
        <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
      </Helmet>

      {/* <div className="gtranslate_wrapper" onClick={handleClick}></div> */}
      {/* {showLastTenNightsModal
        ? <div className="bg-[#000] bg-opacity-50 w-full h-full fixed top-0 z-50">
          <div className="w-full md:w-[500px] bg-[#E1EFF2] h-full py-3 md:p-4">
            <div className="w-full cursor-pointer flex px-3 md:px-0" onClick={() => setShowLastTenNightsModal(false)}>
              <div className="bg-[#F60362] flex justify-center items-center w-[24px]">
                <FaXmark color='white' size={20} />
              </div>
              <strong className="text-[#253B7E] ml-1">Close</strong>
            </div>
            <LaylatulQadrWidget onModal={true} />
          </div>
        </div>
        : <div
          onClick={() => setShowLastTenNightsModal(true)}
          className="flex fixed top-1/2 -left-[34px] rounded-b-2xl bg-[#F60362] text-white -rotate-90 px-4 py-2 text-center z-40 font-['Montserrat'] font-bold cursor-pointer uppercase">
          give last
          <br />
          ten nights
        </div>
      } */}
      {/* <PopUp /> */}
      {!hideNavbar && (
        <CustomNavbar
          navigation={navigation}
          products={allProducts}
          scrollPosition={scrollPosition}
          isAus={isAus}
          isUk={isUk}
          isRamadanPage={isRamadanPage}
          showRegularImg={showRegularImg}
        />
      )}
      {highlights === undefined && !hideHighlights ? <Highlights /> : ''}

      {/* {isUk && <div className="bg-[#00A3DA] h-16 flex justify-center items-center text-white text-xs min-[380px]:text-base md:text-xl">
        <a href="tel:+44 2080895843" target="_self" rel="noopener noreferrer" className="flex justify-center items-center flex-col md:flex-row">
          <div className="flex items-center">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 md:w-6 md:h-6 mr-1 md:mr-3">
                <path d="M27.7875 15C27.5082 14.2211 27.0614 13.513 26.4785 12.9256C25.8957 12.3383 25.1911 11.886 24.4144 11.6006L24.375 8.4375C24.375 3.75 19.7437 0 14.0625 0C8.38125 0 3.75 3.75 3.75 8.4375V11.5875C2.65675 11.9757 1.70994 12.6919 1.03881 13.6382C0.367665 14.5845 0.00489223 15.7149 0 16.875L0 18.75C0 20.2418 0.592632 21.6726 1.64752 22.7275C2.70242 23.7824 4.13316 24.375 5.625 24.375H7.5V11.25H5.625V8.4375C5.625 4.81875 9.375 1.875 14.0625 1.875C18.75 1.875 22.5 4.81875 22.5 8.4375V11.25H20.625V24.375H22.5C23.9918 24.375 25.4226 23.7824 26.4775 22.7275C27.5324 21.6726 28.125 20.2418 28.125 18.75V21.9937C28.125 23.6199 27.479 25.1794 26.3292 26.3292C25.1794 27.479 23.6199 28.125 21.9937 28.125H18.75C18.75 27.6277 18.5525 27.1508 18.2008 26.7992C17.8492 26.4475 17.3723 26.25 16.875 26.25H15C14.5027 26.25 14.0258 26.4475 13.6742 26.7992C13.3225 27.1508 13.125 27.6277 13.125 28.125C13.125 28.6223 13.3225 29.0992 13.6742 29.4508C14.0258 29.8025 14.5027 30 15 30H21.9937C24.1171 30 26.1536 29.1565 27.655 27.655C29.1565 26.1536 30 24.1171 30 21.9937V15H27.7875Z" fill="white" />
              </svg>
              UK Donation Hotline 0208 089 5843
            </div>
            <div className="flex items-center md:ml-2">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 md:w-6 md:h-6 mr-1 md:mr-3">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.375 11C0.375 8.18207 1.49442 5.47956 3.48699 3.48699C5.47956 1.49442 8.18207 0.375 11 0.375C13.8179 0.375 16.5204 1.49442 18.513 3.48699C20.5056 5.47956 21.625 8.18207 21.625 11C21.625 13.8179 20.5056 16.5204 18.513 18.513C16.5204 20.5056 13.8179 21.625 11 21.625C8.18207 21.625 5.47956 20.5056 3.48699 18.513C1.49442 16.5204 0.375 13.8179 0.375 11ZM11.9375 4.75C11.9375 4.50136 11.8387 4.2629 11.6629 4.08709C11.4871 3.91127 11.2486 3.8125 11 3.8125C10.7514 3.8125 10.5129 3.91127 10.3371 4.08709C10.1613 4.2629 10.0625 4.50136 10.0625 4.75V11C10.0624 11.1589 10.1028 11.3153 10.1797 11.4544C10.2567 11.5934 10.3678 11.7107 10.5025 11.795L14.2525 14.1388C14.4633 14.2707 14.718 14.3135 14.9604 14.2577C15.0804 14.2301 15.1938 14.1791 15.2941 14.1076C15.3944 14.0362 15.4797 13.9457 15.545 13.8412C15.6103 13.7368 15.6545 13.6206 15.6749 13.4991C15.6953 13.3777 15.6916 13.2534 15.6639 13.1334C15.6363 13.0134 15.5853 12.9 15.5139 12.7997C15.4424 12.6993 15.3519 12.6141 15.2475 12.5487L11.9375 10.48V4.75Z" fill="white" />
              </svg>
              10am-8pm
            </div>
        </a>
      </div>} */}
      {/* {isAus &&
        <div className={`bg-[#00A3DA] h-16 flex justify-center items-center font-bold- text-white text-sm min-[480px]:text-base md:text-xl`}>
          <a href="tel:+61 282239876 " target="_self" rel="noopener noreferrer" className="flex justify-center items-center flex-col md:flex-row">
            <div className="flex items-center">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 md:w-6 md:h-6 mr-1 md:mr-3">
                <path d="M27.7875 15C27.5082 14.2211 27.0614 13.513 26.4785 12.9256C25.8957 12.3383 25.1911 11.886 24.4144 11.6006L24.375 8.4375C24.375 3.75 19.7437 0 14.0625 0C8.38125 0 3.75 3.75 3.75 8.4375V11.5875C2.65675 11.9757 1.70994 12.6919 1.03881 13.6382C0.367665 14.5845 0.00489223 15.7149 0 16.875L0 18.75C0 20.2418 0.592632 21.6726 1.64752 22.7275C2.70242 23.7824 4.13316 24.375 5.625 24.375H7.5V11.25H5.625V8.4375C5.625 4.81875 9.375 1.875 14.0625 1.875C18.75 1.875 22.5 4.81875 22.5 8.4375V11.25H20.625V24.375H22.5C23.9918 24.375 25.4226 23.7824 26.4775 22.7275C27.5324 21.6726 28.125 20.2418 28.125 18.75V21.9937C28.125 23.6199 27.479 25.1794 26.3292 26.3292C25.1794 27.479 23.6199 28.125 21.9937 28.125H18.75C18.75 27.6277 18.5525 27.1508 18.2008 26.7992C17.8492 26.4475 17.3723 26.25 16.875 26.25H15C14.5027 26.25 14.0258 26.4475 13.6742 26.7992C13.3225 27.1508 13.125 27.6277 13.125 28.125C13.125 28.6223 13.3225 29.0992 13.6742 29.4508C14.0258 29.8025 14.5027 30 15 30H21.9937C24.1171 30 26.1536 29.1565 27.655 27.655C29.1565 26.1536 30 24.1171 30 21.9937V15H27.7875Z" fill="white" />
              </svg>
              AUS Donation Hotline +61 2 9758 9037
            </div>
            <div className="flex items-center md:ml-2">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 md:w-6 md:h-6 mr-1 md:mr-3">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.375 11C0.375 8.18207 1.49442 5.47956 3.48699 3.48699C5.47956 1.49442 8.18207 0.375 11 0.375C13.8179 0.375 16.5204 1.49442 18.513 3.48699C20.5056 5.47956 21.625 8.18207 21.625 11C21.625 13.8179 20.5056 16.5204 18.513 18.513C16.5204 20.5056 13.8179 21.625 11 21.625C8.18207 21.625 5.47956 20.5056 3.48699 18.513C1.49442 16.5204 0.375 13.8179 0.375 11ZM11.9375 4.75C11.9375 4.50136 11.8387 4.2629 11.6629 4.08709C11.4871 3.91127 11.2486 3.8125 11 3.8125C10.7514 3.8125 10.5129 3.91127 10.3371 4.08709C10.1613 4.2629 10.0625 4.50136 10.0625 4.75V11C10.0624 11.1589 10.1028 11.3153 10.1797 11.4544C10.2567 11.5934 10.3678 11.7107 10.5025 11.795L14.2525 14.1388C14.4633 14.2707 14.718 14.3135 14.9604 14.2577C15.0804 14.2301 15.1938 14.1791 15.2941 14.1076C15.3944 14.0362 15.4797 13.9457 15.545 13.8412C15.6103 13.7368 15.6545 13.6206 15.6749 13.4991C15.6953 13.3777 15.6916 13.2534 15.6639 13.1334C15.6363 13.0134 15.5853 12.9 15.5139 12.7997C15.4424 12.6993 15.3519 12.6141 15.2475 12.5487L11.9375 10.48V4.75Z" fill="white" />
              </svg>
              9am-10pm Mon-Fri
            </div>
          </a>
        </div>
       } */}

      <div className={`${childClasses ? childClasses : 'pb-10'} ${propertyName ? 'bg-white' : ''}`}>{children}</div>

      {/* {scrollPosition > 300 && <div className="rounded-full opacity-50 hover:opacity-100 bg-[#093484] transition-all duration-200 hover:shadow-md w-12 h-12 flex justify-center items-center cursor-pointer fixed right-8 bottom-8 text-white" onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
        </svg>
      </div>} */}

      {!hideFooter && <Footer />}
      {!hideMainFooter && <MainFooter />}
    </div>
  );
};
export default Layout;
