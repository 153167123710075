import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { useNavigate } from 'react-router';
import DateInput from '../../components/input/dateInput';

const modeTypes = [
  { lable: 'All', value: '' },
  { lable: 'Payment', value: 'payment' },
  { lable: 'Subscription', value: 'subscription' },
];

const paymentTypes = [
  { lable: 'Stripe', value: 'stripe' },
  { lable: 'Paypal', value: 'paypal' },
  { lable: 'Crypto', value: 'crypto' },
];

const currenciesTypes = [
  { lable: 'All', value: '' },
  { lable: 'AUD', value: 'aud' },
  { lable: 'USD', value: 'usd' },
  { lable: 'EUR', value: 'eur' },
  { lable: 'GBP', value: 'gbp' },
  { lable: 'CAD', value: 'cad' },
  { lable: 'SGD', value: 'sgd' },
  { lable: 'MYR', value: 'myr' },
];

const statusTypes = [
  { lable: 'All', value: '' },
  { lable: 'Paid', value: 'paid' },
  { lable: 'Unpaid', value: 'unpaid' },
];

const ProductsTable = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { columnsData, tableData, type, isLoading, currency, search, pageSize, lastPage, currentPage, getProducts, setSearchItems, searchItems } =
    props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  let localPageSize = Number(localStorage.getItem("pageSize"));
  
  const [loadingReset, setLoadingReset] = useState(false);
  const tableRef = useRef(null);

  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;

  const handleSearch = (name, value) => {
    setSearchItems({ [name]: value });
  };

  const handleReset = () => {
    let temp = {
      payment_status: '',
      date_from: new Date().toISOString().split('T')[0],
      date_to: new Date().toISOString().split('T')[0],
      payment_gateway: '',
      page_no: 1,
      per_page: 10,
      currency: '',
      mode: '',
    };
    setLoadingReset(true);
    setSearchItems(temp);
  };
  /* -------------------------------------------------------------------------- */

  const total = useMemo(() => {
    if (tableData.length === 0) return 0;
    if (tableData[0].hasOwnProperty('crypto_currency_total_amount_paid')) {
      return tableData.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.crypto_currency_total_amount_paid), 0);
    }
    if (tableData[0].hasOwnProperty('order_subtotal_amount')) {
      return tableData.reduce((accumulator, currentValue) => accumulator + currentValue.order_subtotal_amount, 0);
    }
  }, [tableData]);

  return (
    <div className="sm:overflow-auto w-full">
      {type === 'receipts' && (
        <header className="relative flex flex-col justify-between">
          <div className="flex flex-wrap">
            <div className="basis-full sm:basis-1/2 md:basis-1/4 lg:basis-1/6 mt-2 lg:mt-0 px-1">
              <DateInput
                title="Date From"
                placeholder="Date From"
                onSearch={e => {
                  handleSearch('date_from', e);
                }}
                value={searchItems.date_from}
                className="w-full"
              />
            </div>
            <div className="basis-full sm:basis-1/2 md:basis-1/4 lg:basis-1/6 mt-2 lg:mt-0 px-1">
              <DateInput
                title="Date To"
                placeholder="Date To"
                onSearch={e => {
                  handleSearch('date_to', e);
                }}
                value={searchItems.date_to}
                className="w-full"
                min={searchItems.date_from}
              />
            </div>
            <div className="basis-full sm:basis-1/2 md:basis-1/4 lg:basis-1/6 mt-2 lg:mt-0 px-1">
              <div className="flex flex-col justify-start items-start w-full">
                <label className="text-sm text-stone-400" htmlFor="status">
                  Status
                </label>
                <select
                  name="payment_status"
                  value={searchItems.payment_status}
                  className="cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full"
                  onChange={e => {
                    handleSearch('payment_status', e.target.value === 'All' ? '' : e.target.value);
                  }}
                >
                  {statusTypes.map(type => (
                    <option key={type.lable} value={type.value} className="cursor-pointer">
                      {type.lable}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="basis-full sm:basis-1/2 md:basis-1/4 lg:basis-1/6 mt-2 lg:mt-0 px-1">
              <div className="flex flex-col justify-start items-start w-full">
                <label className="text-sm text-stone-400" htmlFor="mode">
                  Mode
                </label>
                <select
                  name="mode"
                  value={searchItems.mode}
                  className="cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full"
                  onChange={e => {
                    handleSearch('mode', e.target.value === 'All' ? '' : e.target.value);
                  }}
                >
                  {modeTypes.map(type => (
                    <option key={type.lable} value={type.value} className="cursor-pointer">
                      {type.lable}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="basis-full sm:basis-1/2 md:basis-1/4 lg:basis-1/6 mt-2 lg:mt-0 px-1">
              <div className="flex flex-col justify-start items-start w-full">
                <label className="text-sm text-stone-400" htmlFor="payment_gateway">
                  Payment Gateway
                </label>
                <select
                  name="payment_gateway"
                  value={searchItems.payment_gateway}
                  className="cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full"
                  onChange={e => {
                    handleSearch('payment_gateway', e.target.value === 'All' ? '' : e.target.value);
                  }}
                >
                  {paymentTypes.map(type => (
                    <option key={type.lable} value={type.value} className="cursor-pointer">
                      {type.lable}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="basis-full sm:basis-1/2 md:basis-1/4 lg:basis-1/6 mt-2 lg:mt-0 px-1">
              <div className="flex flex-col justify-start items-start w-full">
                <label className="text-sm text-stone-400" htmlFor="currency">
                  Currency
                </label>
                <select
                  name="currency"
                  value={searchItems.currency}
                  className="cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full"
                  onChange={e => {
                    handleSearch('currency', e.target.value === 'All' ? '' : e.target.value);
                  }}
                >
                  {currenciesTypes.map(type => (
                    <option key={type.lable} value={type.value} className="cursor-pointer">
                      {type.lable}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="basis-full md:basis-1/4 lg:basis-1/6 pl-1 flex items-end py-2 lg:mt-0">
              <button
                disabled={isLoading}
                onClick={search}
                className="flex justify-center items-center text-white text-md font-medium- mr-1 rounded-md h-[42px] px-2 hover:bg-[#db346e] bg-[#49acea] transition-all duration-200"
              >
                {/* className='flex justify-center items-center text-white text-md font-medium mr-1 rounded-md h-[42px] px-2 hover:bg-[#db346e] bg-[#49acea]- cursor-not-allowed !bg-gray-400 transition-all duration-200'> */}
                {isLoading ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 mr-1 cursor-pointer">
                      <path
                        fillRule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>Search</span>
                  </>
                )}
              </button>
              <button
                onClick={() => handleReset()}
                className="flex justify-center items-center text-white text-md font-medium- rounded-md h-[42px] px-2 bg-[#db346e] hover:bg-[#49acea] transition-all duration-200"
              >
                {isLoading && loadingReset ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5 cursor-pointer transition-all duration-300 hover:rotate-90"
                    >
                      <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                    </svg>
                    <span>Reset</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </header>
      )}
      <div className="mt-8 overflow-hidden">
        {isLoading ? (
          <div role="status" className="w-full col-span-12 flex justify-center items-center min-h-[500px]">
            <svg
              aria-hidden="true"
              className="w-20 h-20 mr-2 text-gray-200 animate-spin fill-[#49acea]"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : data.length > 0 ? (
          <div className="w-full overflow-x-auto">
            <table {...getTableProps()} className="w-full 2xl:table-fixed" variant="simple" color="gray-500" mb="24px" ref={tableRef}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers
                      .filter(i => i.id !== 'order_id' && i.id !== 'receipt_url')
                      .map((column, index) => (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className="border-b border-gray-200 pr-16 pb-[10px] text-start uppercase"
                          key={index}
                        >
                          <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs whitespace-nowrap">{column.render('Header')}</div>
                        </th>
                      ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row);
                  let productName = row.original && row.original.name;
                  let productId = row.original && row.original.id;
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={rowIndex}
                      className={`${rowIndex % 2 === 0 ? 'bg-gray-50' : ''} transition-all duration-200 hover:bg-gray-100`}
                    >
                      {row.cells
                        .filter(i => i.column.id !== 'order_id' && i.column.id !== 'receipt_url')
                        .map((cell, index) => {
                          let data = '';
                          if (cell.column.id === 'amount_total') {
                            data = (
                              <p className="text-xs text-left font-medium text-navy-700 px-1">
                                {cell.value ? cell.value : <span className="text-xs font-normal text-stone-400">NULL</span>}
                              </p>
                            );
                          } else if (cell.column.id === 'currency') {
                            data = (
                              <p className="text-xs text-left font-medium text-navy-700 pr-1 overflow-hidden">
                                {cell.value ? cell.value : <span className="text-xs font-normal text-stone-400">NULL</span>}
                              </p>
                            );
                          } else if (cell.column.id === 'host') {
                            data = (
                              <p className="text-xs text-left font-medium text-navy-700 pr-1 2xl:px-1 max-h-12 overflow-hidden">
                                {cell.value ? cell.value : <span className="text-xs font-normal text-stone-400">NULL</span>}
                              </p>
                            );
                          } else if (cell.column.id === 'mode') {
                            data = (
                              <p className="text-xs text-left font-medium text-navy-700 pr-1 lowercase overflow-hidden">
                                {cell.value ? cell.value : <span className="text-xs font-normal text-stone-400">NULL</span>}
                              </p>
                            );
                          } else if (cell.column.Header === 'Actions' && row.values.payment_status === 'paid') {
                            data = (
                              <div className="flex text-white">
                                <a
                                  target="_blank"
                                  className="bg-[#49acea] text-white hover:bg-[#F60362] h-10 w-28 min-w-[40px] px-3 flex items-center justify-center rounded mr-2 transition-all duration-200"
                                  href={page[0].values.receipt_url}
                                >
                                  View Receipt
                                </a>
                                {/* <button
                                  className="bg-[#49acea] text-white hover:bg-[#F60362] h-10 w-10- min-w-[40px]- px-3 flex items-center justify-center rounded mr-2 transition-all duration-200"
                                  onClick={() => getProducts(row.values.order_id, row.values.payment_method, row.values.currency)}
                                >
                                  Products
                                </button> */}
                              </div>
                            );
                          } else {
                            data = <p className="text-xs text-left font-medium text-navy-700">{cell.value}</p>;
                          }
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={index}
                              className="pt-[14px] pb-[16px] sm:text-[14px] text-stone-500 font-normal max-w-max- w-[150px]- max-w-[120px]-"
                            >
                              {data}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="w-full flex items-center justify-center">No Data</div>
        )}
        {(type === 'receiptProduct' || type === 'receiptTransaction') && (
          <div class="border-t border-gray-200 pt-2 flex flex-col items-end">
            <div class="px-4 py-2 rounded-md bg-gray-100- border-gray-900 text-gray-700 flex justify-center items-center">
              Total Paid: <h2 class="font-bold ml-2 text-gray-900 text-xl">{`${total} ${currency}`}</h2>
            </div>
          </div>
        )}
        {type === 'receipts' && (
          <div className="pagination flex flex-col lg:flex-row items-start my-6 border-t border-stone-200 pt-4">
            {/* <div className="flex flex-col items-start mb-4 lg:mb-0 basis-full xl:basis-1/4">
            <p><span className="text-sm text-stone-400 mr-1 inline-block text-left min-w-[130px]">Total Transactions:</span>{totalTrxs.count}</p>
            {totalTrxs.amount.map((item, index) => {
              return <p key={`total${index}`}><span className="text-sm text-stone-400 mr-1 min-w-[130px] inline-block text-left">Total {item.currency} Amount:</span>{handleCurrency(item.order_total_amount)}</p>
            })}
          </div> */}
            <div className="flex items-center justify-center flex-col w-full md:flex-row basis-full xl:basis-2/4-">
              <div className="flex">
                <button
                  onClick={() => handleSearch('page_no', 1)}
                  disabled={currentPage <= 1}
                  className={`${
                    currentPage <= 1 ? 'text-slate-400 ' : 'text-stone-600 hover:bg-[#49acea] hover:text-white hover:border-[#49acea]'
                  } border border-stone-200 rounded-md w-8 h-8 flex justify-center items-center transition-all duration-200 mr-2`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path
                      fillRule="evenodd"
                      d="M15.79 14.77a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L11.832 10l3.938 3.71a.75.75 0 01.02 1.06zm-6 0a.75.75 0 01-1.06.02l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 111.04 1.08L5.832 10l3.938 3.71a.75.75 0 01.02 1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>{' '}
                <button
                  onClick={() => handleSearch('page_no', currentPage - 1)}
                  disabled={currentPage < 2}
                  className={`${
                    currentPage < 2 ? 'text-slate-400' : 'text-stone-600 hover:bg-[#49acea] hover:text-white hover:border-[#49acea]'
                  } border border-stone-200 rounded-md w-8 h-8 flex justify-center items-center transition-all duration-200 mr-2`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>{' '}
                <button
                  onClick={() => handleSearch('page_no', currentPage + 1)}
                  disabled={currentPage >= lastPage}
                  className={`${
                    currentPage >= lastPage ? 'text-slate-400' : 'text-stone-600 hover:bg-[#49acea] hover:text-white hover:border-[#49acea]'
                  } border border-stone-200 rounded-md w-8 h-8 flex justify-center items-center transition-all duration-200 mr-2`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path
                      fillRule="evenodd"
                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>{' '}
                <button
                  onClick={() => handleSearch('page_no', lastPage)}
                  disabled={currentPage >= lastPage}
                  className={`${
                    currentPage >= lastPage ? 'text-slate-400' : 'text-stone-600 hover:bg-[#49acea] hover:text-white hover:border-[#49acea]'
                  } border border-stone-200 rounded-md w-8 h-8 flex justify-center items-center transition-all duration-200 mr-2`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path
                      fillRule="evenodd"
                      d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <span className="ml-4">
                Page{' '}
                <strong>
                  {currentPage} of {lastPage}
                </strong>{' '}
              </span>
              <span className="ml-2">
                | Go to page:{' '}
                <input
                  type="number"
                  defaultValue={searchItems.currentPage}
                  className="w-[100px] border border-stone-200 rounded-md px-1 focus:outline-none"
                  onChange={e => {
                    const _page = e.target.value ? Number(e.target.value) : 0;
                    handleSearch('page_no', _page);
                    // onChangePage(page)
                  }}
                />
              </span>{' '}
              <select
                value={searchItems.pageSize}
                className="cursor-pointer focus:outline-none"
                onChange={e => {
                  handleSearch('per_page', Number(e.target.value));
                  localStorage.setItem('pageSize', Number(e.target.value))
                  // onChangePageSize(Number(e.target.value))
                }}
              >
                {[5, 10, 100, 500, 1000].map(pageSize => (
                  <option selected={Number(localStorage.getItem('pageSize')) === pageSize} key={pageSize} value={pageSize} className="cursor-pointer">
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsTable;
