import { useEffect, useState } from 'react';
import ProductBox from '../../components/general/productBox';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import eventBanner from "../../images/events/event-banner-plant.jpg";
import { selectProducts } from '../../store/products';

const YasirandnoumanPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [yasirProducts, setYasirProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'MKD-MN-001') tempProductsList[0] = inner_item_product; // Where Most Needed
                    if (inner_item.name === 'Education') {
                        if (inner_item_product.creator === 'EDU-GEN-BTS-20230-002') tempProductsList[1] = inner_item_product; // Orphan Education Supply kit
                    };
                    if (inner_item.name === 'Orphan Feast') {
                        if (inner_item_product.creator === 'FST-DEL-F50-2023-01-009') tempProductsList[2] = inner_item_product; // Orphan Feast For 50
                    };
                    if (inner_item.name === 'Support An Orphan') {
                        if (inner_item_product.creator === 'OPR-FUND-LB-2023-01-01') tempProductsList[4] = inner_item_product; // Orphan Centre Lebanon
                    };
                    if (inner_item.name === 'Food Aid') {
                        if (inner_item_product.creator === 'FWD-DIS-AR-GN-002') tempProductsList[5] = inner_item_product; // Feed 10 Hungry People
                        if (inner_item_product.creator === 'FWD-DIS-BMY-2023-01-050') tempProductsList[7] = inner_item_product; // Baby Milk For 52 Weeks
                    };
                    if (inner_item.name === 'Water Aid') {
                        if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') tempProductsList[6] = inner_item_product; // Water For 500 People
                        if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') tempProductsList[8] = inner_item_product; // Africa Basic Water Well
                        if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') tempProductsList[9] = inner_item_product; // Bangladesh Basic Water Well
                        if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') tempProductsList[10] = inner_item_product; // Pakistan Basic Water Well
                        if (inner_item_product.creator === 'WWC-CON-IBW-2023-01-031') tempProductsList[11] = inner_item_product; // India Basic Water Well
                        if (inner_item_product.creator === 'WWC-CON-H2W-2023-01-025') tempProductsList[12] = inner_item_product; // H2 Hand Pump Water Well / Heavy Duty Hand Pump Uganda
                    };
                    if (inner_item.name === 'Build A Masjid') {
                        if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') tempProductsList[13] = inner_item_product; // Africa Small Masjid
                        if (inner_item_product.creator === 'MSJ-CON-IMJ-2023-01-014') tempProductsList[14] = inner_item_product; // Indonesia Masjid
                    };
                    if (inner_item.name === 'Earthquake Appeal') {
                        if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') tempProductsList[15] = inner_item_product; // Morocco Appeal
                    };
                    if (inner_item.name === 'Libya Floods') {
                        if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') tempProductsList[16] = inner_item_product; // Libya Floods
                    };
                    if (inner_item.name === 'Caravan') {
                        if (inner_item_product.creator === 'EM-SDQ-EM-02-2023') tempProductsList[17] = inner_item_product; // Emergency Caravan Homes
                    };
                    if (inner_item_product.creator === 'RM24-ZM-013') tempProductsList[18] = inner_item_product; // Zakat Al Mal
                    // if (inner_item.name === 'Give Zakat') {
                    // };
                    if (inner_item.name === 'General') {
                        if (inner_item_product.creator === 'RM24-FA-010') tempProductsList[19] = inner_item_product; // Kaffarah
                        if (inner_item_product.creator === 'RM24-FA-011') tempProductsList[20] = inner_item_product; // Fidya
                    };
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
    tempProductsList = tempProductsList.filter(n => n)
    setYasirProducts(tempProductsList);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return (
    <Layout>
      <div className="flex md:w-full md:h-[120px] lg:w-full lg:h-[160px] xl:w-full xl:h-[200px] 2xl:w-full 2xl:h-[250px]">
        {
          <img
            src={eventBanner}
            alt={'yasminmogahedmalaysia'}
            className="w-full"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/general/logo-replace.png';
              currentTarget.className = 'max-h-[150px] mx-auto';
            }}
          />
        }
      </div>
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12 px-3- md:px-4-">
          <div className="col-span-12 text-center text-5xl md:text-6xl text-[#253b7e] flex justify-center items-center py-2- md:py-8- font-['Gotcha'] h-[125px]">
            Plant Your Sadaqah
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-row justify-center flex-wrap">
              {yasirProducts.map((item, index) => {
                return (
                  <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                  </div>
                );
              })}
            </div>
            <div className={`col-span-12 rounded-xl mt-6 lg:min-h-[200px] bg-white p-6 lg:p-12 text-center shadow-lg`}>
              <h1 className="text-[#00a3da] text-4xl mb-4">“Charity does not decrease wealth.” - The Prophet Mohammed ﷺ [Muslim]</h1>
              <p className="text-[#777] text-xl mb-4">
                Never underestimate the power of your Sadaqah or Zakat. It can change lives, uplifting the most vulnerable people in the world. Donate
                now.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default YasirandnoumanPage;
