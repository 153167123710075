import React from 'react';
import MaxWidthContainer from './maxWidthContainer';
import shapeLeft from '../../images/landings/ramadan/quote-wrapper-shape.png';
import shapeRight from '../../images/landings/ramadan/quote-wrapper-shape-reverted.png';

export const QuoteWrapper = ({ text, quote = true, quoteText = true, className, lang = 'en' }) => {
  return (
    <MaxWidthContainer className="max-w-[1080px]">
      <div className={`bg-white rounded-[0_24px_0_24px] p-5 md:p-8 flex flex-col md:flex-row gap-2 md:gap-10 lg:gap-16 ${className ? className : ''}`}>
        <img src={shapeLeft} alt="icon" className="self-start" />
        <div className="md:text-center">
          {text ? (
            <p className={`text-lg md:text-2xl py-3 md:py-6 text-[#14A2DC] font-bold italic`}>{text}</p>
          ) : (
            <p className={`text-lg md:text-2xl py-3 md:py-6 text-[#14A2DC] font-bold italic`}>
              In the name of Allah, the Gracious, the Merciful. Surely, We sent it down on the Night of Destiny. And what should make thee know what
              the Night of Destiny is? The Night of Destiny is better than a thousand months. Therein descend angels and the Spirit by the command
              of their Lord — with every matter. It is all peace till the rising of the dawn.
            </p>
          )}
          {quote ? <small className={`text-[#AAAAAA] text-2xl font-light`}>{quoteText ? <span>{quoteText}</span> : ''}</small> : ''}
        </div>
        <img src={shapeRight} alt="icon" className="self-end" />
      </div>
    </MaxWidthContainer>
  );
};
