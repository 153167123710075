import { useEffect, useState } from 'react';
import ShowCurrencies from '../../utils/showCurrencies';
import Skeleton from 'react-loading-skeleton';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SuggestionProductBoxCheckout = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { product, currency, handleAdded } = props;
    const [isAnyAmount, setIsAnyAmount] = useState(false);
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleCustomProductAmount = (product) => {
        if (
            Number(process.env.REACT_APP_MAKE_DONATION_CATEGORY_ID) === Number(product.category_id) ||
            product.id === 52 ||
            product.id === 69
        ) {
            setIsAnyAmount(true);
        } else {
            if (Number(product[currency.toLowerCase()]) === 1 && !product.quantity) {
                setIsAnyAmount(true);
            } else {
                setIsAnyAmount(false);
            }
        }
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        handleCustomProductAmount(product)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);
    /* -------------------------------------------------------------------------- */
    return (
        <div className="basis-full min-[450px]:basis-8/12 md:basis-9/12 lg:basis-10/12 flex md:flex-col items-center rounded-md shadow-md bg-white p-2 sm:p-2-">
            <div className="w-full min-[380px]:w-7/12 sm:w-full flex flex-row sm:flex-wrap md:flex-col items-center transition-all duration-200 hover:bg-stone-100-">
                <div className='w-[40px] min-w-[40px] md:w-full rounded-md hidden- mx-auto- sm:flex sm:mr-2 justify-center items-center mr-1 md:mb-2 [&>span]:w-full h-[40px] md:h-[123px] overflow-hidden'>
                    {/* {product.image_link
                        ? <img src={product.image_link} className='w-full rounded-md' alt="matw" onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/general/logo-replace.png";
                        }} />
                        : <Skeleton height={124} />
                    } */}
                    {product ? product.image_link
                        ? <LazyLoadImage
                            alt={product.name}
                            effect="blur"
                            width={`100%`}
                            delayTime={500}
                            className='rounded cursor-pointer'
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/images/general/logo-replace.png";
                            }}
                            src={product.image_link} // use normal <img> attributes as props
                        />
                        : <img className='w-full' src="/images/general/logo-replace.png" alt="" />
                        : <Skeleton width={`100%`} height={123} />
                        // : <Skeleton width={`100%`} height={`100%`} />
                    }
                </div>
                {/* <h3 className="text-stone-500 leading-4 md:leading-none text-xs min-[450px]:text-sm sm:text-base md:text-sm md:text-center md:min-h-[30px] lg:min-h-[40px] flex items-center">{product.name}</h3> */}
                <h3 className="text-[#777] md:justify-center leading-4 md:leading-none text-xs min-[450px]:text-sm sm:text-base md:text-sm md:text-center md:min-h-[30px] lg:min-h-[40px] flex items-center md:w-full [&>span]:w-full">
                    {product ? product.name : <Skeleton width={`100%`} height={30} />}
                </h3>
            </div>
            <div className="w-full min-[380px]:w-4/12 md:basis-3/12 lg:basis-2/12 flex md:flex-col sm:justify-between items-center justify-end">
                <h2 className={`text-[#f60362] ${isAnyAmount ? 'text-xs md:text-sm' : 'md:text-lg'} whitespace-nowrap font-bold h-6- md:h-9- mr-2 sm:mr-0`}>
                    {product
                        ? isAnyAmount
                            ? 'Any Amount'
                            : <>{product.quantity
                                ? ShowCurrencies(currency, product.quantity * product[currency.toLowerCase()], true)
                                : ShowCurrencies(currency, product[currency.toLowerCase()], true)
                            }</>
                        : <Skeleton width={`100%`} height={20} />}
                </h2>
                <button
                    disabled={!product}
                    className={`text-white text-sm md:text-md lg:text-base flex rounded-lg md:rounded-md h-8 md:w-16 transition-all duration-200 ${product ? 'bg-[#f60362] hover:bg-[#00a3da] cursor-pointer' : 'bg-stone-400 cursor-not-allowed'} px-2 py-1 justify-center items-center`}
                    onClick={() => handleAdded()}
                >
                    Add
                </button>
            </div>
        </div>
    );
}

export default SuggestionProductBoxCheckout;
