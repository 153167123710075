// import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import InputField from "../components/fields/InputField";
import { useState } from "react";
import { toast } from "react-toastify";
// import Checkbox from "../components/checkbox";

const LoginPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: '',
    password: ''
  })
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const login = () => {
    if(userInfo.username === 'S@dmin7' && userInfo.password === 'm@p791') {
      localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9_.eyJ1c2VySWQiOiIxMjM0NTY3ODkwIiwidXNlck5hbWUiOiJ1c2VyMSJ9.987tb6OkebqtGN08bLzt8uaHgaFe8_i5RdAI4qVJgGk');
      navigate('/dashboard')
    } else {
      toast.error('Username or password is wrong!');
    }
  }
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  /* -------------------------------------------------------------------------- */
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] mx-auto w-full max-w-full flex-col items-center xl:max-w-[420px] bg-white rounded-2xl shadow-lg p-4 md:p-8">
        <h4 className="mb-2.5 text-4xl font-bold text-neutral-700 text-center">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600 text-center">
          Enter username and password to sign in!
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer ">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 ">
            Sign In with Google
          </h5>
        </div> */}
        {/* <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 " />
          <p className="text-base text-gray-600 "> or </p>
          <div className="h-px w-full bg-gray-200 " />
        </div> */}
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Username*"
          placeholder="Username"
          id="username"
          type="text"
          value={userInfo.username}
          onChange={(e) => setUserInfo({...userInfo, username: e.target.value})}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Enter password"
          id="password"
          type="password"
          value={userInfo.password}
          onChange={(e) => setUserInfo({...userInfo, password: e.target.value})}
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          {/* <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 ">
              Keep me logged In
            </p>
          </div> */}
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 "
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        <button className="linear mt-2 w-full rounded-xl bg-[#f60362] transition-all duration-200 hover:bg-[#00a3da] py-[12px] text-base font-medium text-white active:bg-brand-700"
        onClick={() => login()}>
          Sign In
        </button>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 ">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 "
          >
            Create an account
          </a>
        </div> */}
        <div className="mt-4">
          <a
            href="/"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 "
          >
            Back To Home
          </a>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
