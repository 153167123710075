import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import ProductBox from '../../components/general/productBox';
import WidgetHome from '../../components/widgets/widgetHome';
import eventBanner from "../../images/events/event-banner-last-ten-nights.jpg";
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const LiveFundraiserPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [liveFundraiserProducts, setLiveFundraiserProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempProductsList = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'EMR-DIS-MDPL-2023-01-102') tempProductsList[0] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-200') tempProductsList[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-203') tempProductsList[2] = inner_item_product;
          if (inner_item_product.creator === 'GD-MP015') tempProductsList[3] = inner_item_product;
          if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') tempProductsList[4] = inner_item_product;
          if (inner_item_product.creator === 'MSJ-CON-IMJ-2023-01-014') tempProductsList[5] = inner_item_product;
          if (inner_item_product.creator === 'MSJ-CON-YMJ-2023-01-015') tempProductsList[6] = inner_item_product;
          if (inner_item_product.creator === 'FWD-DIS-BMY-2023-01-050') tempProductsList[8] = inner_item_product;
          if (inner_item_product.creator === 'FST-DEL-F150-2023-01-010') tempProductsList[9] = inner_item_product;
          if (inner_item_product.creator === 'RM24-OP-014') tempProductsList[10] = inner_item_product;
          if (inner_item_product.creator === 'ORP-DIS-SOM-2023-01-039') tempProductsList[11] = inner_item_product;
          if (inner_item_product.creator === 'ORP-GEN-LOC-2023-01-129') tempProductsList[12] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') tempProductsList[13] = inner_item_product;
          if (inner_item_product.creator === 'FWD-DIS-WYH-2023-01-057') tempProductsList[14] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') tempProductsList[15] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-PSPW-2023-01-027') tempProductsList[16] = inner_item_product;
          // if (inner_item.name === 'Food Aid') {
          //     if (inner_item_product.creator === 'FWD-DIS-AR-GN-002') tempProductsList[0] = inner_item_product; // Feed 10 Hungry People
          //     if (inner_item_product.creator === 'FWD-DIS-FPY-2023-01-045') tempProductsList[2] = inner_item_product; // Food Pack For Yemen
          //     if (inner_item_product.creator === 'FWD-DIS-FPP-2023-01-046') tempProductsList[3] = inner_item_product; // Food Pack For Pakistan
          //     if (inner_item_product.creator === 'FWD-DIS-FPM-2023-01-044') tempProductsList[4] = inner_item_product; // Food Pack For Middle East
          //     if (inner_item_product.creator === 'FWD-DIS-BMW-2023-01-048') tempProductsList[5] = inner_item_product; // Baby Milk For 1 Week
          //     if (inner_item_product.creator === 'FWD-DIS-RBG-2023-01-052') tempProductsList[6] = inner_item_product; // Rice Bags
          //     if (inner_item_product.creator === 'FWD-DIS-DBD-2023-01-051') tempProductsList[7] = inner_item_product; // Bread
          // };
          // if (inner_item.name === 'Water Aid') {

          //     if (inner_item_product.creator === 'FWD-DIS-AR-GN-006') tempProductsList[1] = inner_item_product; // Water For 500 People
          //     if (inner_item_product.creator === 'FWD-DIS-WYX-2023-01-055') tempProductsList[8] = inner_item_product; // Water Distribution In Yemen 10K Litres
          //     if (inner_item_product.creator === 'FWD-DIS-WPV-2023-01-054') tempProductsList[9] = inner_item_product; // Water Distribution In Palestine 5K Litres
          //     if (inner_item_product.creator === 'FWD-DIS-WPX-2023-01-058') tempProductsList[10] = inner_item_product; // Water Distribution In Pakistan 10K Litres
          //     if (inner_item_product.creator === 'FWD-DIS-WDL-2023-01-053') tempProductsList[11] = inner_item_product; // Water Distribution In Lebanon
          //     if (inner_item_product.creator === 'WWC-CON-L1W-2023-01-026') tempProductsList[12] = inner_item_product; // L1 Africa Water Well
          //     if (inner_item_product.creator === 'WWC-CON-H1W-2023-01-024') tempProductsList[13] = inner_item_product; // H1 Hand Pump Water Well
          //     if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') tempProductsList[14] = inner_item_product; // Bangladesh Basic Water Well
          //     if (inner_item_product.creator === 'WWC-CON-H2W-2023-01-025') tempProductsList[15] = inner_item_product; // H2 Hand Pump Water Well
          // };
          // if (inner_item.name === 'Orphan Feast') {
          //     if (inner_item_product.creator === 'RM23-011') tempProductsList[16] = inner_item_product; // Feed 1 Orphan For 30 Days
          // };
          // if (inner_item.name === 'Give Zakat') {
          //     if (inner_item_product.creator === 'RM24-ZM-013') tempProductsList[17] = inner_item_product; // Zakat Al Mal
          // };
          // if (inner_item.name === 'General') {
          //     if (inner_item_product.creator === 'RM24-FA-010') tempProductsList[18] = inner_item_product; // Kaffarah
          //     if (inner_item_product.creator === 'RM24-FA-011') tempProductsList[19] = inner_item_product; // Fidya
          // };
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempProductsList = tempProductsList.filter(n => n)
    setLiveFundraiserProducts(tempProductsList);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products.length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout>
      {seo && (
        <Helmet>
          <title>{`${seo['events']['liveFundraiser']['title']}`}</title>
          <meta name="title" content={`${seo['events']['liveFundraiser']['title']}`} />
          <meta name="description" content={`${seo['events']['liveFundraiser']['description']}`} />
        </Helmet>
      )}
      <div className="flex md:w-full md:h-[120px]- lg:w-full lg:h-[160px]- xl:w-full xl:h-[200px]- 2xl:w-full 2xl:h-[250px]-">
        {isLoaded && (
          <img
            src={eventBanner}
            alt={'live fundariser'}
            className="w-full"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/general/logo-replace.png';
              currentTarget.className = 'max-h-[150px] mx-auto';
            }}
          />
        )}
      </div>
      {/* <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} /> */}
      <WidgetHome selectedCurrency={e => setSelectedCurrency(e.value)} />
      {/* /* --------------------------------- Youtube -------------------------------- */}
      {/* <div className="md:container md:mx-auto mt-0 md:mt-2 mb-4- md:p-2">
                <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px] md:rounded-xl overflow-hidden">
                    <iframe className='video'
                        width={'100%'}
                        height={'100%'}
                        title={'youtube'}
                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                        src={`https://youtube.com/embed/4cI1ApoJ3qw?controls=1&autoplay=0&loop=0&mute=0&playlist=4cI1ApoJ3qw`} />
                </div>
            </div> */}
      <div className="md:container md:mx-auto mt-5">
        <div className="grid grid-cols-12">
          <div className="col-span-12 text-center text-6xl text-[#253b7e] flex justify-center items-center font-['Gotcha'] h-[100px]">
            Live Fundraiser
          </div>
          <div className="col-start-1 col-span-12 rounded-md">
            <div className="flex flex-row justify-center flex-wrap">
              {liveFundraiserProducts.map((item, index) => {
                return (
                  <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                    <ProductBox product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                  </div>
                );
              })}
            </div>
            <div className={`col-span-12 rounded-xl mt-6 lg:min-h-[250px] bg-white p-6 lg:p-12 text-center shadow-lg`}>
              <h1 className="text-[#00a3da] text-4xl mb-4">“Charity does not decrease wealth.” - The Prophet Mohammed ﷺ [Muslim]</h1>
              <p className="text-[#777] text-xl mb-4">
                Never underestimate the power of your Sadaqah or Zakat. It can change lives, uplifting the most vulnerable people in the world. Donate
                now.
              </p>
            </div>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
};

export default LiveFundraiserPage;
