import { useEffect, useState } from 'react';
import { sustainableFaqs } from './faqs';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Loader } from '../../components/general';
import services from '../../services';
import Accordionn from '../../components/general/accordion';
import SadaqahWidget from './sadaqahWidget';
import FundraiserProductBox from '../../components/general/fundraiserProductBox';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import NewHelmetLayout from '../../components/general/newHelmetLayout';
const OliveTreeSadaqah = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [sustainalbeProducts, setSustainableProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const [futureProducts, setFutureProducts] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];


    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getFundraiserProducts = async () => {
        let tempFutureProducts = [];
        try {
            const response = await services.fundraiserProducts();
            response.data.map(item => {
                item.raised = 0;
                if (item.name === 'Yemen Water Plant' || item.name === 'Palestine Water Plant') {
                    item.goal = 200000;
                    tempFutureProducts.push(item)
                }
                return item;
            });
            getProductsReports(tempFutureProducts)
        } catch (error) {
            console.error('error========', error);
        }
    };
    const getProductsReports = async (tempFutureProducts) => {
        try {
            const response = await services.orphanProductTransactions();
            response.data.length && response.data.map((item, i) => {
                tempFutureProducts.map(future_item => {
                    if (future_item.name === item.product_name) {
                        future_item.raised = item.total_sales
                    }
                    return future_item
                })
                return null;
            })
            // Object.keys(response.data.data).length && Object.keys(response.data.data).map((keyName, i) => {
            //     tempFutureProducts.map(future_item => {
            //         if (future_item.name === keyName) {
            //             future_item.raised = response.data.data[keyName]
            //         }
            //         return future_item
            //     })
            //     return null;
            // })
            console.log(tempFutureProducts)
            setFutureProducts(tempFutureProducts)
        } catch (error) {
            console.error('error========', error);
        }
    };
    const getProductsByName = async (name) => {
        let tempProducts = [];
        try {
            const response = await services.categoryProductsByName(name);
            response.data.products.map(item => {
                if (Number(item.status) === 1) {
                    if (item.name === 'Olive Tree') {
                        tempProducts[0] = item;
                    }
                }
                return item;
            });
            setSustainableProducts(tempProducts)
        } catch (error) {
            console.error('error========', error);
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        // window.scroll(0, 0);
        getProductsByName('sustainable');
        getFundraiserProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <NewHelmetLayout className='!bg-white'>
            {seo &&
                <Helmet>
                    <title>{`${seo['sadaqahJariyah']['oliveTree']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
                    <meta name="description" content={`${seo['sadaqahJariyah']['oliveTree']['description']}`} />
                </Helmet>
            }
            <div className="w-full flex flex-wrap max-[380px]:min-h-[135px] min-[381px]:min-h-[165px] min-[501px]:min-h-[205px] sm:min-h-[260px] lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[600px] px-4 md:px-0">
                <div className="w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                    <iframe className='video'
                        width={'100%'}
                        height={'100%'}
                        title={'youtube'}
                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                        src={`https://youtube.com/embed/jzAZ03Qgf7k?autoplay=0`}
                    >
                    </iframe>
                </div>
            </div>

            {/* /* ------------------------------- Widget ----------------------------------- */}
            <div className='w-full lg:container lg:mx-auto px-4 xl:px-2 py-4 md:py-6 flex justify-center items-center'>
                <SadaqahWidget
                    extraProduct={sustainalbeProducts[0]}
                    defaultProduct={{
                        creator: "MKD-GEN-MIG-2023-01-126",
                        label: "Income Generation",
                        value: 111
                    }}
                // defaultProduct={{
                //     creator: sustainalbeProducts.creator,
                //     label: sustainalbeProducts.name,
                //     value: sustainalbeProducts.id
                // }}
                />
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------- Section One - Texts ---------------------------------- */}
            <div className="w-full flex flex-wrap justify-center md:container md:mx-auto px-4 py-4 md:py-8">
                <div className="basis-full flex flex-col justify-center mb-8 md:mb-16">
                    <div className="w-full md:container flex justify-center">
                        <div className="flex items-start mr-2 md:mr-4 w-[48px]">
                            <svg className='rotate-180' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 186.15">
                                <path d="M242.5,87.53c-1.52,7.1-2.55,14.35-4.63,21.27-10.7,35.68-35.15,57.79-69.71,69.59-5,1.71-10.16,2.82-15.56,4.28l-9.89-21.3c24.63-11.76,37.24-31.54,40.19-58.79H142.83V2.5H242.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                                <path d="M2.5,2.5h99.14c.39.75.67,1,.66,1.31-.22,28.16,1,56.43-1,84.46-3,41-25.34,69.46-62.62,86.09C30.52,178,21.75,180.14,13,183.08L2.5,161.56v-1a16.11,16.11,0,0,0,2.8-.76c18.55-8.7,29.5-23.71,35.12-42.94,1.31-4.48,1.63-9.25,2.48-14.32H2.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                            </svg>
                        </div>
                        <div className="flex w-[80%] md:w-[60%] xl:w-[40%]">
                            <p className="text-center text-lg md:text-4xl text-[#00a3da]">
                                <span className="font-['Gotcha'] font-medium text-[#253b7e] mx-2 md:mx-4">
                                    Sadaqah Jariyah
                                </span>
                                is a means of building sustainable communities and leaving a positive legacy for future generations.
                                <span className="font-['Abeezee'] text-[#253b7e] mx-2 md:mx-4 text-base md:text-lg">
                                    ~ Mufti Ismail Menk
                                </span>
                            </p>
                        </div>
                        <div className="flex items-end ml-2 md:ml-4 w-12">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245 186.15">
                                <path d="M242.5,87.53c-1.52,7.1-2.55,14.35-4.63,21.27-10.7,35.68-35.15,57.79-69.71,69.59-5,1.71-10.16,2.82-15.56,4.28l-9.89-21.3c24.63-11.76,37.24-31.54,40.19-58.79H142.83V2.5H242.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                                <path d="M2.5,2.5h99.14c.39.75.67,1,.66,1.31-.22,28.16,1,56.43-1,84.46-3,41-25.34,69.46-62.62,86.09C30.52,178,21.75,180.14,13,183.08L2.5,161.56v-1a16.11,16.11,0,0,0,2.8-.76c18.55-8.7,29.5-23.71,35.12-42.94,1.31-4.48,1.63-9.25,2.48-14.32H2.5Z" transform="translate(0 0)" fill='none' stroke='#00a3da' strokeWidth={4} />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------- Section Two - Second Widget -------------------------- */}
            <div className="w-full pt-4 md:pt-48 flex flex-wrap bg-[url('../public/images/landings/sadaqah-jariyah/hero.jpg')] bg-center sm:bg-center bg-cover max-[500px]:min-h-[50px] min-[501px]:min-h-[80px] md:min-h-[320px] lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[600px] px-4 md:px-0">
                <div className="w-full flex flex-wrap justify-center md:container md:mx-auto">
                    <div className="flex flex-col basis-full items-center text-center bg-[#f60362] text-white mt-12 p-4 md:p-8">
                        <h3 className="font-['Gotcha'] text-2xl md:text-4xl mb-2">
                            Olive Tree
                        </h3>
                        <h1 className="text-2xl md:text-5xl text-center font-medium mb-2 md:mb-4">
                            Planting Initiative.
                        </h1>
                        <p className='w-full md:w-2/3 text-center my-3 md:my-6 text-lg md:text-2xl'>
                            The olive farm project is a sustainable initiative designed to give employment opportunities
                            and improved livelihood to impoverished families in Palestine.
                        </p>
                    </div>
                    <div className="basis-full flex justify-center bg-white p-4 md:p-8">
                        <SadaqahWidget
                            defaultProduct={{
                                creator: "MKD-GEN-MIG-2023-01-126",
                                label: "Income Generation",
                                value: 111
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------- Section Two - Products ------------------------------- */}
            <div className="w-full px-4 py-4 md:py-8 bg-[#e2f0f3]">
                <div className="w-full flex flex-wrap justify-center md:container md:mx-auto">
                    <div className="flex w-full justify-center">
                        <h1 className="text-2xl md:text-5xl w-full md:w-9/12 text-[#253B7E] text-center font-medium mb-2 md:mb-4">
                            <strong className='text-[#f60362]'>Plant an Olive Tree:</strong>  Sadaqah Jariyah, your ongoing contribution
                        </h1>
                    </div>
                    {futureProducts
                        ? <div className="flex flex-wrap justify-center mt-4 md:mt-6">{futureProducts.map((item, index) => {
                            return <div key={`fundrising${index}`} className="basis-full min-[400px]:basis-1/2 md:basis-1/3 xl:basis-1/4 p-2 overflow-hidden">
                                <FundraiserProductBox goal={item.goal} subTitle='Orphan Education' product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                            </div>
                        })}</div>
                        : <Loader />
                    }
                    <div className="basis-full flex justify-center">
                        <p className='w-full text-[#777] font-montserratMedium md:w-9/12 text-center my-3 md:my-6 text-lg '>
                            Sadaqah Jariyah is a form of ongoing charity in Islamic tradition that continues to provide benefits to people
                            long after the initial donation has been made. This concept is deeply rooted in the belief of doing good deeds that
                            will not only benefit the giver in their lifetime but also continue to reap rewards for them in the hereafter. It
                            represents a sustainable and enduring form of charity, distinguishing itself from Sadaqah, which is a more immediate
                            and transient form of almsgiving. Sadaqah Jariyah can be achieved through various means such as providing clean water,
                            building educational facilities, or any act that has a lasting impact on the well-being of others.
                            <br />
                            <br />
                            Planting an olive tree is a poignant example of Sadaqah Jariyah due to the tree's longevity and the multitude
                            of benefits it offers. An olive tree can live for hundreds of years, providing sustenance, shade, and economic
                            opportunities to countless generations. The act of planting it embodies the essence of Sadaqah Jariyah, as it
                            continues to yield fruit, offer employment, and contribute to the environment long after the planter's lifetime. Moreover, olives and their oil hold significant nutritional, medicinal, and religious importance in many cultures, making the act of planting an olive tree a profoundly impactful form of continuous charity that echoes the teachings of sustainability and compassion in Islam.
                        </p>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------- Section Seven - FAQ -------------------------- */}
            <div className='w-full h-auto flex flex-col justify-center items-center bg-[#E2EFF3]- py-16'>
                <h2 className="text-2xl md:text-4xl font-medium text-center mt-8 mb-4 text-[#253B7E]">Sustainable donations <strong className='text-[#f60362]'>FAQs</strong></h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-2 md:px-0 mt-6">
                    {sustainableFaqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordionn key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordionn>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

        </NewHelmetLayout>
    );
}

export default OliveTreeSadaqah;
