import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { useSelector } from 'react-redux';
import CountUp from 'react-countup';
import newGenerationImg from '../../images/general/newgenerationheader.png';
import clickerImg from '../../images/general/clickhereimpact.png';
import impactHeaderImg from '../../images/general/MATW-Home-Banner-TheMATWImpact-Heading.png';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

export default function CampaignTiktok() {
  /* ----------------------------- Start variables ---------------------------- */
  const pdfFiles = [
    { title: 'Download Annual Impact Report 2022', pdfLink: 'matw-annualreport-2022-1.pdf', img: 'thumbnail-report-annual.png' },
    { title: 'Orphans - Annual Impact Report 2022', pdfLink: 'pdf-report-orphans.pdf', img: 'thumbnail-report-orphans.png' },
    {
      title: 'Water For Life - Annual Impact Report 2021',
      pdfLink: 'pdf-report-food-and-water-aid.pdf',
      img: 'thumbnail-report-food-and-water-aid.png',
    },
    {
      title: 'Download MATW Annual Achievement Report 2019',
      pdfLink: 'matw-projects-report-20193805.pdf',
      img: 'thumbnail-report-achievement-2019.png',
    },

    // Seasonal Reports

    { title: 'Ramadan 2023 - Impact Report', pdfLink: 'MATW_Ramadan_2023_Report - FINAL.pdf', img: 'thumbnail-report-ramadan-2023.png' },
    { title: 'Qurban 2023 - Impact Report', pdfLink: 'MATW_Qurban_2023_Report.pdf', img: 'thumbnail-report-qurban-2023.png' },
    { title: 'Zakat - Impact Report 2022', pdfLink: 'pdf-report-zakat.pdf', img: 'thumbnail-report-zakat.png' },
    { title: 'Download Ramadan Report 2020', pdfLink: 'matw-ramadan-report-2020.pdf', img: 'thumbnail-report-ramadan-2020.png' },
    { title: 'Ramadan 2022 - Impact Report', pdfLink: 'pdf-report-ramadan.pdf', img: 'thumbnail-report-ramadan.png' },
    { title: 'Qurban - Impact Report 2022', pdfLink: 'pdf-report-current-projects.pdf', img: 'thumbnail-report-current-projects.png' },
    {
      title: 'Download Zakat Al Maal Report 2019-2020',
      pdfLink: 'matw-zakatalmal-report-2019-2020.pdf',
      img: 'thumbnail-report-zakat-2019-2020.png',
    },

    // Latest Project Impact Reports

    { title: 'Yemen - Impact Report 2021', pdfLink: 'pdf-report-crisis-and-emergencies.pdf', img: 'thumbnail-report-crisis-and-emergencies.png' },
    {
      title: 'Palestine - Emergency Response Report 2021',
      pdfLink: 'pdf-report-ramadan-emergency.pdf',
      img: 'thumbnail-report-palestine-emergency.png',
    },
    { title: 'Morocco Earthquake - Impact Report', pdfLink: 'MATW_MoroccoEarthquake_2023_Report.pdf', img: 'thumbnail-report-morocco.png' },
  ];
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
  }, []);
  return (
    <NewHelmetLayout hideFooter highlights={true} navigation="tiktok">
      {seo && (
        <Helmet>
          <title>{`${seo['resourcesAndInformation']['campaignTiktok']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
          {/* <meta name="description" content={`${seo['resourcesAndInformation']['campaignTiktok']['description']}`} /> */}
        </Helmet>
      )}

      <section className="bg-white">
        <div className="px-4 sm:container md:container relative mx-auto bg-white shadow-md rounded-md py-8 md:py-12">
          <h1 className="font-gotcha text-center text-4xl md:text-5xl text-[#253B7E] mb-8 md:mb-12 py-6">
            <span className="text-[#f60362]">Ali Banat</span> , his life, his legacy, his impact.
          </h1>
          <div className="w-full">
            <div className="h-[300px] md:h-[400px] lg:h-[600px]">
              <iframe
                className="video border-4 border-[#f60362]"
                width={'100%'}
                height={'100%'}
                title="Gifted with Cancer - Ali Banat"
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0?feature=oembed`}
              ></iframe>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center py-8">
          <Link
            to="/ali-banat"
            className="text-white text-sm md:text-lg transition-all duration-200 flex items-center justify-center h-12 px-12 rounded-md bg-[#f60362]"
          >
            Learn More About Ali Banat
          </Link>
        </div>
      </section>
      {/* Banner */}
      <div className="flex flex-col justify-center flex-wrap col-span-12 bg-[url('../src/images/general/slider-banner-our-purpose.jpg')] bg-cover pt-20 pb-44 md:h-[453px]">
        <h1 className="col-span-12 text-center text-6xl text-[#093484] py-2 md:py-8 font-['Gotcha']">Our Purpose...</h1>
        <p className="col-span-12 text-center text-3xl text-[#00a3da]">
          To build legacies with a global impact <br />
          <span className="text-[#093484] text-2xl">#legacy #impact</span>
        </p>
      </div>
      <div className="flex flex-col justify-center flex-wrap col-span-12 bg-[url('../src/images/general/cellphonebgpng.png')] bg-no-repeat bg-center xl:bg-cover py-20 -mt-[170px] items-center md:h-[685px]">
        <img src={newGenerationImg} alt="matw" className="mt-16 md:w-[626px] md:h-[109px]" />
        <h1 className="col-span-12 text-center text-6xl text-white py-2 mb-6 md:py-8 font-bold">
          Over
          <br />
          774,000
        </h1>
        <p className="col-span-12 text-center text-3xl text-white">
          supporters on social <br />
          media
          <br />
          <br />
          we are not just another charity!
        </p>
      </div>
      <div className="flex flex-col justify-between flex-wrap col-span-12 bg-[url('../src/images/general/impactbg.jpg')] bg-cover py-6 items-center md:h-[380px] lg:h-[400px]">
        <img src={impactHeaderImg} alt="" className="mt-16- md:w-[439px] md:h-[74px]" />
        <div className="flex flex-wrap w-full md:container md:mx-auto px-3 py-10 justify-center">
          <div className="basis-1/2 md:basis-1/4 xl:basis-1/6 flex flex-col justify-center items-center">
            <CountUp start={4450} end={4513} duration={5} separator="," decimals={0} decimal="," prefix="" delay={0}>
              {({ countUpRef }) => (
                <div>
                  <span className="text-[#00a3da] text-3xl md:text-4xl lg:text-6xl" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="text-md md:text-lg text-center">Water Wells Built</span>
          </div>
          <div className="basis-1/2 md:basis-1/4 xl:basis-1/6 flex flex-col justify-center items-center">
            <CountUp start={5950} end={6000} duration={5} separator="," decimals={0} decimal="," prefix="" delay={0}>
              {({ countUpRef }) => (
                <div>
                  <span className="text-[#00a3da] text-3xl md:text-4xl lg:text-6xl" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="text-md md:text-lg text-center">Orphans Sponsored</span>
          </div>
          <div className="basis-1/2 md:basis-1/4 xl:basis-1/6 flex flex-col justify-center items-center">
            <CountUp start={0} end={34} duration={5} separator="," decimals={0} decimal="," prefix="" suffix="M+" delay={0}>
              {({ countUpRef }) => (
                <div>
                  <span className="text-[#00a3da] text-3xl md:text-4xl lg:text-6xl" ref={countUpRef} />
                </div>
              )}
            </CountUp>
            <span className="text-md md:text-lg text-center">Impact Made</span>
          </div>
        </div>
        <Link className="" to={'/annual-reports'}>
          <img src={clickerImg} alt="" className="mt-16- md:w-[606px] md:h-[110px]" />
        </Link>
      </div>
      {/* Promise */}
      <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-[length:400px_300px] bg-[#ecf5f7]`}>
        <div className="md:container md:mx-auto ">
          <div className="grid grid-cols-12 px-3 md:px-4 lg:px-6 py-8 sm:py-20">
            <div className=" col-span-12 rounded-2xl bg-white p-6 py-10 sm:py-20">
              <h1 className="font-gotcha text-[30px] sm:text-[50px] leading-[70px] text-center text-[#253B7E]">Our Promise To You</h1>
              <h1 className=" text-[30px] sm:text-[50px] leading-[60px] font-bold text-center text-[#253B7E]">MATW’s 100% Model</h1>
              <p className="sm:mt-10 text-[#78716C] text-[18px] sm:text-[24px] font-montserratMedium">
                When Ali first founded Muslims Around The World, he promised that every dollar he collected from friends and family, who were his
                first donors, would be used to fund and support the projects they were promised to.
              </p>
              <p className="mt-5 text-[#78716C] text-[18px] sm:text-[24px] font-montserratMedium">
                Till today, it is a promise that we maintain and an Amanah we swear by.
              </p>

              <div className="text-[#253B7E] text-[24px] sm:text-[40px] mt-10 font-bold">So, how do we pay our overheads?</div>
              <p className="mt-5 sm:mt-10 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium">
                From our inception, MATW’s operational costs have been covered by a small group of generous donors - who we call our angel investors.
                They ensure that things like staff salaries, office rent, and trips to the field are covered. With every year that MATW grows, our
                commitment to transparency is something that we uphold, inspiring more support from donors and volunteers all over the world.
              </p>
              <p className="mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium">
                It started with Ali Banat and it continues with YOU.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Charity */}
      <section className="w-full py-10 md:py-16 bg-white">
        <div className="md:container md:mx-auto ">
          <div className="rounded-2xl bg-white p-3 md:p-6 lg:p-6 shadow-lg">
            <h3 className="w-full text-center text-xl sm:text-2xl md:text-4xl text-[#253b7e] font-medium">
              MATW Project - Islamic Charity to support Muslim Charitable Causes with 100% Donation Policy
            </h3>
            <p className="sm:mt-10 text-[#78716C] text-[18px] sm:text-[24px] font-montserratMedium">
              MATW Project stands out as a distinguished Muslim charity committed to supporting those in need within the Islamic community through
              Sadaqah and Zakat donations. Embracing a{' '}
              <Link to="/100-donation-policy" className="text-[#00a3da] cursor-pointer">
                100% donation policy
              </Link>
              , we ensure that every penny contributed by donors directly benefits the intended causes, ranging from humanitarian aid to developmental
              projects. Our transparent and efficient approach in handling donations maximises the impact on the lives of recipients, making MATW
              Project a trustworthy platform for fulfilling one's charitable Islamic duties.
            </p>
            <p className="mt-5 text-[#78716C] text-[18px] sm:text-[24px] font-montserratMedium">
              As a Muslim charity, MATW Project plays a pivotal role in facilitating the practice of Sadaqah and Zakat, two fundamental pillars of
              Islamic giving. Our dedication to channeling the entirety of donations towards helping the less fortunate highlights our commitment to
              upholding Islamic values of compassion and generosity. By providing a seamless and secure donation process,
            </p>
          </div>
        </div>
        <div className="md:container md:mx-auto mt-6 md:mt-12 flex flex-wrap justify-center text-white">
          <h1 className="text-4xl md:text-5xl basis-full text-[#F60362] font-['Gotcha'] mt-4 text-center font-medium mb-4 lg:mb-8 mx-1">
            Ongoing reward in this life and the hereafter
          </h1>
          <div className="basis-full flex flex-wrap items-center justify-center">
            <div className="basis-1/2 order-2 md:order-1 lg:basis-1/3 h-[220px] md:h-[360px] bg-[url('../public/images/landings/love-ones/imageholder-reward.png')] bg-center bg-contain bg-no-repeat" />
            <div className="basis-1/2 order-1 md:order-2 lg:basis-1/3 px-2 pl-4 md:px-6 mt-2">
              <h3 className="text-[#093686] text-lg md:text-2xl mb-3 font-bold">Did you know?</h3>
              <p className="basis-full mb-4 md:mb-8 text-sm md:text-lg text-[#14A2DC]">
                Sadaqah Jariyah is not specifically for the deceased! It is also highly beneficial for the living to leave behind good deeds that will
                continue to be rewarded by Allah (swt) even after they have passed on.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Reports */}
      <div className="w-full">
        <div className="px-4 max-[440px]:max-w-[340px] sm:container md:container flex relative mt-12 flex-col  mx-auto bg-white shadow-md rounded-md py-6">
          <div>
            <h2 className="text-[#253B7E] text-[24px] p-2 xl:p-4 font-bold">Annual Reports</h2>
            <div className="w-full text-[#00aeef] flex flex-wrap">
              {pdfFiles.slice(0, 4).map((file, index) => {
                return (
                  <div
                    key={`pdfs${index}`}
                    className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4 p-2 xl:p-4 overflow-hidden hover:-translate-y-4 transition-all"
                  >
                    <Link to={`../pdf/${file.pdfLink}`} target="_blank">
                      <div className="overflow-hidden rounded-lg sm:rounded-xl border-[#14A2DC] border-[3px] drop-shadow-[-4px_5px_0px_rgba(220,220,220,1)] shadow-md  md:rounded-[15px] transition-all duration-300 hover:shadow-md bg-[#e3e3e3]">
                        <img
                          src={`../images/reports/${file.img}`}
                          alt={file.img}
                          className="w-full transition-all duration-300 scale-100 hover:scale-110-"
                        />
                      </div>
                      <p className="mb-5 text-center text-sm lg:text-lg mt-2">{file.title}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <h2 className="text-[#253B7E] text-[24px] p-2 xl:p-4 font-bold">Seasonal Reports</h2>
            <div className="w-full text-[#00aeef] flex flex-wrap">
              {pdfFiles.slice(4, 11).map((file, index) => {
                return (
                  <div
                    key={`pdfs${index}`}
                    className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4 p-2 xl:p-4 overflow-hidden hover:-translate-y-4 transition-all"
                  >
                    <Link to={`../pdf/${file.pdfLink}`} target="_blank">
                      <div className="overflow-hidden rounded-lg sm:rounded-xl border-[#14A2DC] border-[3px] drop-shadow-[-4px_5px_0px_rgba(220,220,220,1)] shadow-md md:rounded-[15px] transition-all duration-300 hover:shadow-md bg-[#e3e3e3]">
                        <img
                          src={`../images/reports/${file.img}`}
                          alt={file.img}
                          className="w-full transition-all duration-300 scale-100 hover:scale-110-"
                        />
                      </div>
                      <p className="mb-5 text-center text-sm lg:text-lg mt-2">{file.title}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <h2 className="text-[#253B7E] text-[24px] p-2 xl:p-4 font-bold">Latest Project Impact Reports</h2>
            <div className="w-full text-[#00aeef] flex flex-wrap">
              {pdfFiles.slice(11, 14).map((file, index) => {
                return (
                  <div
                    key={`pdfs${index}`}
                    className="basis-1/2 sm:basis-1/3 md:basis-1/4 xl:basis-1/4 p-2 xl:p-4 overflow-hidden hover:-translate-y-4 transition-all"
                  >
                    <Link to={`../pdf/${file.pdfLink}`} target="_blank">
                      <div className="overflow-hidden rounded-lg sm:rounded-xl border-[#14A2DC] border-[3px] drop-shadow-[-4px_5px_0px_rgba(220,220,220,1)] shadow-md md:rounded-[15px] transition-all duration-300 hover:shadow-md bg-[#e3e3e3]">
                        <img
                          src={`../images/reports/${file.img}`}
                          alt={file.img}
                          className="w-full transition-all duration-300 scale-100 hover:scale-110-"
                        />
                      </div>
                      <p className="mb-5 text-center text-sm lg:text-lg mt-2">{file.title}</p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </NewHelmetLayout>
  );
}
