import React from 'react';
import Layout from '../../components/general/layout';

const DoubleTheDonationPage = () => {
  return (
    <Layout>
      <iframe
        title="Double the Donation Page"
        srcDoc={`
          <html>
            <head>
              <link rel="stylesheet" type="text/css" href="https://doublethedonation.com/api/css/ddplugin.css">
            </head>
            <body>
            <script>var DDCONF = { API_KEY: "wpeofzaR6g3EuoLl" };</script>
            <script src="https://doublethedonation.com/api/js/ddplugin.js"></script>
              <div id="dd-container">
                <a href="https://doublethedonation.com/matching-grant-resources/matching-gift-basics/">Matching Gift</a>
                and <a href="https://doublethedonation.com/matching-grant-resources/volunteer-grant-basics/">Volunteer Grant</a> information provided by <br>
                <a href="https://doublethedonation.com"><img alt="Powered by Double the Donation" src="https://doublethedonation.com/api/img/powered-by.png" /></a>
              </div>
            </body>
          </html>
        `}
        style={{ border: 'none', width: '100%', minHeight: '400px' }}
      />
    </Layout>
  );
};

export default DoubleTheDonationPage;
