// import { useState } from "react";
import services from "../services";

const ConvertAUD = (currency, _price, showFractionDigits = true) => {
  // console.log("showcurrencies",currency,_price);

  let tempCurrency = currency && currency.toUpperCase();
  let price = Number(_price);
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  if (showFractionDigits) {
    if (price && Number(price) % 1 !== 0) {
      price = Number(price).toLocaleString('en', options);
    }
  }
  let ratioes = JSON.parse(localStorage.getItem('Ratio'));
  const getConvertedCurrency = async amount => {
    let tempRatioes = {
      EUR: 1,
      USD: 1,
      CAD: 1,
      GBP: 1,
      SGD: 1,
      MYR: 1,
      IDR: 1,
      AUD: 1,
      AED: 1,
    };
    try {
      const response = await services.convertCurrency(1);
      response.data.map(item => {
        tempRatioes[item.currency_name] = item.ratio;
        return item;
      })
      localStorage.setItem('Ratio', JSON.stringify(tempRatioes));
    } catch (error) {
      console.error('error========', error);
    }
  };
  if(!ratioes) {
    getConvertedCurrency(1);
  }
  switch (tempCurrency) {
    case 'AUD':
      return price ? (showFractionDigits ? price.toLocaleString() : price) : 0;
    case 'USD':
      // return price ? (showFractionDigits ? (price * 0.65).toLocaleString() : price * 0.65) : 0;
      return price ? (showFractionDigits ? (price / ratioes.USD).toLocaleString() : price / ratioes.USD) : 0;
    case 'CAD':
      // return price ? (showFractionDigits ? (price * 0.88).toLocaleString() : price * 0.88) : 0;
      return price ? (showFractionDigits ? (price / ratioes.CAD).toLocaleString() : price / ratioes.CAD) : 0;
    case 'GBP':
      // return price ? (showFractionDigits ? (price * 0.52).toLocaleString() : price * 0.52) : 0;
      return price ? (showFractionDigits ? (price / ratioes.GBP).toLocaleString() : price / ratioes.GBP) : 0;
    case 'EUR':
      // return price ? (showFractionDigits ? (price * 0.60).toLocaleString() : price * 0.60) : 0;
      return price ? (showFractionDigits ? (price / ratioes.EUR).toLocaleString() : price / ratioes.EUR) : 0;
    case 'SGD':
      // return price ? (showFractionDigits ? (price * 0.88).toLocaleString() : price * 0.88) : 0;
      return price ? (showFractionDigits ? (price / ratioes.SGD).toLocaleString() : price / ratioes.SGD) : 0;
    case 'MYR':
      // return price ? (showFractionDigits ? (price * 3.079).toLocaleString() : price * 3.079) : 0;
      return price ? (showFractionDigits ? (price / ratioes.MYR).toLocaleString() : price / ratioes.MYR) : 0;
    case 'IDR':
      // return price ? (showFractionDigits ? (price * 10, 352.24).toLocaleString() : price * 10, 352.24) : 0;
      return price ? (showFractionDigits ? (price / ratioes.IDR).toLocaleString() : price / ratioes.IDR) : 0;
    case 'AED':
      // return price ? (showFractionDigits ? (price * 2.39).toLocaleString() : price * 2.39) : 0;
      return price ? (showFractionDigits ? (price / ratioes.AED).toLocaleString() : price / ratioes.AED) : 0;
    default:
      return price ? (showFractionDigits ? price.toLocaleString() : price) : 0;
  }
};

export default ConvertAUD;
