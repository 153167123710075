import React from 'react';
import Card from '../../components/card';
import ProductsTable from './list';
import useDashboardReceiptsHook from '../../../../hooks/dashboard/useDashboardReceiptsHook';

const ReceiptsList = () => {
  const {
    columnsDataProducts,
    columnsProducts,
    allProducts,
    isLoading,
    searchItems,
    columnsTransactions,
    selectedProducts,
    setSearchItems,
    search,
    showProducts,
    pageSize,
    lastPage,
    currentPage,
    setShowProducts,
    currency,
    getProducts,
  } = useDashboardReceiptsHook();
  console.log('allProducts',allProducts);
  
  return (
    allProducts &&
    (!showProducts ? (
      <Card title="Receipt List" className={'w-full h-full sm:overflow-auto px-2 md:px-4 2xl:px-6'}>
        <ProductsTable
          columnsData={columnsDataProducts}
          tableData={allProducts}
          search={search}
          isLoading={isLoading}
          searchItems={searchItems}
          type="receipts"
          getProducts={getProducts}
          pageSize={pageSize}
          lastPage={lastPage}
          currentPage={currentPage}
          setSearchItems={e => {
            setSearchItems({ ...searchItems, ...e });
          }}
        />
      </Card>
    ) : (
      <>
        {selectedProducts.products.length > 0 && (
          <Card
            title="Products"
            className={'w-full h-full sm:overflow-auto px-2 md:px-4 2xl:px-6 mb-2'}
            btn={<ReturnButton onclick={() => setShowProducts(false)} />}
          >
            <ProductsTable
              columnsData={columnsProducts}
              tableData={selectedProducts.products}
              search={search}
              isLoading={isLoading}
              type="receiptProduct"
              searchItems={searchItems}
              currency={currency}
              getProducts={getProducts}
              setSearchItems={e => {
                setSearchItems({ ...searchItems, ...e });
              }}
            />
          </Card>
        )}
        {selectedProducts.transactions.length > 0 && (
          <Card
            title="Transactions"
            className={'w-full h-full sm:overflow-auto px-2 md:px-4 2xl:px-6'} 
          >
            <ProductsTable
              columnsData={columnsTransactions}
              tableData={selectedProducts.transactions}
              search={search}
              currency={currency}
              isLoading={isLoading}
              type="receiptTransaction"
              searchItems={searchItems}
              getProducts={getProducts}
              setSearchItems={e => {
                setSearchItems({ ...searchItems, ...e });
              }}
            />
          </Card>
        )}
      </>
    ))
  );
};

const ReturnButton = ({ onclick }) => (
  <div class="flex mt-2 md:mt-0" onClick={onclick}>
    <button class="flex justify-center items-center mr-2 text-white text-sm font-medium rounded-md h-10 px-4 bg-[#db346e] hover:bg-[#49acea] transition-all duration-200">
      Back to Home
    </button>
  </div>
);

export default ReceiptsList;
