import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { selectProducts } from '../../store/products';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { useRef } from 'react';
import SelectCurrency from '../../components/general/selectCurrency';
import ShowCurrencies from '../../utils/showCurrencies';
import { CustomButton, OurPromiseSection } from '../../components/general';
import banner from '../../images/landings/zakat-calculator/zakat-calculator-hero-banner.jpg';
import bannerMobile from '../../images/landings/zakat-calculator/zakat-calculator-hero-banner-mobile.jpg';
import NewHelmetLayout from '../../components/general/newHelmetLayout';

const Nisab = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const refAssets = useRef(null);
    const refLiabilities = useRef(null);
    const [assets, setAssets] = useState(0);
    const [liabilities, setLiabilities] = useState(0);
    const [zakat, setZakat] = useState(0);
    const [selectedNisab, setSelectedNisab] = useState('Silver');
    const [goldNisabAmount, setGoldNisabAmount] = useState(0);
    const [silverNisabAmount, setSilverNisabAmount] = useState(0);
    const products = useSelector((state) => selectProducts(state));
    const [addProduct] = useHandleAddProduct();
    const [zakatAlMalProduct, setZakatAlMalProduct] = useState();
    const goldNisabGrams = 87.48;
    const silverNisabGrams = 612.36;
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleSelectNisab = (value) => {
        console.log("radio", value)
        setSelectedNisab(value);
        handleCalculateZakat(assets, liabilities, value)
    }
    const handleCurrency = (currency) => {
        setSelectedCurrency(currency.value);
        // selectedNisab === 'Gold' ? handleGoldNisabAmount(currency.value) : handleSilverNisabAmount(currency.value)
        handleGoldNisabAmount(currency.value);
        handleSilverNisabAmount(currency.value);
    }
    const handleAssets = (value) => {
        refAssets.current.value = '';
        if (parseInt(value) >= 0) {
            setAssets(parseInt(value));
            handleCalculateZakat(parseInt(value), liabilities)
        } else {
            setAssets(0);
            setZakat(0)
        }
    }
    const handleLiabilities = (value) => {
        refLiabilities.current.value = '';
        if (parseInt(value) >= 0) {
            setLiabilities(parseInt(value));
            handleCalculateZakat(assets, parseInt(value))
        } else {
            handleCalculateZakat(assets, 0)
            setLiabilities(0);
        }
    }
    const handleCalculateZakat = (assets, liabilities, nisab = selectedNisab) => {
        let zakatableWealth = (parseInt(assets) - parseInt(liabilities));
        console.log("handle-CalculateZakat", parseInt(assets), parseInt(liabilities), silverNisabAmount)
        if (nisab === 'Gold') {
            // zakatableWealth = (parseInt(assets) - parseInt(liabilities));
            // zakatableWealth > goldNisabAmount ? setZakat(Math.round((zakatableWealth - parseInt(goldNisabAmount)) * 0.025)) : setZakat(0)
            zakatableWealth > goldNisabAmount ? setZakat(Math.round(zakatableWealth * 0.025)) : setZakat(0)
        } else {
            // zakatableWealth > silverNisabAmount ? setZakat(Math.round((zakatableWealth - parseInt(silverNisabAmount)) * 0.025)) : setZakat(0)
            zakatableWealth > silverNisabAmount ? setZakat(Math.round(zakatableWealth * 0.025)) : setZakat(0)
        }
    }
    const handleKeyDown = (value) => {
        let checkIfNum;
        if (value.key !== undefined) {
            checkIfNum = value.key === ".";
        }
        return checkIfNum && value.preventDefault();
    }
    const handleGoldNisabAmount = (currency) => {
        switch (currency) {
            case 'USD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_USD) * Number(goldNisabGrams))
                break;
            case 'AUD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_AUD) * Number(goldNisabGrams))
                break;
            case 'CAD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_CAD) * Number(goldNisabGrams))
                break;
            case 'GBP':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_GBP) * Number(goldNisabGrams))
                break;
            case 'EUR':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_EUR) * Number(goldNisabGrams))
                break;
            case 'SGD':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_SGD) * Number(goldNisabGrams))
                break;
            case 'MYR':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_MYR) * Number(goldNisabGrams))
                break;
            case 'AED':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_AED) * Number(goldNisabGrams))
                break;
            case 'IDR':
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_IDR) * Number(goldNisabGrams))
                break;
            default:
                setGoldNisabAmount(Number(process.env.REACT_APP_GOLD_NISAB_USD) * Number(goldNisabGrams))
                break;
        }
    }
    const handleSilverNisabAmount = (currency) => {
        switch (currency) {
            case 'USD':
                console.log("silver nissab usd=", Number(process.env.REACT_APP_SILVER_NISAB_USD), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams));
                handleCalculateZakat(assets, liabilities)
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams))
                break;
            case 'AUD':
                console.log("silver nissab aud=", Number(process.env.REACT_APP_SILVER_NISAB_AUD), Number(silverNisabGrams), Number(process.env.REACT_APP_SILVER_NISAB_AUD) * Number(silverNisabGrams))
                handleCalculateZakat(assets, liabilities)
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_AUD) * Number(silverNisabGrams))
                break;
            case 'CAD':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_CAD) * Number(silverNisabGrams))
                break;
            case 'GBP':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_GBP) * Number(silverNisabGrams))
                break;
            case 'EUR':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_EUR) * Number(silverNisabGrams))
                break;
            case 'SGD':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_SGD) * Number(silverNisabGrams))
                break;
            case 'MYR':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_MYR) * Number(silverNisabGrams))
                break;
            case 'AED':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_AED) * Number(silverNisabGrams))
                break;
            case 'IDR':
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_IDR) * Number(silverNisabGrams))
                break;
            default:
                setSilverNisabAmount(Number(process.env.REACT_APP_SILVER_NISAB_USD) * Number(silverNisabGrams))
                break;
        }
    }
    const getAllProducts = () => {
        setIsLoaded(true);
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'RM24-ZM-013') setZakatAlMalProduct(inner_item_product); // Zakat Al Mal
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
    };
    const handleAddNewProduct = (creator, currency, zakat) => {
        let tempProduct = zakatAlMalProduct;
        let customAmount = Number(zakat) ? Number(zakat) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
        })
        addProduct({product: tempProduct, currency: currency})
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        // window.scroll({top: 0, left: 0, behavior: 'smooth' });
        // window.scroll(0, 0);
        handleGoldNisabAmount(selectedCurrency)
        handleSilverNisabAmount(selectedCurrency)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    useEffect(() => {
        handleCalculateZakat(assets, liabilities)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goldNisabAmount, silverNisabAmount])
    useEffect(() => {
        console.log("=========================temp in zakat calculator", selectedCurrencyStore)
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
        // if(selectedCurrencyStore) {
        //     handleGoldNisabAmount(selectedCurrency);
        //     handleSilverNisabAmount(selectedCurrency);
        //     setSelectedCurrency(selectedCurrencyStore);
        // } else {
        //     handleGoldNisabAmount('AUD');
        //     handleSilverNisabAmount('AUD');
        //     setSelectedCurrency('AUD')
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCurrencyStore])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <NewHelmetLayout className='' hideFooter>
            {seo &&
            <Helmet>
                <title>{`${seo['nisab']['title']}`}</title>
                <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
                <meta name="description" content={`${seo['nisab']['description']}`} />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "HowTo",
                        "name": "How to Calculate Zakat",
                        "description": "This guide outlines the steps to calculate Zakat, ensuring you fulfill your Islamic financial obligations.",
                        "step": [
                            {
                                "@type": "HowToStep",
                                "name": "Determine Nisab",
                                "text": "Calculate the current value of Nisab in your local currency to determine if you are eligible to pay Zakat."
                            },
                            {
                                "@type": "HowToStep",
                                "name": "Calculate Assets",
                                "text": "Total your assets that are subject to Zakat, including cash, gold, silver, and business commodities."
                            },
                            {
                                "@type": "HowToStep",
                                "name": "Deduct Liabilities",
                                "text": "Subtract any debts or liabilities from your total assets to find your net assets."
                            },
                            {
                                "@type": "HowToStep",
                                "name": "Apply Zakat Rate",
                                "text": "If your net assets exceed the Nisab, apply the Zakat rate of 2.5% to calculate the amount of Zakat due."
                            }
                        ],
                        "estimatedCost": {
                            "@type": "MonetaryAmount",
                            "value": "0",
                            "description": "Free"
                        },
                        "tool": {
                            "@type": "HowToTool",
                            "name": "Zakat Calculator 2024",
                            "url": "https://matwproject.org/zakat-calculator"
                        }
                    })}
                </script>
            </Helmet>
            }

            {/* /* --------------------------------- Banner ---------------------------------  */}
            <section className="flex justify-center" >
                <img src={banner} alt="Nisab 2024: Calculating Nisab for Zakat in 2024" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="Nisab Threshold 2024, calculate nisab for gold" className='min-[501px]:hidden w-full' />
            </section>
            <div className="w-full bg-white p-4">
                <OurPromiseSection />
                <div className='w-full md:container md:mx-auto bg-white'>

                    <section className='xl:px-10 mt-8 md:mt-16'>
                        <h1 className="w-full text-center text-4xl sm:text-5xl md:text-6xl text-[#253b7e] font-medium font-['Gotcha'] mb-4 md:mb-8">
                            Nisab 2024: How much is the nisab for zakat in <span className="text-[#F60362]">2024?</span>
                        </h1>
                        <p className=" text-[#14A2DC] text-xl md:text-4xl mb-3 md:mb-6 font-medium">
                            How to calculate Zakat: Use our Zakat Calculator to see how much you owe this year.
                        </p>
                        <p className=" text-[#78716C]">
                            To use our Zakat calculator, enter all the assets that have been in your possession over the course of a lunar year into the Zakat calculator.
                            This will then give you the total amount of the Zakat you owe.
                            <br />
                            <br />
                            Breaking your assets down into different categories makes the Zakat calculation process easier.
                            The process presented on the calculator is broken up by Zakatable assets which include gold, silver, cash, savings, business assets etc.
                            and deductible liabilities money you owe, other outgoings due so you can calculate the Zakat you owe easily. The amount of Zakat that
                            appears will be what you need to pay.
                        </p>
                    </section>
                </div>
            </div>

            <div className="w-full py-20 md:py-24 mt-8- md:mt-16- px-4 md:px-0 bg-[url('../src/images/ali-banat/pattern.png')]">
                <div className="w-full grid grid-cols-6 gap-4 md:gap-8 justify-center items-center md:container md:mx-auto bg-white rounded-md p-4 md:p-8 lg:p-16 xl:p-24">
                    <h2 className="col-span-6 text-[#253B7E] font-medium text-xl md:text-3xl text-center mb-4 md:mb-6">
                        The Zakat Calculator can help you find the amount so that it does not become more or less than nisaab.
                    </h2>
                    <div className="col-span-6 flex justify-center mb-4 md:mb-8">
                        <div className="w-full md:w-[300px]">
                            <SelectCurrency onChangeCurrency={(e) => handleCurrency(e)} />
                        </div>
                    </div>
                    <div className="col-span-6 min-[400px]:col-span-3 py-2 rounded-md">
                        <div className={`w-full hover:shadow-[0px_0px_15px_rgb(240,240,240)] border rounded-md p-2 sm:p-4 flex items-end sm:items-start justify-between transition-all duration-200 cursor-pointer ${selectedNisab === 'Silver' ? 'border-[#00a3da] bg-[#00a3da]' : 'border-stone-200 bg-white'}`}
                            onClick={() => handleSelectNisab('Silver')}>
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col items-center justify-center">
                                    <p className={`mb-0 text-lg sm:text-2xl mt-3 transition-all duration-100 ${selectedNisab === 'Silver' ? 'text-white font-medium' : 'text-[#00a3da] font-normal'}`}>Silver NISAB</p>
                                    <p className={`${selectedNisab === 'Silver' ? 'text-white' : 'text-[#78716C]'} font-light transition-all duration-200 text-sm sm:text-base`}>
                                        Silver (612.36 grams) ≅ {ShowCurrencies(selectedCurrency, silverNisabAmount.toFixed(2))}
                                    </p>
                                </div>
                            </div>
                            <div className=''>
                                <svg className={`transition-all duration-200 text-[#00a3da] ${selectedNisab === 'Silver' ? 'scale-75 sm:scale-100' : 'scale-0'}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill='#fff' width='24' height='24'>
                                    <circle cx="24" cy="24" r="24" />
                                    <polyline points="13.2 25.02 19.83 31.65 35.75 15.73" strokeWidth='5' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 min-[400px]:col-span-3 py-2 rounded-md">
                        <div className={` w-full hover:shadow-[0px_0px_15px_rgb(240,240,240)] border rounded-md p-2 sm:p-4 flex items-end sm:items-start justify-between transition-all duration-200 cursor-pointer ${selectedNisab === 'Gold' ? 'border-[#00a3da] bg-[#00a3da]' : 'border-stone-200 bg-white'}`}
                            onClick={() => handleSelectNisab('Gold')}>
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col items-center justify-center">
                                    <p className={`mb-0 text-lg sm:text-2xl mt-3 transition-all duration-100 ${selectedNisab === 'Gold' ? 'text-white font-medium' : 'text-[#00a3da] font-normal'}`}>Gold NISAB</p>
                                    <p className={`${selectedNisab === 'Gold' ? 'text-white' : 'text-[#78716C]'} text-sm sm:text-base font-light transition-all duration-200`}>
                                        Gold (87.48 grams) ≅ {ShowCurrencies(selectedCurrency, goldNisabAmount.toFixed(2))}
                                    </p>
                                </div>
                            </div>
                            <div className=''>
                                <svg className={`transition-all duration-200 text-[#00a3da] ${selectedNisab === 'Gold' ? 'scale-75 sm:scale-100' : 'scale-0'}`} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill='#fff' width='24' height='24'>
                                    <circle cx="24" cy="24" r="24" />
                                    <polyline points="13.2 25.02 19.83 31.65 35.75 15.73" strokeWidth='5' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 flex flex-wrap mt-4">
                        <div className="basis-full flex flex-col mb-4 md:mb-6">
                            <label className='text-xl md:text-3xl text-[#253B7E] w-full'>Assets</label>
                            <small className='w-full text-[#78716C] text-lg md:text-xl mb-1'>Include gold, silver, cash, savings, business assets etc.</small>
                            <input
                                ref={refAssets}
                                type="number"
                                value={assets}
                                className='min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear'
                                onChange={(e) => handleAssets(e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e)}
                            />
                        </div>
                        <div className="basis-full flex flex-col">
                            <label className='text-xl md:text-3xl text-[#253B7E] w-full'>Liabilities</label>
                            <small className='w-full text-[#78716C] text-lg md:text-xl mb-1'>Include money you owe, other outgoings due.</small>
                            <div className='min-h-[40px] md:min-h-[48px] w-full rounded border border-stone-300 py-[0.32rem] px-3 flex'>
                                <input
                                    ref={refLiabilities}
                                    type="number"
                                    value={liabilities}
                                    className='text-base md:text-md ml-[10px] bg-transparent outline-none transition-all duration-200 ease-linear w-full'
                                    onChange={(e) => handleLiabilities(e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3 flex flex-wrap mt-4 h-full">
                        <div className="basis-full flex flex-col justify-between items-center rounded-md bg-stone-100 p-6">
                            <div className="flex flex-col items-center pt-4">
                                <h2 className="text-[#00a3da] text-2xl md:text-4xl">
                                    {ShowCurrencies(selectedCurrency, zakat)}
                                </h2>
                                {/* <small className='text-[#78716C] text-lg md:text-2xl'>{selectedNisab} Nisab</small> */}
                                <small className='text-[#78716C] text-lg md:text-2xl'>Zakat Owed</small>
                            </div>
                            <CustomButton disabled={!(zakat > 0)} title='Pay Now' onClicked={() => handleAddNewProduct('RM24-ZM-013', selectedCurrency, zakat)} className='mt-4 md:mt-8' />
                        </div>
                    </div>

                    <div className="col-span-6 mt-5 sm:mt-10">
                        <div>
                            <p className='text-[#78716C] text-[18px] sm:text-[24px] font-montserratMedium'>
                                To calculate Zakat on cash, one must sum up all liquid assets and apply the 2.5% rate. This includes checking and savings account balances, cash at home,
                                and any other liquid assets. It is crucial to ensure that the total amount meets or exceeds the Nisab threshold for Zakat to be obligatory.
                            </p>
                        </div>

                        <div className='mt-10'>
                            <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                What is Nisab of Zakat?
                            </h2>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                Zakat Al Mal represents a foundational pillar of Islamic faith, compelling every financially stable Muslim to contribute a portion of their
                                wealth to those in need, provided their wealth exceeds a specified threshold known as the Nisab. The concept of Nisab serves not just as a
                                financial benchmark but as a spiritual threshold that delineates the boundary between those obligated to give and those eligible to receive.
                            </p>
                        </div>

                        <div className='mt-10'>
                            <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                Nisab Threshold - What is it?
                            </h2>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                Determined by the Prophet Muhammad (Peace Be Upon Him), the Nisab employs the intrinsic value of gold and silver as a stable measure of wealth.
                                Historically, these metals have played a pivotal role in economic systems, offering a reliable standard due to their enduring value and scarcity.
                            </p>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                The initial thresholds established were 87.48 grams for gold and 612.36 grams for silver. These figures were not arbitrary but were carefully chosen to
                                ensure fairness and inclusivity, taking into account the economic conditions of the time.
                            </p>
                        </div>

                        <div className='mt-10'>
                            <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                Current Nisab Amount for 2024
                            </h2>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                In contemporary terms, the Nisab has been adjusted slightly to 85 grams for gold and 595 grams for silver, reflecting the need to adapt to the changing
                                value of these metals on the global market. As of March 12, 2024, the Nisab values in various countries are:
                            </p>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                Australia: Gold (85g) - $8,944.55 AUD | Silver (595g) - $696.15 AUD
                            </p>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                United Kingdom: Gold (85g) - £4,266.49 GBP | Silver (595g) - £336.19 GBP
                            </p>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                United States: Gold (87.48g) - $6,110.45 USD | Silver (612.36g) - $491.05 USD
                            </p>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                These values fluctuate with market conditions, underscoring the importance of referencing current prices when calculating Zakat.
                            </p>
                        </div>

                        <div className='mt-10'>
                            <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                How to Calculate Your Nisab for 2024
                            </h2>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                To determine if your wealth surpasses the Nisab threshold, and thereby ascertain your Zakat obligation, you can utilize a Zakat calculator.
                                This tool requires you to input details of your assets, including cash, gold, silver, and other valuables. By comparing the total value of your assets against the current Nisab value in either gold or silver (whichever is lower), you can establish your eligibility for Zakat.
                            </p>
                        </div>

                        <div className='mt-10'>
                            <h3 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                Deeper Understanding of Nisab
                            </h3>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                The Nisab is more than a mere numerical value; it embodies a balance between spiritual obligation and economic capability.
                                It ensures that the duty of Zakat only falls upon those who possess sufficient means, thereby protecting those of lesser means from hardship.
                            </p>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                Moreover, the use of gold and silver as standards for the Nisab threshold is significant. These metals, revered for their purity and stability,
                                symbolize the timeless and universal nature of Zakat as a pillar of Islamic faith. As markets fluctuate, the intrinsic values of gold and silver serve as a steady benchmark, ensuring the principle of Zakat remains relevant and equitable across different economic landscapes.
                            </p>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                Understanding the Nisab and accurately calculating it are crucial steps in fulfilling the Zakat obligation. By doing so, Muslims not only adhere to a key pillar of their
                                faith but also contribute to a broader social welfare system designed to alleviate poverty and promote social solidarity. As we navigate through 2024, let us remember the profound impact that Zakat, guided by the principles of Nisab, can have on our communities and beyond.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </NewHelmetLayout>
    )
}

export default Nisab;
