import React, { useEffect, useState } from 'react'
import CloseIcon from '../../images/general/close-Icon.png';
import Jummah from '../../images/general/Jummah.png';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../store/products';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { selectSelectedCurrency } from '../../store/user';
const UkModal = ({ closeModal }) => {
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => selectProducts(state));
    const [WhereMostNeededProduct, setWhereMostNeededProduct] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('AUD');
    const [addProduct] = useHandleAddProduct();
    const getAllProducts = () => {
        setIsLoaded(true);
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item.name === 'Make A Donation') {
                        if (inner_item_product.creator === 'MKD-MN-001') setWhereMostNeededProduct(inner_item_product) // Where most needed
                    };
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
    };
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    return (
        <main className="antialiased  fixed  overflow-x-hidden z-50 w-full h-full">
            <div className="relative px-4 min-h-screen flex items-center justify-center">
                <div className="bg-black opacity-25 w-full h-full absolute z-10 inset-0"></div>
                <div className=" bg-white relative overflow-hidden rounded-xl min-h-[391px]

                sm:min-w-[600px]  sm:max-w-[600px] p-4 pb-0  inset-x-0 bottom-0 z-50 mb-4 mx-4 w-full">

                    <div className='flex flex-row-reverse cursor-pointer' onClick={() => closeModal()}><img src={CloseIcon} alt='matw' className='w-[20px] sm:w-[25px]'/></div>


                    <div className="md:flex items-center">

                        <div className="sm:mt-4 md:mt-0 md:ml-6 text-center sm:text-left">
                            <div className='text-[20px] sm:text-4xl font-bold text-[#23408F]'>Wait! Before you go... </div>
                            <div className='text-[16px] sm:text-2xl font-bold text-[#23408F]'>Earn the blessings of <span className='text-[#ED145B] font-normal font-haveHeart text-base sm:text-3xl tracking-[3px]'>Jummah</span></div>
                            <div className="flex justify-center text-center sm:text-left  sm:w-[260px] text-black mt-1 text-[14px] font-thin">

                                <div className='w-[300px] text-[14px] sm:text-[15px]'>
                                    Prophet Muhammad (peace be upon him) said:
                                    <span className='text-[#ED145B] text-[14px] sm:text-[15px] font-bold'>
                                        "Charity given on the day of Friday is
                                        the most virtuous." - Bukhari
                                    </span>
                                    <div className='text-[12px] sm:text-[14px] font-thin mt-2 sm:mt-6'>
                                        Take your Sadaqah further on this blessed
                                        Friday and give to help those in need!
                                    </div>
                                    <button className='bg-[#ED145B] text-[12px] sm:text-[14px] mt-4 font-bold h-[30px] sm:h-[36px] text-white px-4 rounded-3xl'
                                    //  onClick={() => { closeModal(); navigate('checkout?id=eyJwcm9kdWN0IjoiTUtELU1OLTAwMSIsImN1cnJlbmN5IjoiVVNEIiwiaWQiOjEyMiwicXVhbnRpdHkiOjEwMCwiY2F0ZWdvcnlfaWQiOjMzfQ==')}}
                                    onClick={() => { closeModal(); addProduct({product: WhereMostNeededProduct, currency: selectedCurrency}) }}
                                    >
                                        GIVE TO WHERE MOST NEEDED
                                    </button>
                                    <div onClick={() => closeModal()} className='underline cursor-pointer underline-offset-1 mt-2 sm:mt-4 text-[12px] sm:text-[14px] text-center font-bold sm:h-[36px] text-[#000000B2] px-4 rounded-3xl'>
                                        No Thanks
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='relative -bottom-[10px] sm:absolute h-[120px] w-[120px] sm:h-[350px] sm:w-[350px] md:h-[400px] md:w-[400px]
                    sm:-right-[60px] sm:-bottom-[60px]
                    md:-right-[110px] md:-bottom-[80px] rounded-full text-center mx-auto  bg-[#ED145B]'></div>

                    <div className=' absolute bottom-0 left-0 sm:left-auto right-0 sm:left-unset  ml-auto mr-auto sm:ml-0 sm:mr-0  w-[100px] sm:w-[200px] md:right-[0px] sm:right-0'>
                        <img src={Jummah} className='w-[100px] sm:w-[220px] md:w-full ' alt='matw'/>
                    </div>
                </div>
            </div>

        </main>
    )
}

export default UkModal