import React from 'react'

const DonateSection = ({ arabic, lang = 'en' }) => {
  return (
    <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <h1 className={`text-2xl md:text-[40px] text-[#253b7e] mb-5 md:mb-8 md:text-center ${arabic || lang === 'ar' ? 'font-[AlmariMedium] font-bold' : 'font-montserratBold'}`}>
        {/* {arabic || lang === 'ar'
          ? <>تبرع <span className="text-[#f60362]">لفلسطين</span></>
          : lang === 'fr'
            ? <>Faites un don à la <span className="text-[#f60362]">Palestine</span></>
            : <>Donate to <span className="text-[#f60362]">Palestine</span></>
        } */}
        {arabic || lang === 'ar'
          ? <>أنت تعطي وهم يعيشون: <span className="text-[#f60362]">عام من الأمل في مواجهة اليأس</span></>
          : lang === 'fr'
            ? <>VOUS DONNEZ, ILS VIVENT: <span className="text-[#f60362]">Une année d'espoir face au désespoir</span></>
            : <>YOU GIVE, THEY LIVE: <span className="text-[#f60362]">A Year of Hope in the Face of Despair</span></>
        }
      </h1>
      {/* <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratBold'}`}>
        <p>
          Since the start of the conflict on October 7, more than 30,000 Palestinians have lost their lives, including more than 12,000 children.
          Over 63,000 Palestinians have been injured, and more than 1.9 million people have become internally displaced. Families have fled south,
          where conditions are barely liveable. Urgent food, water, and medical supplies are required to ensure their survival.
        </p>
        <p>
          MATW is on the ground providing life-saving assistance for displaced families in Palestine and, more recently, for Palestinian families
          seeking refuge in Egypt. We urgently need your support to help the people of Palestine survive and to help refugee families start over.
        </p>
        <p>
          You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and
          family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100%
          Zakat-applicable.
        </p>
      </div> */}
      <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : 'font-montserratBold'}`}>
        <p>
          {arabic || lang === 'ar'
            ? `لقد عانى شعب فلسطين على مدى أكثر من عام من معاناة لا يمكن تصورها ــ النزوح والدمار والخسارة. ومع ذلك، فإن النضال من أجل البقاء لم ينته بعد، حيث لا يزال الوصول إلى الغذاء والمياه والإغاثة الطبية مقطوعا.`
            : lang === 'fr'
              ? `Depuis plus d’un an, le peuple palestinien subit des souffrances inimaginables : déplacements, destructions et pertes. Pourtant, la lutte pour la survie est loin d’être terminée, car l’accès à la nourriture, à l’eau et aux soins médicaux reste coupé.`
              : `For over a year, the people of Palestine have endured unimaginable suffering—displacement, destruction, and loss.
              Yet, the struggle for survival is far from over, as access to food, water, and medical relief remains cut off.`
          }
        </p>
        <p>
          {arabic || lang === 'ar'
            ? `أنت تعطي، وهم يعيشون" هي شهادة على الدعم المنقذ للحياة الذي أصبح ممكنًا بفضل مانحينا. كل تبرع يعني أكثر من مجرد البقاء على قيد الحياة - إنه يعني الأمل والكرامة والمرونة. من تقديم الإغاثة الحرجة إلى إعادة بناء الحياة، كان كرمك شريان حياتهم. معًا، أحدثنا تأثيرًا لا يمكن إنكاره، لكن القتال لم ينته بعد. لا يزال شعب غزة بحاجة إلينا. أنت تعطي، وهم يعيشون - استمر في الوقوف مع غزة اليوم.`
            : lang === 'fr'
              ? `YOU GIVE, THEY LIVE est un témoignage du soutien vital rendu possible par nos donateurs.
              Chaque don a signifié plus que de la survie : il a apporté de l'espoir, de la dignité et de la résilience.
              Qu'il s'agisse de fournir une aide d'urgence ou de reconstruire des vies, votre générosité a été leur bouée de sauvetage.
              Ensemble, nous avons eu un impact indéniable, mais le combat n'est pas terminé.
              La population de Gaza a toujours besoin de nous. You give, they live – continuez à soutenir Gaza aujourd'hui.`
              : `YOU GIVE, THEY LIVE is a testament to the life-saving support made possible by our donors.
              Every donation has meant more than survival—it's meant hope, dignity, and resilience.
              From delivering critical relief to rebuilding lives, your generosity has been their lifeline.
              Together, we've made an undeniable impact, but the fight isn't over. The people of Gaza still need us.
              You give, they live—continue to stand with Gaza today.`
          }
        </p>
      </div>
    </div>
  )
}

export default DonateSection