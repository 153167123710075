
import { useEffect, useState } from 'react';
import services from '../../../services';
import SuggestionProductBoxCheckout from '../../general/suggestionProductBox-checkout';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
// import HandleShowAdminCost from '../../../utils/handleAdminCost';
// import useHandleAddProduct from '../../../utils/handleAddProduct';

const AddOnProducts = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { onHandleAddToCartDataLayer, onSetBasketStates, currency, basketStates } = props;
    // const [addProduct] = useHandleAddProduct();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    // const products = useSelector(state => selectProducts(state));
    const [topProducts, setTopProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [userProductsCount, setUserProductsCount] = useState(0);
    // const palestineProduct = {
    //     "id": 336,
    //     "stripe_product_id": "prod_Op6mTtd3OOhKCS",
    //     "paypal_product_id": "PROD-4R20097857099902B",
    //     "creator": "A009-PL-EMR-2023-01-001",
    //     "name": "Support Victims of Palestine",
    //     "description": "Your support is imperative to ensure the survival of Palestinians",
    //     "aud": 25,
    //     "usd": 16,
    //     "gbp": 13,
    //     "cad": 22,
    //     "eur": 15,
    //     "sgd": 22,
    //     "myr": 75,
    //     "custom_amount": "Yes",
    //     "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/crisis-and-emergencies-palestine-support-victims-of-palestine.jpeg",
    //     "recurring": "Allowed",
    //     "status": "Active",
    //     "created_at": "2023-10-15 22:30:35",
    //     "updated_at": "2023-10-15 22:32:54",
    //     "deleted_at": null,
    //     "category_id": 27,
    //     "aed": 58,
    //     "idr": 246844
    // }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start Methods ----------------------------- */
    const handleAddSuggestionProduct = (index) => {
        let tempBasket = basketStates;
        let tempNewProduct = topProducts[index];
        let tempCurrency = selectedCurrencyStore ? selectedCurrencyStore.toLowerCase() : tempBasket.currency ? tempBasket.currency.toLowerCase() : 'aud';
        // let tempAdminCost = Number(HandleShowAdminCost(tempCurrency));
        let tempAdminCost = basketStates.adminCost;
        let tempProduct = {
            product: {
                AUD: tempNewProduct.aud,
                CAD: tempNewProduct.cad,
                EUR: tempNewProduct.eur,
                GBP: tempNewProduct.gbp,
                MYR: tempNewProduct.myr,
                SGD: tempNewProduct.sgd,
                USD: tempNewProduct.usd,
                AED: tempNewProduct.aed,
                IDR: tempNewProduct.idr,
                category: '',
                category_id: tempNewProduct.category_id,
                creator: tempNewProduct.creator,
                currency: tempBasket.currency,
                custom_amount: tempNewProduct.custom_amount,
                description: tempNewProduct.description,
                image_link: tempNewProduct.image_link,
                name: tempNewProduct.name,
                paypal_plans: tempNewProduct.paypal_product_id,
                paypal_subscription_plans: tempNewProduct.paypal_subscription_plans,
                recurring: tempNewProduct.recurring,
                status: tempNewProduct.status,
                unit_amount: tempNewProduct[tempBasket.currency.toLowerCase()],
            },
            amount: tempNewProduct[tempCurrency],
            quantity: tempNewProduct[tempCurrency] === 1 ? 200 : 1,
            // currency: tempBasket.currency,
            currency: tempCurrency,
            recurring: tempBasket.recurring,
            total: tempNewProduct[tempCurrency] === 1 ? 200 : tempNewProduct[tempCurrency],
            landing_page: window.location.pathname === '/' ? '/home' : window.location.pathname,
            onBehalf: "For the sake of Allah SWT",
        };
        if (!tempBasket.products[0].product) {
            tempBasket.products[0] = tempProduct;
            tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(tempBasket.products[0].total) + tempAdminCost;
            tempBasket.total = Number(tempBasket.products[0].total);
            tempBasket.currency = tempCurrency.toUpperCase();
            tempBasket.adminCost = tempAdminCost;
            tempBasket.paymentFlow = '';
            // tempBasket.donor = {
            //     name: '',
            //     email: '',
            //     phone: '',
            //     address: '',
            //     city: '',
            //     postal_code: '',
            //     countryCode: '',
            //     country: ''
            // }
        } else {
            let tempTotal = 0;
            tempBasket.products.push({ ...tempBasket.products[tempBasket.products.length - 1] });
            tempBasket.products[tempBasket.products.length - 1] = tempProduct
            tempBasket.grandTotal = Number(tempBasket.grandTotal) + Number(tempBasket.products[tempBasket.products.length - 1].total) + tempAdminCost;
            tempBasket.products.map(item => {
                tempTotal += item.total;
                return null
            });
            tempBasket.total = tempTotal;
            // tempBasket.adminCost = tempAdminCost;
        }
        // let obj = {
        //     content_type: 'product',
        //     content_id: tempProduct.product.creator,
        //     currency: tempCurrency,
        //     value: tempProduct.total,
        //     price: tempProduct.amount,
        //     quantity: tempProduct.quantity,
        //     content_category: tempProduct.product.category_id || 'Donation',
        //     content_name: tempProduct.product.name,
        //     description: tempProduct.product.description || '',
        // }
        // window.ttq && window.ttq.track('AddToCart', {
        //     ...obj
        // });
        // window.ttq && window.ttq.track('ClickButton', {
        //     ...obj
        // });
        console.log("before add", basketStates, tempBasket, tempCurrency)
        onSetBasketStates(tempBasket); // Add new record to userProducts
        onHandleAddToCartDataLayer(tempNewProduct);
    }
    const getAddOnProducts = async (name) => {
        try {
            // const response = await services.categoryProductsByName(name);
            const response = await services.getAddOnProducts();
            function uniqBy(a) {
                var seen = {};
                return a.filter(function (item) {
                    var k = item.creator;
                    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
                })
            }
            response.data.filter(item => Number(item.status) === 1)
            let tempNewProducts = uniqBy(response.data)
            // console.log("addons t=",tempNewProducts)
            // let temp = [palestineProduct];
            // response.data.products.map(item => {
            //     if (item.name !== "Hidden" && Number(item.status) === 1) {
            //         temp.push(item)
            //     }
            //     return item;
            // })
            sortAddOnProducts(tempNewProducts, currency)
        } catch (error) {
            console.error('error========', error);
        }
    };
    const sortAddOnProducts = (products, currency) => {
        let testCompare = products.sort((a, b) => b[currency.toLowerCase()] - a[currency.toLowerCase()])
        console.log("sorted", selectedCurrencyStore,currency,testCompare);
        setUserProductsCount(userProductsCount + 1);
        setTopProducts(testCompare);
    }
    // const getAllProducts = async () => {
    //     setIsLoaded(true);
    //     let _products = [];
    //     let tempPrds = [];
    //     let tempCurrency = selectedCurrencyStore ? selectedCurrencyStore.toLowerCase() : basketStates.currency ? basketStates.currency.toLowerCase() : 'aud';
    //     if(!products || !Object.keys(products).length) {
    //         const response = await services.allProducts();
    //         _products = response.data;
    //     } else {
    //         _products = products;
    //     }
    //     _products.map(item => {
    //         item.sub_categories.map(inner_item => {
    //             inner_item.products.map(inner_item_product => {
    //                 if (Number(inner_item_product.is_addon) === 1) tempPrds.push(inner_item_product);
    //                 return inner_item_product;
    //             });
    //             return inner_item;
    //         });
    //         return item;
    //     });
    //     sortAddOnProducts(tempPrds, tempCurrency)
    // };
    /* -------------------------------------------------------------------------- */

    /* -------------------------------- Useeffect ------------------------------- */
    useEffect(() => {
        console.log("currency=",currency,selectedCurrencyStore,topProducts[0][currency.toLowerCase()])
        topProducts[0][currency.toLowerCase()] && sortAddOnProducts(topProducts, currency);
        currency && getAddOnProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency])
    useEffect(() => {
        // getAddOnProducts("hidden");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    return (
        <div className="rounded-md border border-blue-200 p-3 flex flex-wrap justify-center mb-4">
            <div className="basis-full text-lg- md:text-xl text-stone-600 py-4 px-2">
                Want to make an even bigger impact?
            </div>
            {topProducts ? (
                topProducts.map((item, index) => {
                    return (
                        <div key={`makedonation_${index}`} className={`basis-full md:basis-1/3 lg:basis-1/4 xl:basis-[14.25%] md:px-2 mx-2- md:mx-0 py-1 ${index !== topProducts.length - 1 ? 'border-b- border-stone-300' : ''}`}>
                            <SuggestionProductBoxCheckout product={item} currency={basketStates.currency ? basketStates.currency : currency ? currency : 'AUD'}
                            handleAdded={() => handleAddSuggestionProduct(index)}
                            // handleAdded={() => {
                            //     addProduct({ product: item, currency: currency })
                            //     onSetBasketStates(basketStates); // Add new record to userProducts
                            //     onHandleAddToCartDataLayer(item);
                            // }}
                            />
                        </div>
                    );
                })
            ) : (
                <div role="status" className="col-span-12 flex justify-center items-center min-h-[500px]">
                    <span className="sr-only">Loading...</span>
                </div>
            )}
        </div>
    );
}

export default AddOnProducts;