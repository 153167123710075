import {  useState, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ShowCurrencies from '../../utils/showCurrencies';
import Skeleton from 'react-loading-skeleton';
import useHandleAddProduct from '../../utils/handleAddProduct';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CustomButton from './button';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import ConvertAUD from '../../utils/convertAUD';

const SelectOneProductsBox = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const ref = useRef(null);
    const [addProduct] = useHandleAddProduct();
    const selectedCurrency = useSelector(selectSelectedCurrency);
    const [activeProductIdx, setActiveProductIdx] = useState(0);
    const { title, description, image, products, hasCustomPrice, arabic, buttonLable, alt, linkBtnClasses, } = props;
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const handleConvert = (currency, value) => {
        return Math.round(ConvertAUD(currency, value, false))
    }
    const handleCurrency = (product) => {
        if (product.quantity) {
            return ShowCurrencies(selectedCurrency, product[selectedCurrency.toLowerCase()] * product.quantity, false);
        } else {
            return ShowCurrencies(selectedCurrency, product[selectedCurrency.toLowerCase()], false);
        }
    }
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    /* -------------------------------------------------------------------------- */

    return (
        <div className="w-full p-2 md:p-3 md:pb-5 rounded-xl shadow-lg bg-white">
            <div className="overflow-hidden img-hover-zoom rounded-md w-full h-[260px] tooltip [&>span]:!block">
                {image ? image
                    ? <LazyLoadImage
                        alt={alt ? alt : title}
                        effect="blur"
                        width={`100%`}
                        height={`100%`}
                        delayTime={500}
                        className='rounded cursor-pointer block h-full object-cover object-top'
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/general/logo-replace.png";
                        }}
                        src={image} // use normal <img> attributes as props
                    />
                    : <img className='w-full' src="/images/general/logo-replace.png" alt="" />
                    : <Skeleton height={260} />
                }
            </div>
            <p className="text-[#00a3da] cursor-pointer flex justify-center items-center text-lg xl:text-xl text-center font-bold h-[42px] sm:h-[40px] lg:h-[56px] my-2 leading-4 md:leading-none uppercase">
                {title}
            </p>
            <div className="relative text-start">
                <div className={`transition-all duration-300  my-2 relative overflow-hidden max-h-[65px] md:max-h-[78px] ${description.split(' ').length > 28 ? 'mb-0' : 'mb-0'}`}>
                    <p ref={ref} className={`text-stone-500 text-sm md:text-base lg:!leading-6 overflow-hidden overflow-y-scroll- transition-all duration-200 min-h-[65px] md:min-h-[79px] `}>
                        {description}
                    </p>
                </div>
            </div>
            <div className="">
                <div className='flex flex-col gap-y-1.5 my-6'>
                    {
                        products?.map((product, index) => (
                            product ?
                            <div
                                key={`product_${index}`}
                                onClick={() => setActiveProductIdx(index)}
                                className={`w-full flex justify-between p-2 rounded transition cursor-pointer ${activeProductIdx === index ? 'text-white bg-[#F60362] ': 'text-[#253B7E] bg-white' }`}
                                style={{
                                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25)"
                                }}
                            >
                                <p>{product.alternativeName || product.name}</p>
                                <p className='font-brandingBold'>{hasCustomPrice ? handleConvert(selectedCurrency, product.quantity) : handleCurrency(product)} <span>{selectedCurrency}</span></p>
                            </div>
                            : <Skeleton key={`product_skeleton_${index}`}  height={40} />
                        ))
                    }
                </div>

                <div className='flex justify-center col-span-6'>
                    <CustomButton
                        onClick={() => {
                            const product = products[activeProductIdx];
                            if(hasCustomPrice) {
                                product.quantity = handleConvert(selectedCurrency, product.quantity);
                            }
                            addProduct({product: product, currency: selectedCurrency})
                        }}
                        title={buttonLable ? buttonLable : arabic ? 'تبرع الان' : 'Donate Now'}
                        className={`${linkBtnClasses ? linkBtnClasses : ''} ${arabic ? 'font-[AlmariMedium]' : ''}`} />
                </div>
            </div>
        </div>
    );
}

export default SelectOneProductsBox;