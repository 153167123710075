import { useEffect, useState } from 'react';
import ProductBox from '../../components/general/productBox';
import { useSelector } from 'react-redux';
import { selectLanguage, selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet-new';
import SeoData from '../../utils/seo.json';
import { palestineFaqs } from './components/faqs';
import Accordion from '../../components/general/accordion';
import { useKeenSlider } from 'keen-slider/react'
import CrisisWidget from './components/crisisWidget';
// import logos from '../../images/landings/palestine/logos(1).png';

import FoodPackImg from '../../images/landings/winter/foodPack.png';
import MedicalKitImg from '../../images/landings/winter/medical-kits.png';
import FuelImg from '../../images/landings/winter/fuel.png';
import MattressesImg from '../../images/landings/winter/mattresses.png';
import MedicalReliefImg from '../../images/landings/winter/medical-relief.png';
import HotMealsImg from '../../images/landings/winter/hot-meals.png';
import BlanketsImg from '../../images/landings/winter/blankets.png';
import MedicalBedsImg from '../../images/landings/winter/medical-beds.png';
// import OurPromises from '../../images/landings/palestine/our-promise.png';
// import Policy from '../../images/landings/palestine/policy100.png';
import VideosSection from '../../components/general/videosSection';
// import OurPromisesMobile from '../../images/landings/palestine/OurPromisesMobile.png';
import { getProducts } from '../../store/products';
import { OurPromiseSection } from '../../components/general';
import MuslimsAroundSection from './components/common-sections/muslimsAround';
import NewHelmetLayout from '../../components/general/newHelmetLayout';


export const impactInfo = {
    blankets: '38,500',
    foodPacks: '10,577,400',
    fuel: '33,500 L',
    hotMeals: '557,700',
    mattresses: '3,450',
    medicalKits: '12,500',
    medicalAid: '28,183',
    total: '11,389,333',
    lastUpdate: '24th January 2024',
    hygieneKits: '138,100'
}
const impactNumbers = [
    {
        total: '11,389,333',
        lastUpdate: '24th January 2024'
    },
    {
        title: 'Medical Kits',
        amount: '12,500',
        image: MedicalKitImg
    },
    {
        title: 'Fuel',
        amount: '33,500 L',
        image: FuelImg
    },
    {
        title: 'Mattresses',
        amount: '3,450',
        image: MattressesImg
    },
    {
        title: 'Food Packs',
        amount: '10,577,400',
        image: FoodPackImg
    },
    {
        title: 'Medical Relief',
        amount: '28,183',
        image: MedicalReliefImg
    },
    {
        title: 'Hot Meals',
        amount: '557,700',
        image: HotMealsImg
    },
    {
        title: 'Blankets',
        amount: '38,500',
        image: BlanketsImg
    },
    {
        title: 'Medical Beds & Loading Stretchers',
        amount: '121,600',
        image: MedicalBedsImg
    }

];
const images = [
    'img1',
    'img2',
    'img3',
    'img4',
    'img5'
]
export const videos = [
    {
        link: 'https://www.youtube.com/embed/0dhqNmL1uS4',
        title: '',

    },
    {
        link: 'https://www.youtube.com/embed/j0v11vDhzV8',
        title: '',

    },
    {
        link: 'https://www.youtube.com/embed/FRp5i7MhV9w',
        title: '',

    },
];

const DonateToPalestine = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [palestineProducts, setPalestineProducts] = useState([0, 0, 0, 0, 0, 0, 0]);
    const products = useSelector((state) => getProducts(state, 'Crisis and Emergencies', 'Palestine'));
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
    const seo = SeoData[selectedcountryCode];
    const [currentSlideImpact, setCurrentSlideImpact] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const language = useSelector(selectLanguage);
    const [sliderRefImpact, instanceRefImpact] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImpact(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    /* -------------------------------------------------------------------------- */


    /* ------------------------------ Start methods ----------------------------- */

    const getAllProducts = () => {
        setIsLoaded(true);
        let tempCritical = [];
        let p = products?.subCategory?.products;
        p?.forEach(item => {
            if (item.creator === 'EMR-DIS-BFPL-2023-01-200') {
                item = Object.assign({}, item, {
                    alt: "Donate to Palestine: Sponsor A Family In Palestine For A Week",
                })
                tempCritical[0] = item; // Sponsor A Family In Palestine For A Week
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-201') {
                item = Object.assign({}, item, {
                    alt: "Donate to Palestine: Sponsor A Patient",
                })
                tempCritical[1] = item; // Sponsor A Patient
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-202') {
                item = Object.assign({}, item, {
                    alt: "Donate to Palestine: Provide Clean Water In Palestine",
                })
                tempCritical[2] = item; // Provide Clean Water In Palestine
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-203') {
                item = Object.assign({}, item, {
                    alt: "Donate to Palestine: Provide Shelter For A Family In Palestine",
                })
                tempCritical[3] = item; // Provide Shelter For A Family In Palestine
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-204') {
                item = Object.assign({}, item, {
                    alt: "Palestine Charity: Emergency Orphan Support",
                })
                tempCritical[4] = item; // Emergency Orphan Support
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-205') {
                item = Object.assign({}, item, {
                    alt: "Palestine Charity: Emergency Education Support",
                })
                tempCritical[5] = item; // Emergency Education Support
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-206') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[6] = item; // Widow Support And Shelter
            }
            if (item.creator === 'EMR-DIS-PSE-2023-01') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[7] = item; // Palestine Appeal
            }
            if (item.creator === 'EMR-DIS-MDPL-2023-01-102') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[8] = item; // Palestine Emergency Medical Kit
            }
            if (item.creator === 'EMR-DIS-BFPL-2023-01-102') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[9] = item; // Palestine Essential Family Food Pack
            }
            if (item.creator === 'EMR-DIS-PSE-2023-01-01') {
                // item = Object.assign({}, item, {
                //     alt:"",
                // })
                tempCritical[10] = item; // Palestine Medical Relief
            }

        });
        tempCritical = tempCritical.filter(n => n)
        setPalestineProducts(tempCritical);
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        // getProductsByName('palestine');
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <NewHelmetLayout className='!bg-white' hideFooter={true}>
            {seo &&
                <Helmet>
                    <title>{`${seo['crisisAndEmergencies']['donateToPalestine']['title']}`}</title>
                    <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`}/>
                    <meta name="description" content={`${seo['crisisAndEmergencies']['palestine']['description']}`} />
                    {/* <meta name="description" content={`Donate to Palestine appeal and provide emergency support. Make a difference with your donations through MATW Charity.`} /> */}
                </Helmet>
            }
            {/* <div className='hidden sm:flex justify-center md:mx-auto'><img src={Banner} alt="palestine-banner" /></div> */}
            <section className="h-[400px] bg-center bg-cover bg-no-repeat bg-[url('../src/images/landings/palestine/mobile-palestine-banner.jpg')] sm:bg-[url('../src/images/landings/palestine/palestine-hero-banner.jpg')]" />

            {/* /* ------------------------------- Widget ----------------------------------- */}
            <section className='bg-[#f60362]'>
                <section className='max-w-[1440px] md:mx-auto sm:px-0 px-4 '>
                    <div className='text-[#fff]   pt-5 leading-[45px] text-center text-[35px] font-bold'>Make a Donation</div>
                    <div className='w-full   lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        <CrisisWidget className={' px-6 h-auto pt-10 bg-[#DFEEF2]'}
                            lang={language}
                            defaultProduct={{
                                creator: "EMR-DIS-PSE-2023-01",
                                label: "Palestine Appeal",
                                value: 255
                            }}
                        />
                    </div>
                </section>
            </section>
            <OurPromiseSection lang={language} />
            {/* /* -------------------------------------------------------------------------- */}

            <div className="md:container md:mx-auto flex flex-wrap mt-5" dir={language === 'ar' ? 'rtl' : 'ltr'}>
                <div className={`basis-full text-center text-6xl text-[#253b7e] flex justify-center items-center h-[125px] ${language === 'ar' ? '' : 'font-["Gotcha"]'}`}>
                    Palestine Emergency
                </div>
                <p className='w-full text-center mb-4 md:mb-8 sm:px-4 px-1 text-lg md:text-xl text-[#253B7E]'>
                    <span className='text-[#f60362]'>Donate</span> today and <span className='text-[#f60362]'>join us</span> in making a difference to those severely affected in the current conflict.
                    <br /> <br />
                    Our MATW team is on the ground working day and night to send life-saving supplies to orphaned
                    children and families in need.
                    <br />


                </p>

                <h1 className="basis-full text-center text-2xl font-bold sm:text-5xl text-[#253b7e] flex justify-center items-center py-4">
                    Donate to Palestine: Make your donations to palestine</h1>
                <p className='w-full text-center mb-4 md:mb-8 sm:px-4 px-1 text-lg md:text-xl text-[#253B7E]'>
                    We are providing essential food, water, shelter, hygiene kits and all urgent support. Our recent
                    partnership with UNWRA and the Egyptian Youth Council ensures your donations are reaching
                    those most in need in Palestine.
                </p>
                <div className="basis-full">
                    <div className="flex flex-row justify-center flex-wrap">
                        {palestineProducts.map((item, index) => {
                            return <div key={`products.products${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                                <ProductBox product={item} alt={item.alt} currency={selectedCurrency} />
                            </div>
                        })
                        }
                    </div>

                    {/* /* --------------------------------- The people of Palestine need your help --------------------------------- */}
                    <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16">
                        <h1 className="text-3xl md:text-5xl w-full text-[#253B7E] text-center font-medium mb-5 ">
                            The people of Palestine need your help
                        </h1>
                        <h2 className="text-xl md:text-4xl md:w-2/3 px-6 text-[#253B7E] text-center font-medium ">
                            It’s been more than three months of ongoing conflict. The lives of children and their
                            families continue to be uprooted, leaving many orphaned.
                        </h2>


                        <p className="text-[#253B7E] text-[18px] md:text-[20px]  leading-5 mt-3 md:mt-12">

                            The current situation in Palestine is catastrophic. More than 22,000 have lost their
                            lives and more than 57,000 have been injured. Many
                            children are being left orphaned, with no one to care for them and protect them.
                            <br />
                            <br />
                            More than 1.9 million people have become internally displaced, around half of them
                            are children. Families have fled South, leaving everything behind, seeking refuge
                            in shelters that can no longer accommodate the numbers. Now, during winter, everyone
                            is exposed to harsher conditions that are especially dangerous for children. Not
                            only are families trying to protect themselves from violence, they now have to
                            worry about how to protect themselves from the biting winter cold.
                            <br />
                            <br />
                            Our MATW team has been responding to the crisis. We are providing life-saving assistance
                            to those suffering from the effects of the worst outbreak of violence in the region in
                            years. Use your Zakat and Sadaqah to help us provide urgent medical support, food, clean
                            water and shelter.
                            <br />
                            <br />

                            <b> Your support is needed now more than ever before.</b>

                        </p>
                        <div className='basis-full flex justify-center py-8'>
                            <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#c3004c] h-[48px] px-4 sm:px-6 rounded-md' onClick={() => window.scroll(0, 0)}>
                                DONATE NOW
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* /* -------------------------------------------------------------------------- */}
            <section className='bg-[#f1f1f1] sm:pt-5'>
                {/* ----------------- videos section ---------------- */}
                <VideosSection youtube videos={videos} />
                {/* ----------------- videos section --END-------------- */}
                {/* /* --------------------------------- Images --------------------------------- */}
                <div className="md:container md:mx-auto flex justify-center flex-wrap  sm:mt-12">
                    <div className="basis-full hidden sm:flex flex-wrap">
                        <div className="basis-full md:basis-1/3 md:pr-2 mb-4">
                            <div className="bg-[url('../src/images/landings/palestine/img1.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[465px] 2xl:h-[500px]" />
                        </div>
                        <div className="basis-full md:basis-1/3 md:px-2 mb-4">
                            <div className="bg-[url('../src/images/landings/palestine/img2.jpg')] bg-center bg-cover sm:h-[300px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[170px] mb-2" />
                            <div className="bg-[url('../src/images/landings/palestine/img3.jpg')] bg-top bg-cover sm:h-[300px] md:h-[114px] lg:h-[124px] xl:h-[150px] 2xl:h-[150px] mb-2" />
                            <div className="bg-[url('../src/images/landings/palestine/img4.jpg')] bg-center bg-cover sm:h-[300px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[170px]" />
                        </div>
                        <div className="basis-full md:basis-1/3 md:pl-2 mb-4">
                            <div className="bg-[url('../src/images/landings/palestine/img5.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[465px] 2xl:h-[500px]" />
                        </div>

                    </div>
                    <div className="w-full sm:hidden flex-wrap justify-center items-center  relative">
                        <div ref={sliderRefImages} className="keen-slider">
                            {images.map((item, index) => {
                                return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full px-9 flex justify-center`}>
                                    <div className={`bg-[url('../src/images/landings/palestine/img${index + 1}.jpg')] bg-center bg-cover h-[300px] w-full`} />
                                </div>
                            })}
                        </div>
                        {loaded && instanceRefImages.current && (
                            <>
                                <Arrow
                                    left
                                    bg={'bg-[#253A7E]'}
                                    onClick={(e) =>
                                        e.stopPropagation() || instanceRefImages.current?.prev()
                                    }
                                    disabled={currentSlideImages === 0}
                                />

                                <Arrow
                                    bg={'bg-[#253A7E]'}
                                    onClick={(e) =>
                                        e.stopPropagation() || instanceRefImages.current?.next()
                                    }
                                    disabled={
                                        currentSlideImages ===
                                        instanceRefImages.current.track.details.slides.length - 1
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div className="w-full sm:hidden flex justify-center">
                        {loaded && instanceRefImages.current && (
                            <div className="dots flex pt-3 pb-6">
                                {[
                                    ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                ].map((idx) => {
                                    return (
                                        <button
                                            key={idx}
                                            onClick={() => {
                                                instanceRefImages.current?.moveToIdx(idx)
                                            }}
                                            className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                        ></button>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
                {/* /* -------------------------------------------------------------------------- */}



                {/* /* --------------------------------- Muslim Arounds ------------------------- */}
                <MuslimsAroundSection lang={language} />
            </section>
            {/* /* --------------------------Impact Numbers------------------------------------------------ */}

            <div className="md:container md:mx-auto  mt-12 px-4 md:px-0">
                <h2 className="basis-full mb-3 md:basis-1/2 text-[#253A7E] text-center font-bold text-3xl md:text-4xl lg:text-6xl">
                    MATW's <span className='text-[#F60362]'>IMPACT</span>
                </h2>
                <div className=' flex flex-col  items-center text-center text-white'>
                    <div className='bg-[#F60362] w-[348px] text-[26px] sm:w-[675px] my-6  h-[80] rounded-full sm:text-[50px]'>
                        TOTAL IMPACT: <span className='font-bold'>{impactNumbers[0].total}</span>
                    </div>
                </div>

                <div className='hidden sm:flex gap-4 mt-6 mb-16 justify-center flex-wrap'>
                    {impactNumbers.map((item, index) => (

                        index > 0 && <div key={index} className='relative flex justify-center  p-3'>
                            <div className='bg-[#F60362] h-[67px] flex justify-center items-center text-[50px] font-bold text-white rounded-[20px] text-center bottom-1/4 w-full absolute'>
                                {item.amount}
                            </div>
                            <div className='flex flex-col justify-around items-center min-h-[365px] w-[280px] rounded-3xl border-[3px] border-[#F60362]'>
                                <div><img src={item.image} alt={item.title} className='w-[130px]' /></div>
                                <div className='text-[#253B7E] text-[24px] font-bold mt-24 px-3 leading-5 text-center'> {item.title}</div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* -------------Impact numbers slider for mobile----------- */}


                <div className="w-full sm:hidden flex-wrap justify-center items-center  relative">
                    <div ref={sliderRefImpact} className="keen-slider">
                        {impactNumbers.map((item, index) => {
                            return index > 0 && <div key={`carousel_impact${index}`} className={`keen-slider__slide basis-full px-4 py-2 flex justify-center`}>
                                <div className='relative flex justify-center  p-3'>
                                    <div className='bg-[#F60362] h-[67px] flex justify-center items-center text-[50px] font-bold text-white rounded-[20px] text-center bottom-1/4 w-full absolute'>
                                        {item.amount}
                                    </div>
                                    <div className='flex flex-col justify-around items-center h-[365px] min-w-[280px] rounded-3xl border-[3px] border-[#F60362]'>
                                        <div><img src={item.image} alt={item.title} className='w-[130px]' /></div>
                                        <div className='text-[#253B7E] text-[24px] font-bold mt-20 leading-5 text-center'> {item.title}</div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    {loaded && instanceRefImpact.current && (
                        <>
                            <Arrow
                                left
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImpact.current?.prev()
                                }
                                disabled={currentSlideImpact === 0}
                            />

                            <Arrow
                                onClick={(e) =>
                                    e.stopPropagation() || instanceRefImpact.current?.next()
                                }
                                disabled={
                                    currentSlideImpact ===
                                    instanceRefImpact.current.track.details.slides.length - 1
                                }
                            />
                        </>
                    )}
                </div>
                <div className="w-full sm:hidden flex justify-center">
                    {loaded && instanceRefImpact.current && (
                        <div className="dots flex pt-3 pb-6">
                            {[
                                ...Array(instanceRefImpact.current.track.details.slides.length).keys(),
                            ].map((idx) => {
                                return (
                                    <button
                                        key={idx}
                                        onClick={() => {
                                            instanceRefImpact.current?.moveToIdx(idx)
                                        }}
                                        className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImpact === idx ? " active bg-[#F60362]" : " bg-gray-300")}
                                    ></button>
                                )
                            })}
                        </div>
                    )}
                </div>

            </div>





            {/* /* --------------------------- Section Seven - FAQ -------------------------- */}
            <section className='max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10' dir={language === 'ar' ? 'rtl' : 'ltr'}>
                <h1 className='text-[#253B7E] text-left  sm:text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                    Palestine donations <span className='text-[#F60362]'> FAQ’s</span>
                </h1>
                <div className='grid grid-cols-3 gap-x-16 gap-y-8'>
                    <div className='col-span-3 sm:col-span-1'>
                        <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{palestineFaqs[0].question}</h1>
                        <p className='text-base leading-4 mt-3'>
                            {palestineFaqs[0].answer}
                        </p>
                    </div>
                    <div className='col-span-3 sm:col-span-1'>
                        <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{palestineFaqs[1].question}</h1>
                        <p className='text-base leading-4 mt-3'>
                            {palestineFaqs[1].answer}
                        </p>
                    </div>
                    <div className='col-span-3 sm:col-span-1'>
                        <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{palestineFaqs[2].question}</h1>
                        <p className='text-base leading-4 mt-3'>
                            {palestineFaqs[2].answer}
                        </p>
                    </div>
                </div>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                    {palestineFaqs.map((item, index) => {
                        return index > 2 && (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordion>
                            </div>
                        );
                    })}
                </div>
            </section>
            {/* /* -------------------------------------------------------------------------- */}

        </NewHelmetLayout>
    );
}
function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <div
            className={`${props.bg ? props.bg : 'bg-[#F60362]'} w-[30px] h-[60px] absolute top-[calc(50%-60px)] translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.left ? "arrow--left left-[0px]" : "arrow--right left-auto right-[0px]"}`}
            onClick={props.onClick}
        >
            <svg
                className={`w-[24px] h-[24px] fill-white cursor-pointer arrow ${props.left ? "arrow--left" : "arrow--right"
                    } ${disabeld}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                {props.left && (
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                )}
                {!props.left && (
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                )}
            </svg>
        </div>
    )
}
export default DonateToPalestine;