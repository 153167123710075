const ramadanFaqs = [
    {
        id: 0,
        question: 'What is Ramadan and why is it important?',
        answer:'Ramadan is the ninth month of the Islamic lunar calendar, observed by Muslims worldwide. It is a sacred month of fasting, prayer, reflection, and charity. Muslims fast from sunrise to sunset during this month to strengthen their spirituality and empathy for those less fortunate.'
    },
    {
        id: 1,
        question: 'What is the significance of giving during Ramadan?',
        answer:'Giving during Ramadan holds special significance in Islam. it is not only a means of fulfilling the pillar of charity (Zakat) but is also a way to multiply one’s rewards. Muslims believe that their good deeds are amplified during this sacred month, making it a time for acts of charity and kindness.'
    },
    {
        id: 2,
        question: 'How can I get involved with MATW’s Ramadan projects?',
        answer:'You can get involved by donating to our Ramadan campaigns, volunteering your time, or spreading awareness about our initiatives. Your support makes a significant difference in the lives of those in need during Ramadan.'
    },
    {
        id: 3,
        question: 'In which countries does MATW operate during Ramadan?',
        answer:'We operate in various countries across the Middle East, Africa, South Asia, and Southeast Asia. We aim to reach vulnerable communities in these regions to provide essential support during Ramadan.'
    },
    {
        id: 4,
        question: 'What types of projects are run during Ramadan?',
        answer:'We offer a range of Ramadan projects, including Iftar meal distribution, food packs, daily meals for those in need, and Eid gift distribution and celebrations. These initiatives are designed to provide different aspects of support during this holy month.'
    },
    {
        id: 5,
        question: 'How do you ensure transparency and accountability in your charity projects?',
        answer:'We are committed to transparency and accountability at MATW. We provide regular updates on the progress of our projects through our website, and social media channels to keep you up-to-date on how your donation is being used and making a difference in the lives of impoverished and underserved communities. We also work with local partners and organisations to ensure accountability at the grassroots level.'
    },
    {
        id: 6,
        question: 'Can I choose which project my donation goes toward?',
        answer:'Yes, we offer the flexibility to donate to specific projects. You can choose the project that resonates with you the most, and your donation will be directed accordingly.'
    },
    {
        id: 7,
        question: 'How can I stay updated on your Ramadan Projects’ progress?',
        answer:'You can stay updated by visiting our website or following us on social media. We regularly share stories, photos, and updates on the impact of our Ramadan initiatives.'
    },
    {
        id: 8,
        question: 'What impact can be donation make during Ramadan?',
        answer:'Your donation can provide essential assistance, nourishment, support and joy to individuals and families in need during Ramadan. It can help ensure that they have access to Iftar meals, food, daily sustenance and even celebrate Eid with dignity and happiness. Every contribution counts and makes a positive difference in the lives of those we serve.'
    },
    {
        id: 9,
        question: 'How do you select the beneficiaries of your Ramadan Projects?',
        answer:'Beneficiaries are selected through a careful assessment process that considers their needs and vulnerabilities. We work closely with community leaders, local NGOs, and social workers to identify and assist regions and communities who require support the most.'
    },
    {
        id: 10,
        question: 'How do you ensure that aid reaches those in remote or conflict-affected areas?',
        answer:'We collaborate with local partners who have in-depth knowledge of the regions we serve. These partners have experience navigating challenges, including remote or conflict-affected areas. We work together to ensure that aid reaches the intended recipients safely and efficiently.'
    },
    {
        id: 11,
        question: 'What is the impact of my donation beyond Ramadan? ',
        answer:'Your donation’s impact extends far beyond Ramadan. We aim to create sustainable solutions that uplift communities even after the holy month ends. Your support helps improve education, healthcare, community cohesion, and overall well-being in the regions in which we operate.'
    },
];
const lastTenNightsFaqs = [
    {
        id: 0,
        question: 'What is the significance of Laylatul Qadr?',
        answer:`Laylatul Qadr, known as the Night of Decree, is believed to be the holiest night in Ramadan. It's when the Quran was first revealed to Prophet Muhammad (PBUH). Acts of worship during this night are said to be more rewarding than a thousand months of worship.`
    },
    {
        id: 1,
        question: 'When is Laylatul Qadr?',
        answer:'Laylatul Qadr falls within the last ten nights of Ramadan, usually on the 27th night. Scholars encourage us to seek it throughout the last ten nights as its exact date is unknown and can fall on any odd night.'
    },
    {
        id: 2,
        question: 'How can I donate during Laylatul Qadr?',
        answer:`Donating during Laylatul Qadr is simple with MATW Project - in fact, donating throughout the entire month is easy! Just choose your preferred project, select the date you’d like to start donating from, set up your payment plan, and automate your donations. This will Insha’Allah ensure you don't miss out on giving during these sacred nights!`
    },
];
const laylatulQadr2024Faqs = [
    {
        id: 0,
        question: 'Seeking Laylatul Qadr – When Exactly Is It In 2024?',
        answer:`Traditionally sought during the last ten nights of this blessed month of Ramadan, Laylatul Qadr’s presence is felt most deeply on the odd-numbered nights. This year, in 2024, from the eve of April 5th, Muslims across the world will turn their hearts and souls towards this night of unparalleled importance. Engaging fervently in prayer, reflection, and acts of charity, they seek the divine blessings and mercy that Laylatul Qadr bestows upon the faithful.`
    },
    {
        id: 1,
        question: 'The Signs of Laylatul Qadr - A Night of Divine Spiritual Illumination',
        answer:'The signs of Laylatul Qadr encompass a night of serene tranquility, where the soul finds peace and solace. The temperature is mild, neither too hot nor too cold, and the sun rises without its usual intensity, casting a gentle glow over a clear sky. This blessed night is neither fleeting nor overly prolonged, offering believers a precious span of time to draw closer to their Creator, seeking forgiveness, mercy, and abundant blessings.'
    },
    {
        id: 2,
        question: 'The Transformative Impact of Giving on Laylatul Qadr',
        answer:`Laylatul Qadr holds a profound significance for acts of charity and giving. On this sacred night, the virtues of giving are multiplied manifold, offering a unique opportunity to help those in need while purifying one's wealth and drawing closer to Allah through selfless generosity. It is believed that donating on Laylatul Qadr brings blessings equivalent to giving charity every day for a thousand months, highlighting the immense spiritual rewards and transformative power of giving on this auspicious night.`
    },
    {
        id: 3,
        question: 'Illuminate the Night with Your Generosity: Laylatul Qadr Donation Drive',
        answer:`As Laylatul Qadr approaches, let us heed the call to action and become instruments of hope for those in need. Your donation, no matter how big or small, can provide crucial aid to the less fortunate, support educational initiatives, and strengthen our community. Whether you choose to feed the hungry, aid the sick, or support a cause dear to your heart, your contribution on this sacred night can truly change lives. Seize this opportunity to earn countless rewards by making your Laylatul Qadr donation today. Let us unite in this blessed month to make a positive impact, seeking the pleasure of Allah and the betterment of our society. Join us in commemorating the Night of Power with generous giving and heartfelt prayers, as we strive to embody the spirit of this auspicious night.`
    },
];
export { ramadanFaqs, lastTenNightsFaqs, laylatulQadr2024Faqs};
