import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import HeroTabContent from './heroTabContent';
import useScrollTo from '../../../hooks/general/useScrollTo';

const HeroTab = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  /* -------------------------------------------------------------------------- */
  const { tabButtons } = props;

  const tabButtonsProducts = tabButtons && tabButtons;
  const productTitle = props.title && props.title;

  const products =
    props.products &&
    props.products.map(product => ({
      products: product,
    }));

    const [scrollTo, scrollToRef] = useScrollTo();

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);

  /* -------------------------------------------------------------------------- */
  return tabButtonsProducts ? (
    <section className="bg-[#253B7E]">
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className="flex flex-wrap gap-3 items-center justify-center">
          {tabButtonsProducts.map(({ title }, index) => (
            <button
              key={`btn_${index}`}
              onClick={() => {
                setActiveTabProducts(index)
                scrollTo();
                }
              }
              className={`${
                index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'
              } rounded-full px-3 py-2 sm:px-6 uppercase text-[15px] font-bold font-['Montserrat']`}
            >
              {title}
            </button>
          ))}
        </div>
          <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 mt-4 md:mt-8">
            <div ref={scrollToRef} className="flex flex-col w-full justify-center">
              {tabButtonsProducts.map(
                (item, index) =>
                  index === activeTabProducts && (
                    <React.Fragment key={index}>
                      <div key={`description${index}`} className="flex w-full justify-center mb-4 md:mb-8">
                        <h3 className="text-center text-white text-base md:text-3xl max-w-[1080px] font-['montserrat'] font-semibold">{item.description}</h3>
                      </div>
                      <HeroTabContent products={item.products} currency={selectedCurrency} />
                    </React.Fragment>
                  ),
              )}
            </div>
        </div>
      </div>
    </section>
  ) : (
    <section ref={scrollToRef} className="bg-[#253B7E]">
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        {productTitle && <h3 className="text-center mx-auto text-white text-lg md:text-3xl lg:max-w-[950px] font-['montserrat'] font-semibold">{productTitle}</h3>}
        {products.map((item, index) => (
          <div key={index}>
            <div key={`description${index}`} className="flex w-full justify-center mb-6">
              <h3 className="text-center text-white text-base md:text-3xl max-w-[1080px] font-['montserrat'] font-semibold">{item.description}</h3>
            </div>
            <HeroTabContent products={item.products} currency={selectedCurrency} btnGroupClasses={props.btnGroupClasses && props.btnGroupClasses} />
          </div>
        ))}
      </div>
    </section>
  );
};
export default HeroTab;
