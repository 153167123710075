import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../../store/user';
import TabContent from '../../home/components/tab-content';
import { useRef } from 'react';

const FoodWaterTabs = props => {
  /* ----------------------------- Start variables ---------------------------- */
  const { foodAidProducts, waterAidProducts, babyMilkProducts, lang = 'en' } = props;
  const contentRef = useRef(null);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [activeTabProducts, setActiveTabProducts] = useState(0);
  const tabButtonsProducts = [
    {
      title: lang === 'fr' ? 'AIDE ALIMENTAIRE' : lang === 'ar' ? 'مساعدات غذائية' : 'Food Aid',
      description: '',
      products: foodAidProducts,
    },
    {
      title: lang === 'fr' ? 'AIDE POUR APPROVISIONNEMENT EN EAU' : lang === 'ar' ? 'مساعدات مياه' : 'Water Aid',
      description: '',
      products: waterAidProducts,
    },
    {
      title: lang === 'fr' ? 'LAIT POUR BÉBÉ' : lang === 'ar' ? 'حليب الأطفال' : 'Baby Milk',
      description: '',
      products: babyMilkProducts,
    },
  ];
  /* -------------------------------------------------------------------------- */

  const handleTabChange = index => {
    window.scrollTo({ behavior: 'smooth', top: contentRef.current.offsetTop - 200 });
    setActiveTabProducts(index);
  };
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  /* -------------------------------------------------------------------------- */
  return (
    <section className="bg-[#253B7E]">
      <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12">
        <div className=" flex flex-wrap gap-2 min-[501px]:gap-3 items-center justify-center">
          {tabButtonsProducts.map(({ title }, index) => (
            <button
              key={`btn_${index}`}
              onClick={handleTabChange.bind(this, index)}
              className={`${index === activeTabProducts ? 'bg-[#F60362] text-white' : 'bg-[#F8F8F8] text-[#78716C]'}
              ${lang === 'ar' ? 'font-[AlmariMedium]' : ''}
              text-[14px] rounded-full p-2 min-[501px]:px-4 min-[501px]:min-w-36 uppercase font-["Montserrat"] font-bold`}
            >
              {title}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
          <div className="flex flex-col w-full justify-center">
            {tabButtonsProducts.map(
              (item, index) =>
                index === activeTabProducts && (
                  <React.Fragment key={`description_${index}`}>
                    <div className="flex w-full justify-center mb-6">
                      <p className="text-center text-white text-[18px] md:text-3xl md:w-2/3">{item.description}</p>
                    </div>
                    <div ref={contentRef}>
                      <TabContent
                        products={item.products}
                        currency={selectedCurrency}
                        showDots={true}
                        showArrows={true}
                        showMultiProductsOnRow={false}
                        viewAppealsLink={item.viewAppealsLink || null}
                        lang={lang}
                      />
                    </div>
                  </React.Fragment>
                ),
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default FoodWaterTabs;
