import React, { useState, useEffect, useCallback } from 'react';
import services from '../../services';

const useDashboardReceiptsHook = () => {
  const [allProducts, setAllProducts] = useState();
  const [selectedProducts, setSelectedProducts] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [currency, setCurrency] = useState('');
  const [searchItems, setSearchItems] = useState({
    payment_status: '',
    date_from: new Date().toISOString().split('T')[0],
    date_to: new Date().toISOString().split('T')[0],
    payment_gateway: 'stripe',
    page_no: 1,
    per_page: Number(localStorage.getItem('pageSize')),
    currency: '',
    mode: '',
  });
  const columnsDataProducts = [
    {
      Header: 'AMOUNT',
      accessor: 'total_amount',
    },
    {
      Header: 'Currency',
      accessor: 'currency',
    },
    {
      Header: 'Host',
      accessor: 'host',
    },
    {
      Header: 'Mode',
      accessor: 'mode',
    },
    {
      Header: 'Created Date',
      accessor: 'order_date',
    },
    {
      Header: 'Status',
      accessor: 'payment_status',
    },
    {
      Header: 'transaction id',
      accessor: 'transaction_id',
    },
    {
      Header: 'receipt_url',
      accessor: 'receipt_url',
    },
    {
      Header: 'order_id',
      accessor: 'order_id',
    },
    {
      Header: 'Actions',
      accessor: 'id',
    },
  ];

  const columnsProducts = [
    {
      Header: 'Product',
      accessor: 'item_name',
    },
    {
      Header: 'ON BEHALF OF',
      accessor: 'plaque_name',
    },
    {
      Header: 'AMOUNT',
      accessor: 'item_cost',
    },
    {
      Header: 'quantity',
      accessor: 'quantity',
    },
    {
      Header: 'sub total',
      accessor: 'order_subtotal_amount',
    },
  ];

  const columnsTransactions = [
    {
      Header: 'TRANSACTION ID',
      accessor: 'transaction_id',
    },
    {
      Header: 'CRYPTO CURRENCY',
      accessor: 'crypto_currency',
    },
    {
      Header: 'CRYPTO AMOUNT',
      accessor: 'total_crypto_amount',
    },
    {
      Header: 'type',
      accessor: 'type',
    },
    {
      Header: 'DATE',
      accessor: 'created_at',
    },
    {
      Header: 'AMOUNT PAID',
      accessor: 'crypto_currency_total_amount_paid',
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    console.log('hook searchItems', searchItems);

  }, [searchItems])

  const search = useCallback(async () => {
    setIsLoading(true);
    console.log('searchItems', searchItems);
    const options = {
      provider: searchItems.payment_gateway,
      mode: searchItems.mode,
      payment_status: searchItems.payment_status,
      date_from: searchItems.date_from,
      date_to: searchItems.date_to,
      currency: searchItems.currency,
      page: searchItems.page_no,
      limit: searchItems.per_page
    }
    const receipts = await services.getDashboardReceipts(options);
    if (receipts.hasOwnProperty('data')) setAllProducts(receipts.data);
    setLastPage(receipts.meta.last_page);
    setCurrentPage(receipts.meta.current_page);
    setPageSize(receipts.meta.per_page);
    setIsLoading(false);
  }, [searchItems]);

  const getProducts = async (order_id, payment_method, currency) => {
    setIsLoading(true);
    const products = await services.getDashboardReceiptProducts(order_id, payment_method);
    setShowProducts(true);
    setSelectedProducts(products);
    console.log({ currency });
    setCurrency(currency);
    setIsLoading(false);
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [currentPage, page]);

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    search();
  }, [searchItems.page_no, searchItems.per_page, search]);

  return {
    columnsDataProducts,
    currency,
    columnsTransactions,
    columnsProducts,
    selectedProducts,
    allProducts,
    isLoading,
    searchItems,
    pageSize,
    lastPage,
    currentPage,
    setSearchItems,
    setShowProducts,
    search,
    showModal,
    selectedProduct,
    setShowModal,
    setSelectedProduct,
    getProducts,
    showProducts,
  };
};

export default useDashboardReceiptsHook;
