import React from 'react'
import imageJHCO from '../../../../images/landings/palestine/image-jhco.png';
import imageAlHilal from '../../../../images/landings/palestine/image-alhilal.png';
import imageEyc from '../../../../images/landings/palestine/image-eyc.png';

const MuslimsAroundSection = ({ arabic, lang = 'en' }) => {
  return (
    <div className="w-full md:container md:mx-auto flex flex-wrap justify-center items-center pt-4 sm:pt-0 px-4 md:px-0 mt-8 md:mt-16" dir={arabic || lang === 'ar' ? 'rtl' : 'ltr'}>
      <div className="flex flex-col justify-center items-center gap-5 md:gap-8">
        <p className={`text-2xl md:text-[40px] max-w[884px] text-[#253B7E] text-center font-bold ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : 'font-["Montserrat"]'}`}>
          Muslims Around The World (MATW) on the ground in Palestine:
        </p>
        <h2 className={`text-[#78716C] text-center text-sm md:text-[18px] font-bold ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : 'font-["Montserrat"]'}`}>
          In Partnership with
        </h2>
      </div>
      <div className="flex justify-center items-center flex-col gap-3 md:gap-6">
        <img src={imageJHCO} alt="jhco logo" className="w-[301px] md:w-[585px]" />
        <div className="flex gap-5 items-center">
          <img src={imageAlHilal} alt="alhilal logo" className="w-[120px] md:w-[201px]" />
          <img src={imageEyc} alt="eyc logo" className="w-[71px] md:w-[100px]" />
        </div>
      </div>
      <div className={`mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4 font-bold ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : 'font-["Montserrat"]'}`}>
        <p>
          With a long-standing presence in Palestine and supplies on standby for an immediate response, our MATW team has been quick to deliver
          urgent essentials to those directly affected by the conflict in Palestine. Our direct response efforts are focused on those who are most
          vulnerable including orphans, widows, the elderly and families.
        </p>
        <p>
          Our operations team is delivering emergency medical kits, urgent food packs, fuel, hot meals and more to those who have been displaced.
        </p>
        <p>
          Our medical response within hospitals proved to be a critical lifeline. Thanks to donors, our team was able to deliver stretchers, beds,
          mattresses, blankets, medical kits and supplies. We also were able to provide staff support and fuel to ensure medical make-shift camps
          and operational hospitals continue to run.
        </p>
        <p className={`text-2xl text-[#253B7E] font-bold ${arabic || lang === 'ar' ? 'font-[AlmariMedium]' : 'font-["Montserrat"]'}`}>
          Our team is primarily distributing with the Jordanian Hashemite Charity Organisation (JHCO). We’re also partnered with the Egyptian Youth
          Council, the Egyptian Red Crescent to ensure relief is reaching those in need in Palestine.
        </p>
        <p>
          We are also working to provide urgent essentials and family relocation support to Palestinian families seeking refuge in Egypt. Through
          these projects, donors will be able to help us provide family sponsorship and settlement support to ensure a new start for families with
          all that they need.
        </p>
        <p>
          We are determined as ever to never give up. Every life is valuable, and your support allows us to continue doing the work that we do.
        </p>
        <p>
          With full transparency to our donors, every single donation to our Palestine Emergency Appeal will go directly to the field, with a 100%
          donation policy.
        </p>
        <p>
          Donations to our Palestine Emergency Appeal are also 100% Zakat applicable.
        </p>
      </div>
    </div>
  )
}

export default MuslimsAroundSection