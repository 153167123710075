import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import { allFaqs } from './sadaqah-mostPopular';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import QurbanProductBox from '../../components/general/qurbanProductBox';
import img1 from '../../images/qurban/cow1.jpg';
import imgSlider from '../../images/qurban/Water for School 1-27 screenshot 2.jpg';
import services from '../../services';
import Accordion from '../../components/general/accordion';
import useHandleAddProduct from '../../utils/handleAddProduct';

const CowsPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [products, setProducts] = useState();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [indiaCowProduct, setIndiaCowProduct] = useState();
  const [addProduct] = useHandleAddProduct();
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getProductsByName = async (name) => {
    setProducts(null)
    try {
      const response = await services.categoryProductsByName("cow");
      console.log('response get categoryProductsByName', response);
      setProducts(response.data);
      response.data.products.map(item => {
        item.name === 'India Whole Cow Qurban' && Number(item.status) === 1 && setIndiaCowProduct(item);
        return item;
      })
      // ChangeDocumentTitle(`${response.data.name} - ${response.data.category.name} - MATW Project Muslim Charity`);
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
  }, [selectedCurrencyStore])
  useEffect(() => {
    window.scroll(0, 0);
  }, [])
  useEffect(() => {
    let pathNames = window.location.pathname.split('/');
    if (pathNames.length > 2) {
      let temp = pathNames[2].split('-');
      temp = temp.join(' ');
      getProductsByName(temp);
      console.log("params", temp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout>
      {products && <img src={products.tile_image} alt={products.name} className='w-full' onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = "/images/general/logo-replace.png";
        currentTarget.className = 'max-h-[150px] mx-auto'
      }} />}
      <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} />
      <div className="w-full bg-[#F60362] py-6">
        <div className="md:container md:mx-auto lg:mt-5">
          <div className="flex flex-wrap flex-col- justify-center px-3 md:px-4">
            <div className="basis-full lg:basis-1/2 text-center text-6xl text-white py-2 lg:py-8 font-['Gotcha']">
              Cow
              {/* <h2 className='text-white text-3xl md:text-4xl lg:text-5xl mb-4 md:mb-6'>Deliver fresh Sadaqah (1/7th or a whole cow) to families in need this Eid!</h2> */}
              {/* <p className="text-white text-base md:text-2xl lg:text-3xl font-['Abeezee']">
                                For many poor families around the world, your Qurban donation is the only time they will get to eat meat in the entire year. Last year, we reached more than 6.1 million people through our Qurbani campaign. Help us reach more this year. Donate Now.
                            </p> */}
            </div>
            {/* /* ------------------------------- Section Two ------------------------------ */}
            <div className='w-full flex- hidden flex-wrap items-stretch justify-start bg-[#E2EFF3]'>
                <div className="basis-full lg:basis-5/12 xl:basis-6/12 h-full max-[575px]:max-h-[200px] max-[767px]:max-h-[300px] max-[1023px]:max-h-[400px] overflow-hidden">
                    <img src={img1} alt="" className='w-full min-h-full' />
                </div>
                <div className="basis-full lg:basis-7/12 xl:basis-5/12 flex flex-col justify-center px-8 py-8 lg:pt-16 order-2 lg:order-1">
                    <h2 className='font-bold text-3xl mb-4 xl:mb-8'>Donate a cow to feed the hungry across India this Eid </h2>
                    <p className='text-sm'>India is home to a vast population that struggles to secure access to adequate and nutritious food for themselves and their families. By donating cow meat through MATW this Eid, individuals can make a direct and meaningful impact in alleviating hunger and improving the well-being of these vulnerable communities. This act of generosity not only nourishes their bodies but also uplifts their spirits, reminding them that they are not alone and that their well-being matters.
                    </p>
                    <div className="flex justify-start mt-8">
                        {indiaCowProduct && <button
                            className='border border-[#F60362] bg-transparent text-[#F60362] hover:bg-[#F60362] hover:text-white px-8 h-10 flex items-center justify-center rounded'
                            onClick={(e) => { addProduct({product: indiaCowProduct, currency: selectedCurrency}) }}
                        >
                            Donate Now
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ml-2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </button>}
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      {/* /* ------------------------------- Section Two ------------------------------ */}
      <div className="w-full flex- hidden flex-wrap items-stretch justify-start bg-[#E2EFF3]">
        <div className="basis-full lg:basis-5/12 xl:basis-6/12 h-full max-[575px]:max-h-[200px] max-[767px]:max-h-[300px] max-[1023px]:max-h-[400px] overflow-hidden">
          <img src={img1} alt="" className="w-full min-h-full" />
        </div>
        <div className="basis-full lg:basis-7/12 xl:basis-5/12 flex flex-col justify-center px-8 py-8 lg:pt-16 order-2 lg:order-1">
          <h2 className="font-bold text-3xl mb-4 xl:mb-8">Donate a cow to feed the hungry across India this Eid </h2>
          <p className="text-sm">
            India is home to a vast population that struggles to secure access to adequate and nutritious food for themselves and their families. By
            donating cow meat through MATW this Eid, individuals can make a direct and meaningful impact in alleviating hunger and improving the
            well-being of these vulnerable communities. This act of generosity not only nourishes their bodies but also uplifts their spirits,
            reminding them that they are not alone and that their well-being matters.
          </p>
          <div className="flex justify-start mt-8">
            {indiaCowProduct && (
              <button
                className="border border-[#F60362] bg-transparent text-[#F60362] hover:bg-[#F60362] hover:text-white px-8 h-10 flex items-center justify-center rounded"
                onClick={e => {
                  addProduct(indiaCowProduct, selectedCurrency);
                }}
              >
                Donate Now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 ml-2"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      {/* /* ----------------------------------- FAQ ---------------------------------- */}
      <div className="w-full h-auto flex- hidden flex-col justify-center items-center bg-[#E2EFF3] pb-16">
        <h2 className="text-2xl text-center mt-8 mb-4">Frequently Asked Questions</h2>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-8 md:px-0 mt-6">
          {allFaqs.map((item, index) => {
            return (
              <div className="bg-white rounded-md p-4 mt-2 w-full" key={`faq${index}`}>
                <Accordion key={`keyallfaq${index}`} title={item.question} index={index} level={1} noBorder={true}>
                  {item.answer}
                </Accordion>
              </div>
            );
          })}
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
      {/* /* ----------------------------------- Image -------------------------------- */}
      <div className="w-full h-auto flex- hidden items-center">
        <a href="/zakat/give-zakat" className="w-full">
          <img src={imgSlider} alt="" className="w-full min-h-full" />
        </a>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};

export default CowsPage;
