import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import services from '../../../../services';
import CustomModal from '../../components/modal';
import CheckBox from '../../../../components/general/checkbox';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../../../components/general/button';
import Multiselect from 'multiselect-react-dropdown';
import Select from 'react-select';
const styles = {
    control: (base) => ({
        ...base,
        border: '1px solid #e7e5e4',
        boxShadow: '0px 0px 2px 0px rgb(220, 220, 220)',
        ":hover": {
            boxShadow: '0px 0px 8px 0px rgb(216, 216, 216)',
        }
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}
const AddContentBlock = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { show, onClose, onSaved } = props;
    const fileRef = useRef(null);
    const [file, setFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [banners, setBanners] = useState([]);
    const [selectedBanners, setSelectedBanners] = useState([]);
    const [showAddImage, setShowAddImage] = useState(false);
    const [videoLink, setVideoLink] = useState('');
    const [contents, setContents] = useState('');
    const [contentBlock, setContentBlock] = useState(
        {
            name: '',
            title: '',
            content: '',
            type: { value: 'text', label: 'Text' },
            products: [],
        }
    )
    const [banner, setBanner] = useState(
        {
            name: '',
            image: '',
            mobileImage: '',
            imgUrl: '',
            mobileImgUrl: '',
            link: '',
            is_page_banner: '0',
            order: 1,
            status: 1,
            section: 'page_builder',
            alt: ''
        }
    )
    const prdBlockSchema = Yup.object().shape({
        name: Yup.string().min(3, 'Should be 3 character long').required('Enter content block name'),
        title: Yup.string().min(3, 'Should be 3 character long').required('Enter content block title'),
        // type: Yup.string(),
        content: Yup.string(),
    });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getBanners = async (_selectedBanners, isNewBanner = false) => {
        try {
            const response = await services.getBanners();
            setBanners(response.data);
            if (_selectedBanners) {
                let tempBnrs = [];
                response.data.map(item => {
                    _selectedBanners.map(inner_item => {
                        if (inner_item.id === item.id) tempBnrs.push(item)
                        return inner_item
                    })
                    return item
                })
                if (isNewBanner) {
                    tempBnrs.push(response.data[response.data.length - 1])
                }
                setSelectedBanners(tempBnrs)
            }
            console.log('response get getBanners', response.data);
        } catch (error) {
            console.error('error========', error);
        }
    };
    const handleSaveContenttBlock = async (values) => {
        setIsLoading(true);
        let tempContent = values.type.value === 'image'
            // ? selectedBanners.map(item => item.id)
            ? `${selectedBanners[0].id}`
            : values.type.value === 'video'
                ? videoLink
                : values.content
        let req = {
            name: values.name,
            title: values.title,
            type: values.type.value,
            content: tempContent,
            codes: []
        }
        try {
            await services.createContentBlock(req);
            onSaved();
            closeModal();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const handleSelectProduct = (selectedList) => {
        setSelectedProducts(selectedList)
    }
    const handleRemoveProduct = (selectedList) => {
        setSelectedProducts(selectedList)
    }
    const handleKeyDown = (value) => {
        let checkIfNum;
        if (value.key !== undefined) {
            if (value.key === "." || value.key === " " || value.key === "/") checkIfNum = true;
        }
        return checkIfNum && value.preventDefault();
    }
    const handleBannerFileChange = (e) => {
        if (e.target.files) {
            let tempFile = e.target.files[0];
            if (tempFile.type !== 'image/jpeg') {
                toast.warning('Please upload a jpg file');
                fileRef.current.value = '';
                return false;
            }
            if (tempFile.size > 500000) {
                toast.warning('Please upload a file smaller than 500 KB');
                fileRef.current.value = '';
                return false;

            }
            setFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(tempFile);
            setBanner({ ...banner, imgUrl: objectUrl, name: tempFile.name.replace(/\s+/g, '') });
        }
    };
    const saveBanner = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('image', file);
        formData.append('mobile_image', '');
        formData.append('name', banner.name);
        formData.append('link', '/');
        formData.append('order', '100');
        formData.append('status', banner.status);
        formData.append('alt', banner.alt);
        formData.append('section', banner.section);
        formData.append('is_page_banner', '1');
        try {
            await services.createBanner(formData, file);
            getBanners(selectedBanners, true);
            setShowAddImage(false);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const handleSelectBanner = (selectedList) => {
        setSelectedBanners(selectedList)
    }
    const handleRemoveBanner = (selectedList) => {
        setSelectedBanners(selectedList)
    }
    const closeModal = () => {
        setContentBlock({
            ...contentBlock,
            name: '',
            title: '',
            products: [],
        });
        onClose();
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        show && getBanners();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    /* -------------------------------------------------------------------------- */
    return (
        <CustomModal
            title={showAddImage ? 'Add New Image' : 'Add New Content Block'}
            show={show}
            className='pb-0'
            onClose={() => closeModal()}
        >
            {showAddImage
                ? <div className='w-full grid grid-cols-4 items-end gap-2 md:gap-3'>
                    <div className="flex flex-col col-span-4 md:col-span-2 md:w-auto relative">
                        <div className="absolute bottom-1 right-1 rounded h-6 flex flex-col items-center md:flex-row overflow-hidden">
                            <span className="h-6 w-6 flex items-center justify-center rounded-full cursor-pointer text-blue-700 bg-white">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M1 8a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 018.07 3h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0016.07 6H17a2 2 0 012 2v7a2 2 0 01-2 2H3a2 2 0 01-2-2V8zm13.5 3a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM10 14a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                </svg>
                            </span>
                            <input ref={fileRef} type="file" accept="image/jpeg" className='opacity-0 absolute w-full cursor-pointer' onChange={(e) => handleBannerFileChange(e)} />
                        </div>
                        <p className="w-full text-sm text-stone-500 mb-2">Image:</p>
                        <div className="flex items-center w-full md:max-h-[250px] overflow-hidden border border-stone-200 rounded-md">
                            <img src={
                                banner.imgUrl
                                    ? banner.imgUrl
                                    : banner.image ? banner.image : `/images/general/logo-replace.png`
                            } alt="slider" className='w-full- w-auto h-auto rounded' />
                        </div>
                    </div>
                    <div className="flex col-span-4 justify-end pt-4">
                        <button
                            type='button'
                            className='border border-red-500 hover:bg-red-500 text-red-500 hover:text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 mr-2'
                            onClick={() => setShowAddImage(false)}>Cancel</button>
                        <button
                            type='button'
                            disabled={isLoading}
                            className='bg-[#0f960f] hover:bg-[#096909] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200'
                            onClick={saveBanner}>
                            {isLoading ? (<>Processing...<svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            </>) : 'Save'}
                        </button>
                    </div>
                </div>
                : <Formik
                    enableReinitialize
                    initialValues={{
                        name: contentBlock.name,
                        products: contentBlock.products,
                        title: contentBlock.title,
                        type: contentBlock.type,
                        content: contentBlock.content
                    }}
                    validationSchema={prdBlockSchema}
                    onSubmit={(values) => handleSaveContenttBlock(values)}
                >
                    {({ errors, values, touched, handleBlur, handleChange, handleSubmit }) => (
                        <form onSubmit={(values) => { handleSubmit(values); console.log("submited=", errors, values) }} className='w-full'>
                            <div className="grid grid-cols-12 items-end w-full md:pl-3- mt-3 md:mt-0 gap-2">
                                <div className="flex col-span-12 sm:col-span-4 flex-col min-h-[80px] items-start">
                                    <label htmlFor="name">Block Name</label>
                                    <input name='name' type="text" value={values.name}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder='Block Name'
                                        className='border lowercase w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                    {(errors.name && touched.name) &&
                                        <small id='name-error' className="text-[#f60362] text-xs">
                                            {errors.name}
                                        </small>
                                    }
                                </div>
                                <div className="flex col-span-12 sm:col-span-4 flex-col min-h-[80px] items-start">
                                    <label htmlFor="title">Block Title</label>
                                    <input name='title' type="text" value={values.title}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder='Block Title'
                                        className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                    {(errors.title && touched.title) &&
                                        <small id='title-error' className="text-[#f60362] text-xs">
                                            {errors.title}
                                        </small>}
                                </div>
                                <div className="flex col-span-12 sm:col-span-4 flex-col min-h-[80px] items-start [&>div]:w-full">
                                    <label htmlFor="type">Block Type</label>
                                    <Select
                                        options={[
                                            { value: 'text', label: 'Text' },
                                            { value: 'image', label: 'Image' },
                                            { value: 'video', label: 'Video' },
                                        ]}
                                        placeholder="Select Type"
                                        value={values.type}
                                        styles={styles}
                                        name='type'
                                        onChange={(val) => {
                                            let e = { target: { value: val, name: 'type', id: 'type' } };
                                            console.log("changes=", e, val)
                                            handleChange(e);
                                            // handleChange(val);
                                        }}
                                        isSearchable={false}
                                    />
                                    {/* {(errors.type && touched.type) &&
                                        <small id='type-error' className="text-[#f60362] text-xs">
                                            {errors.type}
                                        </small>} */}
                                </div>
                                {values.type.value === 'image' && <div className="flex col-span-12 flex-col mb-4">
                                    <label htmlFor="banners">
                                        Images
                                        <small className='ml-1 text-stone-400'>(Select image from list or add new image)</small>
                                    </label>
                                    <div className="flex flex-col md:flex-row [&>div]:w-full">
                                        <Multiselect
                                            avoidHighlightFirstOption={true}
                                            options={banners} // Options to display in the dropdown
                                            selectedValues={selectedBanners} // Preselected value to persist in dropdown
                                            onSelect={handleSelectBanner} // Function will trigger on select event
                                            onRemove={handleRemoveBanner} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                        <CustomButton
                                            onClick={() => setShowAddImage(true)}
                                            type={'button'}
                                            title={'Add Image'}
                                            className='border border-[#0f960f] hover:bg-[#096909] !text-[#0f960f] bg-transparent mt-2 md:mt-0 md:ml-2 hover:!text-white px-4 min-w-[140px] h-[46px] flex items-center justify-center rounded transition-all duration-200'
                                        />
                                    </div>
                                </div>}
                                {values.type.value === 'text' && <div className="flex col-span-12 flex-col min-h-[80px] items-start">
                                    <label htmlFor="content">Content</label>
                                    <textarea rows="2" name='content' type="text"
                                        value={values.content}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 p-2' />
                                    {(errors.content && touched.content) &&
                                        <small id='content-error' className="text-[#f60362] text-xs">
                                            {errors.content}
                                        </small>}
                                </div>}
                            </div>
                            <div className="flex w-full flex-col md:flex-row justify-end pt-4 gap-2">
                                <CustomButton
                                    onClick={() => closeModal()}
                                    title='Cancel'
                                    className='border border-red-500 hover:bg-red-500 !text-red-500 hover:!text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 md:mr-2'
                                />
                                <CustomButton
                                    disabled={isLoading}
                                    type="submit"
                                    isLoading={isLoading}
                                    title={'Save'}
                                    className='!bg-[#0f960f] hover:!bg-[#096909] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200'
                                />
                            </div>
                        </form>
                    )}
                </Formik>
            }
        </CustomModal>
    );
};
export default AddContentBlock;