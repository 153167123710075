import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../../store/user';
import SeoData from '../../../utils/seo.json';
import { selectProducts } from '../../../store/products';
import { OurPromiseSection } from '../../../components/general';
import { Helmet } from 'react-helmet-new';
import giftImg1 from '../../../images/landings/gift-sound/gift-sound-img1.jpg';
import giftImg2 from '../../../images/landings/gift-sound/gift-sound-img2.jpg';
import giftImg3 from '../../../images/landings/gift-sound/gift-sound-img3.jpg';
import CustomButton from '../../../components/general/button';
import banner from '../../../images/landings/gift-sound/Hearing-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../../images/landings/gift-sound/Hearing-Hero-Banner-Mobile.jpg';
import CrisisWidget from '../../crisis-and-emergencies/components/crisisWidget';
import GiftSoundProducts from './products';
import { giftSoundFaqs } from './faq';
import useHandleAddProduct from '../../../utils/handleAddProduct';
import NewHelmetLayout from '../../../components/general/newHelmetLayout';

const GiftOfSoundLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [addProduct] = useHandleAddProduct();
  const products = useSelector(state => selectProducts(state));
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const [giftSoundsProducts, setGiftSoundsProducts] = useState([0, 0]);
  const [helpThemHearPrd, setHelpThemHearPrd] = useState();
  const giftSounds = [
    {
      image: giftImg1,
      title: 'Partner collaboration and needs assessment.',
      alt: 'Muslim Charity to help Muslims around the world'
    },
    {
      image: giftImg2,
      title: 'Setting up of mobile health clinics to distribute and fit hearing aids.',
      alt: 'Muslim Charity to help Muslims around the world'
    },
    {
      image: giftImg3,
      title: 'Tracking progress and assessing impact to gather feedback. ',
      alt: 'Muslim Charity to help Muslims around the world'
    },
  ];
  const tempGiftSounds = [
    {
      "id": 394,
      "stripe_product_id": "prod_PmtIo7JjmgnvhY",
      "new_stripe_product_id": "prod_PmtMBfM07SHcOL",
      "us_stripe_product_id": "prod_PmtQUUj9Knk317",
      "paypal_product_id": "PROD-4W662696LG762711M",
      "creator": "HLT-DIS-EAR-2024-01",
      "name": "Gift Of Sound For 1 Person",
      "description": "With your Sadaqah Jariyah, we can provide the gift of sound! Your donation delivers much-needed hearing aids and improved access to healthcare. Together, let's improve the lives of underprivileged people with hearing loss. ",
      "aud": 300,
      "usd": 200,
      "gbp": 160,
      "cad": 280,
      "eur": 190,
      "sgd": 280,
      "myr": 1000,
      "custom_amount": "Yes",
      "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-gift-of-sound.jpg",
      "recurring": "Allowed",
      "status": "Active",
      "created_at": "2024-03-23 12:28:12",
      "updated_at": "2024-03-23 12:44:40",
      "deleted_at": null,
      "category_id": 28,
      "aed": 750,
      "idr": 3000000,
    },
    {
      "id": 395,
      "stripe_product_id": "prod_PmtI5yK9PMM6Tc",
      "new_stripe_product_id": "prod_PmtMe2rccmm27M",
      "us_stripe_product_id": "prod_PmtQh3UXyBeBC5",
      "paypal_product_id": "PROD-4X943163RJ788430D",
      "creator": "HLT-DIS-EAR-2024-01-A",
      "name": "Help Them Hear",
      "description": "Give any amount to help provide the gift of sound! Your donation will contribute to delivering much-needed hearing aids and improved access to healthcare. ",
      "aud": 1,
      "usd": 1,
      "gbp": 1,
      "cad": 1,
      "eur": 1,
      "sgd": 1,
      "myr": 2,
      "custom_amount": "Yes",
      "image_link": "https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-help-them-hear.jpg",
      "recurring": "Allowed",
      "status": "Active",
      "created_at": "2024-03-23 12:28:15",
      "updated_at": "2024-03-23 12:44:41",
      "deleted_at": null,
      "category_id": 28,
      "aed": 4,
      "idr": 15000,
    }
  ]
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempGiftSoundsProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'HLT-DIS-EAR-2024-01-A') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Islamic Charity: donate to build Masjids',
            })
            setHelpThemHearPrd(inner_item_product);
            tempGiftSoundsProducts[1] = inner_item_product
          }
          if (inner_item_product.creator === 'HLT-DIS-EAR-2024-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Muslim Charity to help palestinian refugees',
            })
            tempGiftSoundsProducts[0] = inner_item_product
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    console.log("gifrt sounds=",tempGiftSounds, tempGiftSounds[0])
    tempGiftSoundsProducts = tempGiftSoundsProducts.filter(n => n)
    // setGiftSoundsProducts(tempGiftSoundsProducts);
    tempGiftSoundsProducts.length ? setGiftSoundsProducts(tempGiftSoundsProducts) : setGiftSoundsProducts(tempGiftSounds);
    !tempGiftSoundsProducts.length && setHelpThemHearPrd(tempGiftSounds[1]);;
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  return (
    <NewHelmetLayout className="bg-white" isRamadanPage>
      {seo &&
        <Helmet>
          <title>{`${seo['home']['title']}`}</title>
          <link rel="canonical" href={`${window.location.protocol + '//matwproject.org'}${window.location.pathname}`} />
          <meta name="description" content={`${seo['home']['description']}`} />
        </Helmet>
      }
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: 'Where Most Needed',
            value: 122,
          }}
        />
      </section>
      {/* /* --------------------------------- Banner ---------------------------------  */}
      <section className="flex justify-center" >
        <img src={banner} alt="Gift of sound" className='max-[500px]:hidden w-full' />
        <img src={bannerMobile} alt="Gift of sound" className='min-[501px]:hidden w-full' />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            defaultProduct={{
              creator: 'MKD-MN-001',
              label: 'Where Most Needed',
              value: 122,
            }}
          />
        </div>
      </section>
      {/* /* ------------------------------- Our Promise ------------------------------ */}
      <div className="hidden md:block">
        <OurPromiseSection alt={'Muslim Charity with 100% donation policy'} />
      </div>
      {/* /* ------------------------------- Tab ------------------------------ */}
      <GiftSoundProducts giftSoundsProducts={giftSoundsProducts} />
      {/* /* ------------------------------- Our Promise ------------------------------ */}
      <div className="md:hidden">
        <OurPromiseSection alt={'Muslim Charity with 100% donation policy'} />
      </div>
      {/* /* -------------------------------- Our senses ------------------------------- */}
      <div className="w-full flex flex-wrap justify-center items-center md:container mx-auto py-8 px-4 md:px-0 bg-[#F1F1F1] md:bg-white">
        <p className="md:text-center font-bold text-[#093686] leading-[34px] sm:leading-[55px] text-[30px] sm:text-3xl md:text-[40px] md:px-4 basis-full md:basis-10/12 mb-3">
          Our senses are truly an incredible gift from Allah SWT.
          <br />
          <span className="text-[#F60362]">Help deliver the gift of sound to those in need.</span>
        </p>
        <p className="text-[#78716C] md:text-[18px] md:text-center mb-3 md:mb-6 lg:mb-12">
          We wouldn’t trade our hearing for any value of money, it is truly priceless. Imagine,
          not being able to hear the voices of your loved ones or the beautiful recitation of
          the Quran. Hearing is something we could never imagine living without. The World Health
          Organization (WHO) estimates that 278 million people are suffering from disabling
          hearing impairment and that 70% of these people live in low-income countries. For
          these people, treatment is difficult to afford and come by but for a small act of
          kindness, we can change that.
          <br />
          <br />
          We have the ability to ensure that people living in impoverished communities,
          suffering from hearing impairments, have the opportunity to regain their hearing.
          <br />
          <br />
          By restoring someone’s hearing, you’re giving a beautiful Sadaqah Jariyah. This
          continuous charity is not only an investment in your Akhirah but a priceless gift
          to someone in need, allowing them to walk independently through life.
        </p>
        <CustomButton onClick={() => addProduct({product: helpThemHearPrd, currency: selectedCurrency})} title='Donate Now' className='mb-2 sm:mb-0 min-w-[226px]' />
      </div>

      {/* /* --------------------------------- YouTube -------------------------------- */}
      <section className="xl:px-20 sm:mb-16">
        <div className="md:container md:mx-auto overflow-hidden w-full relative flex flex-wrap">
          <div
            className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[150px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute pt-6 pb-6 md:pb-8"
          >
            <p className="text-[25px] sm:text-[30px] font-montserratMedium leading-[36px] mt-3- px-10 text-white text-center italic">
              The smallest act of kindness could make the biggest impact.
            </p>
          </div>
          <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
          <div className=" basis-full max-[380px]:min-h-[300px] min-[381px]:min-h-[300px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[1020px]">
            <iframe
              className="video pointer-events-none"
              width={'100%'}
              height={'100%'}
              title={'youtube'}
              frameBorder={0}
              sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
              src={`https://www.youtube.com/embed/pgNqtKfd8sA?controls=0&autoplay=1&loop=1&mute=1&playlist=pgNqtKfd8sA`}
            ></iframe>
          </div>
        </div>
      </section>
      {/* /* ------------------------------- Gift Sound Project ----------------------- */}
      <section className="sm:mt-20 py-6 md:py-8 px-4 md:px-0 bg-[#DFEEF2]">
        <div className="md:container md:mx-auto">
          <p className="md:text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-[25px] md:text-[40px] md:px-4 basis-full md:basis-10/12 mb-4">
            MATW’s Gift of Sound<span className="text-[#F60362] ml-1">Project</span>
          </p>
          <p className="md:leading-7 text-[#78716C] md:text-center font-['Montserrat'] text-md lg:text-lg md:px-30 lg:px-32">
            Our team is collaborating closely with local organisations in the countries that we work in to ensure the best quality healthcare and
            hearing aid solutions for patients.
          </p>
          <p className="md:text-center font-bold text-[#093686] leading-[20px] sm:leading-[30px] text-[18px] sm:text-[25px] md:px-4 basis-full md:basis-10/12 mt-5 md:my-3">
            Our project phase includes:
          </p>
          <div className='grid grid-cols-3 gap-2 md:gap-4 xl:gap-6 md:gap-y-10 mt-8'>
            {giftSounds.map((item, index) => (
              <div key={index} className='col-span-3 sm:col-span-1 mb-3 md:mb-0'>
                <div className={`max-h-[300px] overflow-hidden rounded-lg`}>
                  <img alt={item.alt ? item.alt : "matw"} src={item.image} className='rounded-lg' />
                </div>
                <p className="text-[#253A7E] text-[18px] mt-2 text-center font-bold">{item.title}</p>
              </div>
            ))}
          </div>
          <p className="text-center font-bold text-[#093686] leading-[30px] sm:leading-[50px] text-2xl sm:text-3xl md:text-[40px] md:px-4 basis-full md:basis-10/12 my-5">
            This life-changing work would not be possible without the support of our donors.
            <span className="text-[#F60362] mx-1">Help us provide the Gift of Sound</span>
            with your Sadaqah Jariyah today.
          </p>
        </div>
      </section>
      {/* /* --------------------------------- FAQS --------------------------------- */}
      <section className='xl:px-8 px-4 md:container mx-auto py-4 md:py-8'>
        <h4 className='text-[#253B7E] font-bold text-[25px] md:text-[40px] leading-7 md:leading-[50px] md:text-center my-6 md:my-10'>
          Gift of Sound Donations <span className="text-[#F60362] ml-1">FAQs</span>
        </h4>
        <div className="grid grid-cols-3 gap-3 md:gap-6">
          {giftSoundFaqs.map((item, index) => {
            return <div key={`faq_${index}`} className="col-span-3 md:col-span-1 flex flex-col md:px-2 font-bold mb-6 md:mb-0">
              <h4 className='text-[#253B7E] text-[18px] mb-2 md:mb-3'>
                {item.question}
              </h4>
              <p className="text-[#78716C] text-base font-montserratMedium">{item.answer}</p>
            </div>
          })}
        </div>
      </section>

    </NewHelmetLayout>
  );
};

export default GiftOfSoundLandingPage;