import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import NomanBanner from "../../images/events/noman-banner.jpg";
import MuftiBanner from "../../images/events/mufti-banner.jpg";
import NomanBannerMobile from "../../images/events/noman-banner-mobile.jpg";
import MuftiBannerMobile from "../../images/events/mufti-banner-mobile.jpg";
import { useSelector } from 'react-redux';
import { selectSelectedCurrency } from '../../store/user';
import Layout from '../../components/general/layout';
import WidgetHome from '../../components/widgets/widgetHome';
import { events } from './eventsProducts';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';

const banner = {
  muftiBanner: MuftiBanner,
  nomanBanner: NomanBanner,
  muftiBannerMobile: MuftiBannerMobile,
  nomanBannerMobile: NomanBannerMobile,
};
const EventDetails = () => {
  const params = useParams();
  const [eventData, setEventData] = useState({});

    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const { i18n } = useTranslation();
    const lang = i18n.language;
    // const [selectedCurrency, setSelectedCurrency] = useState();
    const navigate = useNavigate()
    useEffect(() => {
        const data = events.filter(i => i.id === Number(params.id));
        if (data.length > 0) {
            setEventData(data[0])
        }
        else{
            navigate(generateLinkWithLang('/events', lang))
        }
        // selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrencyStore]);

  return (
    <Layout className="bg-white" hideFooter>
      <div className=" hidden sm:flex md:w-full  lg:w-full  xl:h-full 2xl:w-full ">
        {
          <img
            src={banner[eventData?.banner]}
            alt={'yasminmogahedmalaysia'}
            className="w-full"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/general/logo-replace.png';
              currentTarget.className = 'max-h-[150px] mx-auto';
            }}
          />
        }
      </div>
      <div className="  sm:hidden md:w-full h-[300px] lg:w-full  xl:h-full 2xl:w-full ">
        {
          <img
            src={banner[eventData?.bannerMobile]}
            alt={'speaker'}
            className="h-[300px]  w-full"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/general/logo-replace.png';
              currentTarget.className = 'max-h-[150px] mx-auto';
            }}
          />
        }
      </div>
      {/* <WidgetHome selectedCurrency={(e) => setSelectedCurrency(e.value)} /> */}
      <WidgetHome selectedCurrency={e => console.log(e.value)} />
      <section className="md:container md:mx-auto px-4  mt-20 ">
        <h1 className="text-[45px] font-gotcha leading-[30px] text-center text-[#093686]">{eventData?.title}</h1>

        <div
          className={`${eventData?.btns ? '' : 'mb-16'} text-[#78716C] font-montserratMedium text-[18px] mt-16 leading-[18px]`}
          dangerouslySetInnerHTML={{ __html: eventData?.longDescription }}
        ></div>
        {eventData?.btns && (
          <div className="flex justify-center gap-x-6 mt-10 mb-5">
            <button
              onClick={() => window.open(`${eventData?.donateLink}`)}
              className="font-montserratMedium rounded-md h-[50px] w-[230px] bg-[#F60362] text-white text-[18px]"
            >
              Donate Now
            </button>
            <button
              onClick={() => window.open(`${eventData?.pledgeLink}`, '_blank')}
              className="font-montserratMedium rounded-md h-[50px] w-[230px] bg-[#253A7E] text-white text-[18px]"
            >
              Make a Pledge
            </button>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default EventDetails;
