import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import services from '../../../../services';
import Multiselect from 'multiselect-react-dropdown';
import AddBanner from './addBanner';
import PreviewPageModal from './previewPage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../../../components/general/button';
import CheckBox from '../../../../components/general/checkbox';
import Select from 'react-select';
import AddProductBlock from './addProductBlock';
import AddContentBlock from './addContenttBlock';
const styles = {
    control: (base) => ({
        ...base,
        border: 'none',
        boxShadow: '0px 0px 2px 0px rgb(220, 220, 220)',
        ":hover": {
            boxShadow: '0px 0px 8px 0px rgb(216, 216, 216)',
        }
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }
}

const HandlePageV2 = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    // const navigate = useNavigate();
    const { onClose, onSaved, selectedPage } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [allProducts, setAllProducts] = useState();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [banners, setBanners] = useState([]);
    const [selectedBanners, setSelectedBanners] = useState([]);
    const [showAddBannerModal, setShowAddBannerModal] = useState(false);
    const [showAddProductBlockModal, setShowAddProductBlockModal] = useState(false);
    const [showAddContentBlockModal, setShowAddContentBlockModal] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showHomePageWidget, setShowHomePageWidget] = useState(false);
    const [pageContent, setPageContent] = useState('');
    const [allProductBlocks, setAllProductBlocks] = useState([])
    const [allContentBlocks, setAllContentBlocks] = useState([])
    const [selectedProductBlocks, setSelectedProductBlocks] = useState([]);
    const [selectedContentBlocks, setSelectedContentBlocks] = useState([]);
    const [userProductsCount, setUserProductsCount] = useState(0);
    const [page, setPage] = useState(
        {
            page_url: '',
            name: '',
            meta: '',
            status: 'published',
            meta_description: '',
            banners: [],
            products: [],
            title: '',
            contents: '',
            home_widget: 0,
            // imgUrl: '',
            id: 0,
        }
    )
    const suggestedRecurringModes = [
        { label: <div><img src={'https://matw-staging.s3.eu-central-1.amazonaws.com/images/banners/water--food-aid-banner.jpg'} height="50px" width="50px" alt='test' />afghanistan-hero-banner-desktop.jpg</div>, value: 3 },
        { label: <div><img src={'https://matw-staging.s3.eu-central-1.amazonaws.com/images/banners/carousel-homepage-recurring-donations-.jpg'} height="50px" width="50px" alt='test' />carousel-homepage-recurring-donations-.jpg</div>, value: 10 },
    ]
    const pageSchema = Yup.object().shape({
        page_url: Yup.string().min(3, 'Should be 3 character long').required('Enter page URL'),
        name: Yup.string().min(3, 'Should be 3 character long').required('Enter page name'),
        meta: Yup.string().min(3, 'Should be 3 character long').required('Enter meta title'),
        meta_description: Yup.string().min(3, 'Should be 3 character long').required('Enter meta description'),
        // products: Yup.array().required('Select products'),
        // title: Yup.string().min(3, 'Should be 3 character long').required('Enter content title'),
        // contents: Yup.string().min(3, 'Should be 3 character long'),
    });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = async () => {
        try {
            const response = await services.searchProducts();
            function compare(a, b) {
                if (a.name.trim() < b.name.trim()) {
                    return -1;
                }
                if (a.name.trim() > b.name.trim()) {
                    return 1;
                }
                return 0;
            }
            let temp = response.data.sort(compare);
            setAllProducts(temp)
        } catch (error) {
            console.error('error========', error);
        }
    };
    const getProductBlocks = async () => {
        try {
            const response = await services.getAllProductBlocks();
            setAllProductBlocks(response.data.data);
        } catch (error) {
            console.error('error========', error);
        }
    }
    const getContentBlocks = async () => {
        try {
            const response = await services.getAllContentBlocks();
            setAllContentBlocks(response.data.data);
        } catch (error) {
            console.error('error========', error);
        }
    }
    const handleSave = async (values) => {
        setIsLoading(true);
        let tempPrds = selectedProducts.map(item => item.id);
        let tempBnrs = selectedBanners.map(item => item.id);
        // let tempCntBlk = selectedContentBlocks.map(item => {
        //     return {
        //         [item.id]: {
        //             position: item.position.value,
        //             order: Number(item.order)
        //         }
        //     }
        // })
        // let tempPrdBlk = selectedProductBlocks.map(item => {
        //     return {
        //         [item.id]: {
        //             position: item.position.value || 'top',
        //             order: Number(item.order)
        //         }
        //     }
        // })
        // let tempCntBlk = {
        //     [selectedContentBlocks[0].id]: {
        //         position: selectedContentBlocks[0].position.value,
        //         order: Number(selectedContentBlocks[0].order)
        //     },
        //     [selectedContentBlocks[1].id]: {
        //         position: selectedContentBlocks[1].position.value,
        //         order: Number(selectedContentBlocks[1].order)
        //     },
        // }
        // let tempPrdBlk = {
        //     [selectedProductBlocks[0].id]: {
        //         position: selectedProductBlocks[0].position.value,
        //         order: Number(selectedProductBlocks[0].order)
        //     },
        //     [selectedProductBlocks[1].id]: {
        //         position: selectedProductBlocks[1].position.value,
        //         order: Number(selectedProductBlocks[1].order)
        //     },
        // }
        // const objPrd = {};

        // for (const key of selectedProductBlocks) {
        //     objPrd[key] = whatever;
        // }
        const tempCntBlk = Object.fromEntries(
            selectedContentBlocks.map(item => [item.id, {
                position: item.position.value,
                order: Number(item.order)
            }])
          )
        const tempPrdBlk = Object.fromEntries(
            selectedProductBlocks.map(item => [item.id, {
                position: item.position.value || 'top',
                order: Number(item.order)
            }])
          )
        let tempPageUrl = values.page_url.substring(0, 1) === '/' ? values.page_url.substring(1) : values.page_url;
        const req = {
            title: values.title || "sample title",
            name: values.name,
            page_url: tempPageUrl,
            meta: values.meta,
            description: values.meta_description,
            contents: values.contents || "sample contents",
            home_widget: showHomePageWidget ? 1 : 0,
            status: values.status,
            banners: tempBnrs,
            products: [332, 58, 24, 243, 18],
            content_blocks: tempCntBlk,
            product_blocks: tempPrdBlk,
            codes: []
        }
        try {
            const response = await services.createPage(req);
            toast.success('The page has been created successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
            console.log('response get getBanners', response);
        } catch (error) {
            toast.error('Something went wrong!');
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const handleUpdate = async (values) => {
        setIsLoading(true);
        let tempPrds = selectedProducts.map(item => item.id)
        let tempBnrs = selectedBanners.map(item => item.id)
        let tempPageUrl = values.page_url.substring(0, 1) === '/' ? values.page_url.substring(1) : values.page_url;
        const req = {
            page_url: tempPageUrl,
            meta: values.meta,
            description: values.meta_description,
            name: values.name,
            title: values.title,
            contents: values.contents,
            status: values.status,
            products: tempPrds,
            banners: tempBnrs,
            home_widget: showHomePageWidget ? 1 : 0
        }
        try {
            console.log('before send', req);
            const response = await services.updatePage(page.id, req);
            // const response = await services.updateProduct(page.id,tempData);
            toast.success('The page has been updated successfully');
            onSaved();
            closeModal();
            setIsLoading(false);
            console.log('response update page', response);
        } catch (error) {
            toast.error('Something went wrong!');
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const getBanners = async (_selectedBanners, isNewBanner = false) => {
        try {
            const response = await services.getBanners();
            // let tempBanners = response.data.map(item => item)
            setBanners(response.data);
            if (_selectedBanners) {
                let tempBnrs = [];
                response.data.map(item => {
                    _selectedBanners.map(inner_item => {
                        // if (inner_item.id === item.id) tempBnrs.push({value: item.id, name: item, label: <div><img src={item.image} height="50px" width="50px" alt='test'/>{item.name}</div>})
                        if (inner_item.id === item.id) tempBnrs.push(item)
                        // if (inner_item.id === item.id) tempBnrs.push( { label: item.name, value: item.id })
                        return inner_item
                    })
                    return item
                })
                console.log("tgemp banners=", tempBnrs, suggestedRecurringModes)
                if (isNewBanner) {
                    // let tempBnrs = selectedBanners.length ? selectedBanners : [];
                    tempBnrs.push(response.data[response.data.length - 1])
                    // setSelectedBanners(tempBnrs)
                }
                setSelectedBanners(tempBnrs)
            }
            console.log('response get getBanners', response.data);
        } catch (error) {
            console.error('error========', error);
        }
    };
    const closeModal = () => {
        setPage({
            ...page,
            title: '',
            page_url: '',
            name: '',
            contents: '',
            imgUrl: '',
            meta: '',
            meta_description: '',
            status: 'published',
            home_widget: 0,
            id: 0,
        });
        onClose();
    }
    const handleSelectProduct = (selectedList, selectedItem) => {
        console.log("products=", selectedList);
        setSelectedProducts(selectedList)
    }
    const handleRemoveProduct = (selectedList, selectedItem) => {
        setSelectedProducts(selectedList)
    }
    const handleSelectBanner = (selectedList, selectedItem) => {
        console.log("banners=", selectedList);
        setSelectedBanners(selectedList)
    }
    const handleRemoveBanner = (selectedList, selectedItem) => {
        setSelectedBanners(selectedList)
    }
    const handleKeyDown = (value) => {
        let checkIfNum;
        if (value.key !== undefined) {
            if (value.key === "." || value.key === " " || value.key === "/") checkIfNum = true;
        }
        return checkIfNum && value.preventDefault();
    }
    const handleSubmit = (values) => {
        selectedPage ? handleUpdate(values) : handleSave(values)
    }
    const handleEditContentBlock = (value, index, field) => {
        console.log("edited=", value)
        const newContentBlocks = selectedContentBlocks.map((obj, inner_index) => {
            if (inner_index === index) {
                if (field === 'id') return { ...obj, id: value.value, name: value.label }
                return { ...obj, [field]: value }
            }
            return obj;
        });
        setSelectedContentBlocks(newContentBlocks)
    }
    const handleAddContentBlock = (index) => {
        console.log("handleAddProductBlock=", index, selectedContentBlocks)
        let tempBlocks = selectedContentBlocks;
        if (tempBlocks.length) {
            if (index === tempBlocks.length - 1) {
                tempBlocks.push(tempBlocks[tempBlocks.length - 1]);
                setSelectedContentBlocks(tempBlocks);
                setUserProductsCount(userProductsCount + 1);
            }
        } else {
            console.log("allProductBlocks[0]=", allContentBlocks, allContentBlocks[0])
            let tempBlock = [{
                id: allContentBlocks[0].id,
                name: allContentBlocks[0].name,
                position: { value: "top", label: "Top" },
                order: 1
            }];
            setSelectedContentBlocks(tempBlock)
        }
    }
    const handleRemoveContentBlock = (index) => {
        let tempBlocks = selectedContentBlocks;
        tempBlocks.splice(index, 1);
        setSelectedContentBlocks(tempBlocks);
        setUserProductsCount(userProductsCount + 1);
    }
    const handleEditProductBlock = (value, index, field) => {
        // console.log("handleEditProductBlock=", index, field)
        // let temp = selectedProductBlocks;
        // temp[index] = { ...temp[index], [field]:value};
        console.log("edited=", value)
        const newProductBlocks = selectedProductBlocks.map((obj, inner_index) => {
            if (inner_index === index) {
                if (field === 'id') return { ...obj, id: value.value, name: value.label }
                return { ...obj, [field]: value }
            }
            return obj;
        });
        setSelectedProductBlocks(newProductBlocks)
    }
    const handleAddProductBlock = (index) => {
        console.log("handleAddProductBlock=", index, selectedProductBlocks)
        let tempBlocks = selectedProductBlocks;
        if (tempBlocks.length) {
            if (index === tempBlocks.length - 1) {
                tempBlocks.push(tempBlocks[tempBlocks.length - 1]);
                setSelectedProductBlocks(tempBlocks);
                setUserProductsCount(userProductsCount + 1);
            }
        } else {
            console.log("allProductBlocks[0]=", allProductBlocks, allProductBlocks[0])
            let tempBlock = [{
                id: allProductBlocks[0].id,
                name: allProductBlocks[0].name,
                position: "top",
                order: 1
            }];
            setSelectedProductBlocks(tempBlock)
        }
    }
    const handleRemoveProductBlock = (index) => {
        let tempBlocks = selectedProductBlocks;
        tempBlocks.splice(index, 1);
        setSelectedProductBlocks(tempBlocks);
        setUserProductsCount(userProductsCount + 1);
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        if (selectedPage) {
            let tempPage = selectedPage;
            // if(tempPage.page_url.substring())
            // tempPage.page_url = `/${tempPage.page_url}`
            tempPage.page_url = tempPage.page_url.substring(0, 1) === '/' ? tempPage.page_url : `/${tempPage.page_url}`;
            tempPage.meta_description = tempPage.description;
            tempPage.home_widget === 1 ? setShowHomePageWidget(true) : setShowHomePageWidget(false)
            setPage(tempPage);
            let tempPrds = [];
            selectedPage.products.map(item => tempPrds.push({ creator: item.creator, id: item.id, name: item.name }))
            setPageContent(selectedPage.contents)
            getBanners(selectedPage.banners);
            setSelectedProducts(tempPrds)
        } else {
            getBanners();
        }
        selectedPage && console.log("page=", selectedPage);
        getProductBlocks();
        getContentBlocks();
        getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* -------------------------------------------------------------------------- */
    return (
        <div className='w-full flex flex-wrap items-end'>
            <Formik
                enableReinitialize
                initialValues={{
                    page_url: page.page_url,
                    name: page.name,
                    meta: page.meta,
                    status: page.status,
                    meta_description: page.meta_description,
                    banners: page.banners,
                    products: page.products,
                    title: page.title,
                    contents: page.contents,
                }}
                validationSchema={pageSchema}
                onSubmit={handleSubmit}
            >
                {({ errors, values, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                    <form onSubmit={(values) => { handleSubmit(values); console.log("submited=", errors, values) }} className='w-full'>
                        <div className="grid grid-cols-12 items-end w-full md:pl-3- mt-3 md:mt-0 gap-2 md:gap-6 pt-6">
                            {/* /* -------------------------------- Page Info -------------------------------  */}
                            <div className="col-span-12 grid grid-cols-12 gap-2 bg-stone-100 border border-stone-200 rounded-md p-2 md:p-4">
                                <div className="col-span-12 border-b border-stone-300">
                                    <h2 className='text-lg text-stone-500 uppercase'>Page Info</h2>
                                </div>
                                <div className="flex order-2 md:order-1 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 flex-col min-h-[80px] items-start">
                                    <label htmlFor="page_url">Page URL</label>
                                    <input name='page_url' type="text" value={values.page_url}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        onChange={(e) => {
                                            let _value = e;
                                            if (_value.target.value.substring(0, 1) !== '/') _value.target.value = `/${_value.target.value}`
                                            handleChange(_value)
                                        }}
                                        onBlur={handleBlur}
                                        className='border lowercase w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                    {(errors.page_url && touched.page_url) &&
                                        <small id='page_url-error' className="text-[#f60362] text-xs">
                                            {errors.page_url}
                                        </small>}
                                </div>
                                <div className="flex order-3 md:order-2 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 flex-col min-h-[80px] items-start">
                                    <label htmlFor="name">Page Name</label>
                                    <input name='name' type="text" value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                    {(errors.name && touched.name) &&
                                        <small id='name-error' className="text-[#f60362] text-xs">
                                            {errors.name}
                                        </small>}
                                </div>
                                <div className="flex order-4 md:order-3 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 flex-col min-h-[80px] items-start">
                                    <label htmlFor="meta">Meta Title</label>
                                    <input name='meta' type="text" value={values.meta}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                    {(errors.meta && touched.meta) &&
                                        <small id='meta-error' className="text-[#f60362] text-xs">
                                            {errors.meta}
                                        </small>}
                                </div>
                                <div className="flex order-1 md:order-4 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 flex-col min-h-[80px] items-start justify-start">
                                    <label htmlFor="status">Status</label>
                                    <select
                                        name="status"
                                        value={values.status}
                                        className='cursor-pointer focus:outline-none border border-stone-300 rounded px-2 text-sm h-[42px] w-full'
                                        onChange={handleChange}
                                    >
                                        <option value={"published"} className='cursor-pointer'>
                                            Published
                                        </option>
                                        <option value={"draft"} className='cursor-pointer'>
                                            Draft
                                        </option>
                                    </select>
                                </div>
                                <div className="flex order-5 md:order-5 col-span-12 flex-col min-h-[80px] items-start">
                                    <label htmlFor="meta_description">Meta Description</label>
                                    <textarea rows="2" name='meta_description' type="text"
                                        value={values.meta_description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 p-2' />
                                    {(errors.meta_description && touched.meta_description) &&
                                        <small id='meta_description-error' className="text-[#f60362] text-xs">
                                            {errors.meta_description}
                                        </small>}
                                </div>
                            </div>
                            {/* /* --------------------------------- Banners --------------------------------  */}
                            <div className="col-span-12 grid grid-cols-12 gap-2 bg-stone-100 border border-stone-200 rounded-md p-2 md:p-4">
                                <div className="col-span-12 border-b border-stone-300">
                                    <h2 className='text-lg text-stone-500 uppercase'>Banners</h2>
                                </div>
                                <div className="flex order-6 col-span-12 flex-col mb-4">
                                    <label htmlFor="banners">
                                        Banners
                                        <small className='ml-1 text-stone-400'>(Select banners from list or add new banner)</small>
                                    </label>
                                    <div className="flex flex-col md:flex-row [&>div]:w-full">
                                        <Multiselect
                                            avoidHighlightFirstOption={true}
                                            options={banners} // Options to display in the dropdown
                                            selectedValues={selectedBanners} // Preselected value to persist in dropdown
                                            onSelect={handleSelectBanner} // Function will trigger on select event
                                            onRemove={handleRemoveBanner} // Function will trigger on remove event
                                            displayValue="name" // Property name to display in the dropdown options
                                        />
                                        <CustomButton
                                            onClick={() => setShowAddBannerModal(true)}
                                            type={'button'}
                                            title={'Add Banner'}
                                            className='border border-[#0f960f] hover:bg-[#096909] !text-[#0f960f] bg-transparent mt-2 md:mt-0 md:ml-2 hover:!text-white px-4 min-w-[140px] h-[46px] flex items-center justify-center rounded transition-all duration-200'
                                        />
                                    </div>
                                </div>
                                {selectedBanners.length > 0 &&
                                    <div className="grid order-7 grid-cols-2 gap-2 col-span-12 mb-2 max-h-[300px] overflow-y-scroll border border-stone-300 p-2 rounded-md">
                                        {selectedBanners.map((item, index) => {
                                            return <div key={`banners${index}`} className='col-span-2 md:col-span-1'>
                                                <img src={item.image} alt={item.name} />
                                            </div>
                                        })}
                                    </div>
                                }
                            </div>
                            {/* /* -------------------------------- Products --------------------------------  */}
                            <div className="col-span-12 grid grid-cols-12 gap-2 bg-stone-100 border border-stone-200 rounded-md p-2 md:p-4">
                                <div className="col-span-12 border-b border-stone-300 flex justify-between items-center pb-2">
                                    <h2 className='text-lg text-stone-500 uppercase'>Product blocks</h2>
                                    <CustomButton
                                        onClick={() => setShowAddProductBlockModal(true)}
                                        type={'button'}
                                        title={'Create New Product Block'}
                                        className='border border-[#0f960f] hover:bg-[#096909] !text-[#0f960f] bg-transparent mt-2 md:mt-0 md:ml-2 hover:!text-white px-4 min-w-[140px] h-[46px] flex items-center justify-center rounded transition-all duration-200'
                                    />
                                </div>
                                <div className="col-span-12 flex-col mb-4">
                                    {selectedProductBlocks.length
                                        ? selectedProductBlocks.map((blockItem, index) => {
                                            return <div className='grid grid-cols-12 w-full gap-2 md:gap-4 mb-3' key={`blocks${index}`}>
                                                <div className="col-span-8 lg:col-span-8">
                                                    <label htmlFor="products">Product Blocks</label>
                                                    <Select
                                                        options={allProductBlocks.map((item, index) => {
                                                            return { value: item.id, label: item.name }
                                                        })}
                                                        placeholder="Select product block"
                                                        value={{
                                                            value: blockItem.id,
                                                            label: blockItem.name
                                                        }}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            handleEditProductBlock(e, index, 'id')
                                                        }}
                                                        isSearchable={true}
                                                    />
                                                </div>
                                                <div className="col-span-2 lg:col-span-2 flex flex-col justify-end">
                                                    <label htmlFor="order">Order</label>
                                                    <input name='order' type="number" min={1} value={blockItem.order}
                                                        onKeyDown={(value) => {
                                                            let checkIfNum;
                                                            if (value.key !== undefined) {
                                                                if (value.key === "." || value.key === " " || value.key === "/" || value.key === "-") checkIfNum = true;
                                                            }
                                                            return checkIfNum && value.preventDefault();
                                                        }}
                                                        onBlur={(e) => {
                                                            Number(e.target.value) < 1 && handleEditProductBlock(1, index, 'order')
                                                        }}
                                                        onChange={(e) => {
                                                            // e.target.value > 0 && handleEditProductBlock(e.target.value, index, 'order')
                                                            handleEditProductBlock(e.target.value, index, 'order')
                                                        }}
                                                        // onBlur={handleBlur}
                                                        className='border lowercase w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                                                    />
                                                </div>
                                                <div className="col-span-2 lg:col-span-2 flex items-end md:justify-end">
                                                    <button type='button' onClick={() => handleAddProductBlock(index)} className='w-10 h-10 mr-2 px-1 xl:px-2 rounded bg-[#00A3DA] text-white flex justify-center items-center md:w-[calc(100%_-_36px)]'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 md:hidden">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                        </svg>
                                                        <span className="hidden- md:block-">
                                                            Add again
                                                        </span>
                                                    </button>
                                                    <button
                                                        type='button'
                                                        onClick={() => handleRemoveProductBlock(index)}
                                                        className={`w-10 h-10 rounded hidden md:flex justify-center items-center bg-[#F60362] text-white`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        })
                                        : <div className='col-span-12 flex flex-col justify-center items-center py-4 mb-4'>
                                            {/* <img src={emptyImg} alt="empty cart" className='w-96' /> */}
                                            <p className='text-stone-500 text-xl my-3 text-center px-1'>Please choose a block</p>
                                            <div className="flex flex-col md:flex-row">
                                                <button
                                                    type="button"
                                                    disabled={!allProductBlocks[0]}
                                                    className="inline-block rounded bg-[#00a3da] text-white px-2 sm:px-6 font-medium text-sm md:text-base h-[38px] transition-all duration-200 hover:bg-[#f60362] focus:outline-none focus:ring-0 "
                                                    onClick={() => handleAddProductBlock()}
                                                >
                                                    Add a Block
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* /* -------------------------------- Contents --------------------------------  */}
                            <div className="col-span-12 grid grid-cols-12 gap-2 bg-stone-100 border border-stone-200 rounded-md p-2 md:p-4">
                                <div className="col-span-12 border-b border-stone-300 flex justify-between items-center pb-2">
                                    <h2 className='text-lg text-stone-500 uppercase'>Content blocks</h2>
                                    <CustomButton
                                        onClick={() => setShowAddContentBlockModal(true)}
                                        type={'button'}
                                        title={'Create New Content Block'}
                                        className='border border-[#0f960f] hover:bg-[#096909] !text-[#0f960f] bg-transparent mt-2 md:mt-0 md:ml-2 hover:!text-white px-4 min-w-[140px] h-[46px] flex items-center justify-center rounded transition-all duration-200'
                                    />
                                </div>
                                <div className="col-span-12 flex-col mb-4">
                                    {selectedContentBlocks.length
                                        ? selectedContentBlocks.map((blockItem, index) => {
                                            return <div className='grid grid-cols-12 w-full gap-2 md:gap-4 mb-3' key={`contents${index}`}>
                                                <div className="col-span-5 lg:col-span-7">
                                                    <label htmlFor="products">Content Blocks</label>
                                                    <Select
                                                        options={allContentBlocks.map((item, index) => {
                                                            return { value: item.id, label: item.name }
                                                        })}
                                                        placeholder="Select content block"
                                                        value={{
                                                            value: blockItem.id,
                                                            label: blockItem.name
                                                        }}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            handleEditContentBlock(e, index, 'id')
                                                        }}
                                                        isSearchable={true}
                                                    />
                                                </div>
                                                <div className="col-span-3 lg:col-span-2 flex flex-col justify-end">
                                                    <label htmlFor="position">Position</label>
                                                    <Select
                                                        options={[
                                                            { value: 'top', label: 'Top' },
                                                            { value: 'right', label: 'Right' },
                                                            { value: 'bottom', label: 'Bottom' },
                                                            { value: 'left', label: 'Left' }
                                                        ]}
                                                        placeholder="Select position"
                                                        // value={{
                                                        //     value: blockItem.id,
                                                        //     label: blockItem.name
                                                        // }}
                                                        value={blockItem.position}
                                                        styles={styles}
                                                        onChange={(e) => {
                                                            handleEditContentBlock(e, index, 'position')
                                                        }}
                                                        // onChange={(val) => {
                                                        //     let e = { target: { value: val, name: 'type', id: 'type' } };
                                                        //     // handleChange(e);
                                                        //     handleEditContentBlock(e, index, 'position')
                                                        // }}
                                                        isSearchable={false}
                                                    />
                                                </div>
                                                <div className="col-span-2 lg:col-span-1 flex flex-col justify-end">
                                                    <label htmlFor="order">Order</label>
                                                    <input name='order' type="number" min={1} value={blockItem.order}
                                                        onKeyDown={(value) => {
                                                            let checkIfNum;
                                                            if (value.key !== undefined) {
                                                                if (value.key === "." || value.key === " " || value.key === "/" || value.key === "-") checkIfNum = true;
                                                            }
                                                            return checkIfNum && value.preventDefault();
                                                        }}
                                                        onBlur={(e) => {
                                                            Number(e.target.value) < 1 && handleEditContentBlock(1, index, 'order')
                                                        }}
                                                        onChange={(e) => {
                                                            handleEditContentBlock(e.target.value, index, 'order')
                                                        }}
                                                        className='border lowercase w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2'
                                                    />
                                                </div>
                                                <div className="col-span-2 lg:col-span-2 flex items-end md:justify-end">
                                                    <button type='button' onClick={() => handleAddContentBlock(index)} className='w-10 h-10 mr-2 px-1 xl:px-2 rounded bg-[#00A3DA] text-white flex justify-center items-center md:w-[calc(100%_-_36px)]'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 md:hidden">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                        </svg>
                                                        <span className="hidden- md:block-">
                                                            Add again
                                                        </span>
                                                    </button>
                                                    <button
                                                        type='button'
                                                        onClick={() => handleRemoveContentBlock(index)}
                                                        className={`w-10 h-10 rounded hidden md:flex justify-center items-center bg-[#F60362] text-white`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        })
                                        : <div className='col-span-12 flex flex-col justify-center items-center py-4 mb-4'>
                                            <p className='text-stone-500 text-xl my-3 text-center px-1'>Please choose a Content Block</p>
                                            <div className="flex flex-col md:flex-row">
                                                <button
                                                    type="button"
                                                    disabled={!allContentBlocks[0]}
                                                    className="inline-block rounded bg-[#00a3da] text-white px-2 sm:px-6 font-medium text-sm md:text-base h-[38px] transition-all duration-200 hover:bg-[#f60362] focus:outline-none focus:ring-0 "
                                                    onClick={() => handleAddContentBlock()}
                                                >
                                                    Add a Block
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* <div className="flex order-9 col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 flex-col min-h-[80px] items-start">
                                <label htmlFor="title">Content Title (H1)</label>
                                <input name='title' type="text" value={values.title}
                                    // onChange={(e) => handleChange('title', e.target.value)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.title && touched.title) &&
                                    <small id='title-error' className="text-[#f60362] text-xs">
                                        {errors.title}
                                    </small>}
                            </div>
                            <div className="flex order-10 col-span-12 flex-col mb-2">
                                <label htmlFor="contents">Content Body Text (P)</label>
                                <textarea rows="4" name='contents' type="text" value={values.contents}
                                    // onChange={(e) => { setPageContent(e.target.value); handleChange('contents', e.target.value) }}
                                    onChange={(e) => {
                                        setPageContent(e.target.value);
                                        handleChange(e)
                                    }}
                                    onBlur={handleBlur}
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md p-2' />
                                {(errors.contents && touched.contents) &&
                                    <small id='contents-error' className="text-[#f60362] text-xs">
                                        {errors.contents}
                                    </small>}
                            </div> */}
                            <div className="flex order-11 col-span-12 items-center [&>input]:mt-0 my-3">
                                <CheckBox
                                    name="showHomePageWidget"
                                    value={showHomePageWidget}
                                    checked={showHomePageWidget}
                                    setter={(e) => setShowHomePageWidget(e)}
                                >
                                    <span className='text-stone-500'>Use HomePage Widget</span>
                                </CheckBox>
                            </div>
                        </div>
                        <div className="flex w-full flex-col md:flex-row justify-end pt-4 gap-2">
                            <CustomButton
                                onClick={() => closeModal()}
                                title='Cancel'
                                className='border border-red-500 hover:bg-red-500 !text-red-500 hover:!text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 md:mr-2'
                            />
                            {/* <CustomButton
                    onClick={() => setShowPreviewModal(true)}
                    title='Preview'
                    className='border border-[#1c0f96] hover:bg-[#1c0f96] !text-[#1c0f96] hover:!text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 md:mr-2'
                /> */}
                            <CustomButton
                                // onClick={() => selectedPage ? handleUpdate() : handleSave()}
                                disabled={isLoading}
                                isLoading={isLoading}
                                title={selectedPage ? 'Update' : 'Save'}
                                className='!bg-[#0f960f] hover:!bg-[#096909] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200'
                            />
                        </div>
                    </form>
                )}
            </Formik>
            {showAddContentBlockModal && <AddContentBlock
                show={showAddContentBlockModal}
                onClose={() => { setShowAddContentBlockModal(false); }}
                onSaved={() => {
                    toast.success('The content block has been added successfully');
                    getContentBlocks();
                }}
            />}
            {showAddProductBlockModal && <AddProductBlock
                show={showAddProductBlockModal}
                onClose={() => { setShowAddProductBlockModal(false); }}
                onSaved={() => {
                    toast.success('The product block has been added successfully');
                    getProductBlocks();
                }}
            />}
            {showAddBannerModal && <AddBanner
                title='Add New Banner'
                show={showAddBannerModal}
                onClose={() => { setShowAddBannerModal(false); }}
                onSaved={() => {
                    toast.success('The banner has been added successfully');
                    getBanners(selectedBanners, true);
                }}
            />}
            {showPreviewModal && <PreviewPageModal
                title={page.title}
                show={showPreviewModal}
                products={selectedProducts}
                banners={selectedBanners}
                content={pageContent}
                onClose={() => { setShowPreviewModal(false); }}
            />}
        </div>
    );
};
export default HandlePageV2;