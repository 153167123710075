import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import services from '../../../../services';
import CustomModal from '../../components/modal';
import CheckBox from '../../../../components/general/checkbox';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../../../components/general/button';
import Multiselect from 'multiselect-react-dropdown';

const AddProductBlock = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { show, onClose, onSaved } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [allProducts, setAllProducts] = useState();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [productBlock, setProductBlock] = useState(
        {
            name: '',
            title: '',
            products: [],
        }
    )
    const prdBlockSchema = Yup.object().shape({
        name: Yup.string().min(3, 'Should be 3 character long').required('Enter product block name'),
        title: Yup.string().min(3, 'Should be 3 character long').required('Enter product block title'),
        products: Yup.array().required('Select products'),
    });
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = async () => {
        try {
            const response = await services.searchProducts();
            function compare(a, b) {
                if (a.name.trim() < b.name.trim()) {
                    return -1;
                }
                if (a.name.trim() > b.name.trim()) {
                    return 1;
                }
                return 0;
            }
            let temp = response.data.sort(compare);
            setAllProducts(temp)
        } catch (error) {
            console.error('error========', error);
        }
    };
    const handleSaveProductBlock = async (values) => {
        setIsLoading(true);
        let tempPrds = values.products.map(item => item.id)
        let req = {
            name: values.name,
            title: values.title,
            products: tempPrds
        }
        try {
            await services.createProductBlock(req);
            onSaved();
            closeModal();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('error========', error);
        }
    };
    const handleSelectProduct = (selectedList) => {
        setSelectedProducts(selectedList)
    }
    const handleRemoveProduct = (selectedList) => {
        setSelectedProducts(selectedList)
    }
    const handleKeyDown = (value) => {
        let checkIfNum;
        if (value.key !== undefined) {
            if (value.key === "." || value.key === " " || value.key === "/") checkIfNum = true;
        }
        return checkIfNum && value.preventDefault();
    }
    const closeModal = () => {
        setProductBlock({
            ...productBlock,
            name: '',
            title: '',
            products: [],
        });
        onClose();
    }
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        show && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    /* -------------------------------------------------------------------------- */
    return (
        <CustomModal
            title={'Add New Product Block'}
            show={show}
            className='pb-0'
            onClose={() => closeModal()}
        >
            <Formik
                enableReinitialize
                initialValues={{
                    name: productBlock.name,
                    products: productBlock.products,
                    title: productBlock.title,
                }}
                validationSchema={prdBlockSchema}
                onSubmit={(values) => handleSaveProductBlock(values)}
            >
                {({ errors, values, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={(values) => { handleSubmit(values); console.log("submited=", errors, values) }} className='w-full'>
                        <div className="grid grid-cols-12 items-end w-full md:pl-3- mt-3 md:mt-0 gap-2">
                            <div className="flex col-span-12 sm:col-span-6 flex-col min-h-[80px] items-start">
                                <label htmlFor="name">Block Name</label>
                                <input name='name' type="text" value={values.name}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='Block Name'
                                    className='border lowercase w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.name && touched.name) &&
                                    <small id='name-error' className="text-[#f60362] text-xs">
                                        {errors.name}
                                    </small>
                                }
                            </div>
                            <div className="flex col-span-12 sm:col-span-6 flex-col min-h-[80px] items-start">
                                <label htmlFor="title">Block Title</label>
                                <input name='title' type="text" value={values.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='Block Title'
                                    className='border w-full focus-visible:outline-none focus-visible:border-stone-400 border-stone-200 text-xs md:text-base rounded-md h-10 px-2' />
                                {(errors.title && touched.title) &&
                                    <small id='title-error' className="text-[#f60362] text-xs">
                                        {errors.title}
                                    </small>}
                            </div>
                            <div className="flex col-span-12 flex-col min-h-[80px] items-start [&>div]:w-full">
                                <label htmlFor="products">Products</label>
                                <Multiselect
                                    avoidHighlightFirstOption={true}
                                    name="products"
                                    options={allProducts} // Options to display in the dropdown
                                    selectedValues={selectedProducts} // Preselected value to persist in dropdown
                                    onSelect={(val, i) => {
                                        let e = { target: { value: val, name: 'products', id: 'products' } };
                                        handleChange(e);
                                        handleSelectProduct(val, i)
                                    }} // Function will trigger on select event
                                    onBlur={handleBlur}
                                    onRemove={(val, i) => {
                                        let e = { target: { value: val, name: 'products', id: 'products' } };
                                        handleChange(e);
                                        handleRemoveProduct(val, i)
                                    }}
                                    displayValue="name" // Property name to display in the dropdown options
                                />
                                {(errors.products && touched.products) &&
                                    <small id='products-error' className="text-[#f60362] text-xs">
                                        {errors.products}
                                    </small>}
                            </div>
                        </div>
                        <div className="flex w-full flex-col md:flex-row justify-end pt-4 gap-2">
                            <CustomButton
                                onClick={() => closeModal()}
                                title='Cancel'
                                className='border border-red-500 hover:bg-red-500 !text-red-500 hover:!text-white bg-transparent px-8 h-10 flex items-center justify-center rounded transition-all duration-200 md:mr-2'
                            />
                            <CustomButton
                                disabled={isLoading}
                                type="submit"
                                isLoading={isLoading}
                                title={'Save'}
                                className='!bg-[#0f960f] hover:!bg-[#096909] text-white px-8 h-10 flex items-center justify-center rounded transition-all duration-200'
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </CustomModal>
    );
};
export default AddProductBlock;